import '@/styles/globals.css';
import '../../node_modules/@softopus-io/rimac-ui-lib/dist/style.css';

import { ApolloProvider } from '@apollo/client';
import type { AppProps } from 'next/app';
import Head from 'next/head';

import { useApollo } from '@/api/apolloClient';
import { CookieBar } from '@/components/cookies/CookieBar';
import { CookieSetting } from '@/components/cookies/CookieSetting';
import { AnalyticsProvider } from '@/components/providers/AnalyticsProvider';

const App = ({ Component, pageProps }: AppProps) => {
  const client = useApollo(pageProps);

  return (
    <ApolloProvider client={client}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Rimac Technology</title>
      </Head>
      <AnalyticsProvider>
        <Component {...pageProps} />
        <CookieBar key="cookies-bar" />
        <CookieSetting key="cookies-settings" />
      </AnalyticsProvider>
    </ApolloProvider>
  );
};

export default App;
