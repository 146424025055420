import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  ArticleContentDynamicZoneInput: { input: any; output: any };
  CookiePolicyCookiePolicyDynamicZoneInput: { input: any; output: any };
  DateTime: { input: any; output: any };
  EnvironmentalPolicyPoliciesDynamicZoneInput: { input: any; output: any };
  IriPageContentsDynamicZoneInput: { input: any; output: any };
  JSON: { input: any; output: any };
  PrivacyPolicyPoliciesDynamicZoneInput: { input: any; output: any };
  TermsAndConditionPoliciesDynamicZoneInput: { input: any; output: any };
  Upload: { input: any; output: any };
  WhistleblowingPolicyPoliciesDynamicZoneInput: { input: any; output: any };
};

export type Article = {
  __typename?: 'Article';
  content?: Maybe<Array<Maybe<ArticleContentDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  hero?: Maybe<ComponentArticleArticleHero>;
  preview?: Maybe<ComponentArticleArticlePreview>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ArticleContentDynamicZone =
  | ComponentArticleGallery
  | ComponentArticleTextContent
  | Error;

export type ArticleEntity = {
  __typename?: 'ArticleEntity';
  attributes?: Maybe<Article>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ArticleEntityResponse = {
  __typename?: 'ArticleEntityResponse';
  data?: Maybe<ArticleEntity>;
};

export type ArticleEntityResponseCollection = {
  __typename?: 'ArticleEntityResponseCollection';
  data: Array<ArticleEntity>;
  meta: ResponseCollectionMeta;
};

export type ArticleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ArticleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  hero?: InputMaybe<ComponentArticleArticleHeroFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<ArticleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ArticleFiltersInput>>>;
  preview?: InputMaybe<ComponentArticleArticlePreviewFiltersInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ArticleInput = {
  content?: InputMaybe<
    Array<Scalars['ArticleContentDynamicZoneInput']['input']>
  >;
  hero?: InputMaybe<ComponentArticleArticleHeroInput>;
  preview?: InputMaybe<ComponentArticleArticlePreviewInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleRelationResponseCollection = {
  __typename?: 'ArticleRelationResponseCollection';
  data: Array<ArticleEntity>;
};

export type Battery = {
  __typename?: 'Battery';
  cardsSection: ComponentSharedSectionCards;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroVideoSection: ComponentUiHeroVideoSection;
  largeImageSection?: Maybe<ComponentSharedLargeImageSection>;
  newsroom?: Maybe<ComponentSharedNewsroom>;
  productImageBlock?: Maybe<ComponentSharedProductImageBlock>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  twoImagesGrid?: Maybe<ComponentSharedTwoImagesGrid>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BatteryEntity = {
  __typename?: 'BatteryEntity';
  attributes?: Maybe<Battery>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type BatteryEntityResponse = {
  __typename?: 'BatteryEntityResponse';
  data?: Maybe<BatteryEntity>;
};

export type BatteryInput = {
  cardsSection?: InputMaybe<ComponentSharedSectionCardsInput>;
  heroVideoSection?: InputMaybe<ComponentUiHeroVideoSectionInput>;
  largeImageSection?: InputMaybe<ComponentSharedLargeImageSectionInput>;
  newsroom?: InputMaybe<ComponentSharedNewsroomInput>;
  productImageBlock?: InputMaybe<ComponentSharedProductImageBlockInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  twoImagesGrid?: InputMaybe<ComponentSharedTwoImagesGridInput>;
};

export type Bms = {
  __typename?: 'Bms';
  cardsSection?: Maybe<ComponentSharedSectionCards>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroVideoSection?: Maybe<ComponentUiHeroVideoSection>;
  newsroom?: Maybe<ComponentSharedNewsroom>;
  productImageBlock?: Maybe<ComponentSharedProductImageBlock>;
  productSpecsBlock?: Maybe<ComponentSharedProductSpecsBlocks>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  twoImagesGrid?: Maybe<Array<Maybe<ComponentSharedTwoImagesGrid>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BmsTwoImagesGridArgs = {
  filters?: InputMaybe<ComponentSharedTwoImagesGridFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type BmsEntity = {
  __typename?: 'BmsEntity';
  attributes?: Maybe<Bms>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type BmsEntityResponse = {
  __typename?: 'BmsEntityResponse';
  data?: Maybe<BmsEntity>;
};

export type BmsInput = {
  cardsSection?: InputMaybe<ComponentSharedSectionCardsInput>;
  heroVideoSection?: InputMaybe<ComponentUiHeroVideoSectionInput>;
  newsroom?: InputMaybe<ComponentSharedNewsroomInput>;
  productImageBlock?: InputMaybe<ComponentSharedProductImageBlockInput>;
  productSpecsBlock?: InputMaybe<ComponentSharedProductSpecsBlocksInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  twoImagesGrid?: InputMaybe<
    Array<InputMaybe<ComponentSharedTwoImagesGridInput>>
  >;
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  contains?: InputMaybe<Scalars['Boolean']['input']>;
  containsi?: InputMaybe<Scalars['Boolean']['input']>;
  endsWith?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  eqi?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
  nei?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']['input']>;
  notContainsi?: InputMaybe<Scalars['Boolean']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Company = {
  __typename?: 'Company';
  Campus?: Maybe<Array<Maybe<ComponentUiCampus>>>;
  CampusAnchor?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  facilities?: Maybe<ComponentCompanyFacilities>;
  guidelines?: Maybe<ComponentUiGuidelines>;
  heroImageSection: ComponentUiHeroImageSection;
  largeImageSection?: Maybe<ComponentSharedLargeImageSection>;
  newsroom?: Maybe<ComponentSharedNewsroom>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  sliderImageSection?: Maybe<ComponentSharedSliderImageSection>;
  team?: Maybe<ComponentUiTeam>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  values?: Maybe<ComponentSharedValues>;
};

export type CompanyCampusArgs = {
  filters?: InputMaybe<ComponentUiCampusFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyEntity = {
  __typename?: 'CompanyEntity';
  attributes?: Maybe<Company>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CompanyEntityResponse = {
  __typename?: 'CompanyEntityResponse';
  data?: Maybe<CompanyEntity>;
};

export type CompanyInput = {
  Campus?: InputMaybe<Array<InputMaybe<ComponentUiCampusInput>>>;
  CampusAnchor?: InputMaybe<Scalars['String']['input']>;
  facilities?: InputMaybe<ComponentCompanyFacilitiesInput>;
  guidelines?: InputMaybe<ComponentUiGuidelinesInput>;
  heroImageSection?: InputMaybe<ComponentUiHeroImageSectionInput>;
  largeImageSection?: InputMaybe<ComponentSharedLargeImageSectionInput>;
  newsroom?: InputMaybe<ComponentSharedNewsroomInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  sliderImageSection?: InputMaybe<ComponentSharedSliderImageSectionInput>;
  team?: InputMaybe<ComponentUiTeamInput>;
  values?: InputMaybe<ComponentSharedValuesInput>;
};

export type ComponentArticleArticleHero = {
  __typename?: 'ComponentArticleArticleHero';
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentArticleArticleHeroFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentArticleArticleHeroFiltersInput>>>;
  image?: InputMaybe<ComponentUiImageFiltersInput>;
  not?: InputMaybe<ComponentArticleArticleHeroFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentArticleArticleHeroFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentArticleArticleHeroInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ComponentUiImageInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentArticleArticlePreview = {
  __typename?: 'ComponentArticleArticlePreview';
  alt?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  img_desktop?: Maybe<UploadFileEntityResponse>;
  img_mobile?: Maybe<UploadFileEntityResponse>;
  link_path?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  tagColor?: Maybe<Enum_Componentarticlearticlepreview_Tagcolor>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentArticleArticlePreviewFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<
    Array<InputMaybe<ComponentArticleArticlePreviewFiltersInput>>
  >;
  date?: InputMaybe<StringFilterInput>;
  link_path?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentArticleArticlePreviewFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentArticleArticlePreviewFiltersInput>>
  >;
  tag?: InputMaybe<StringFilterInput>;
  tagColor?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentArticleArticlePreviewInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  img_desktop?: InputMaybe<Scalars['ID']['input']>;
  img_mobile?: InputMaybe<Scalars['ID']['input']>;
  link_path?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  tagColor?: InputMaybe<Enum_Componentarticlearticlepreview_Tagcolor>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentArticleGallery = {
  __typename?: 'ComponentArticleGallery';
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<ComponentUiImage>>>;
};

export type ComponentArticleGalleryImagesArgs = {
  filters?: InputMaybe<ComponentUiImageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentArticleTextContent = {
  __typename?: 'ComponentArticleTextContent';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentCompanyCampuses = {
  __typename?: 'ComponentCompanyCampuses';
  id: Scalars['ID']['output'];
};

export type ComponentCompanyFacilities = {
  __typename?: 'ComponentCompanyFacilities';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  map?: Maybe<Array<Maybe<ComponentCompanyMap>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentCompanyFacilitiesMapArgs = {
  filters?: InputMaybe<ComponentCompanyMapFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentCompanyFacilitiesInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  map?: InputMaybe<Array<InputMaybe<ComponentCompanyMapInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentCompanyMap = {
  __typename?: 'ComponentCompanyMap';
  alt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  img_desktop?: Maybe<UploadFileEntityResponse>;
  img_mobile?: Maybe<UploadFileEntityResponse>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ComponentCompanyMapFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentCompanyMapFiltersInput>>>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentCompanyMapFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentCompanyMapFiltersInput>>>;
};

export type ComponentCompanyMapInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  img_desktop?: InputMaybe<Scalars['ID']['input']>;
  img_mobile?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentFooterColumn = {
  __typename?: 'ComponentFooterColumn';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Array<Maybe<ComponentFooterLink>>>;
};

export type ComponentFooterColumnLinkArgs = {
  filters?: InputMaybe<ComponentFooterLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentFooterColumnFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentFooterColumnFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  link?: InputMaybe<ComponentFooterLinkFiltersInput>;
  not?: InputMaybe<ComponentFooterColumnFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentFooterColumnFiltersInput>>>;
};

export type ComponentFooterColumnInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Array<InputMaybe<ComponentFooterLinkInput>>>;
};

export type ComponentFooterContact = {
  __typename?: 'ComponentFooterContact';
  address?: Maybe<ComponentFooterLink>;
  email?: Maybe<ComponentFooterLink>;
  id: Scalars['ID']['output'];
  phone?: Maybe<ComponentFooterLink>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentFooterContactInput = {
  address?: InputMaybe<ComponentFooterLinkInput>;
  email?: InputMaybe<ComponentFooterLinkInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  phone?: InputMaybe<ComponentFooterLinkInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentFooterLink = {
  __typename?: 'ComponentFooterLink';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

export type ComponentFooterLinkFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentFooterLinkFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentFooterLinkFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentFooterLinkFiltersInput>>>;
  path?: InputMaybe<StringFilterInput>;
};

export type ComponentFooterLinkInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentFooterPolicies = {
  __typename?: 'ComponentFooterPolicies';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Array<Maybe<ComponentFooterLink>>>;
};

export type ComponentFooterPoliciesLinkArgs = {
  filters?: InputMaybe<ComponentFooterLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentFooterPoliciesInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Array<InputMaybe<ComponentFooterLinkInput>>>;
};

export type ComponentFooterSocials = {
  __typename?: 'ComponentFooterSocials';
  id: Scalars['ID']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  tiktok?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
};

export type ComponentFooterSocialsInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  tiktok?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentHomepageImageCardsWIthLink = {
  __typename?: 'ComponentHomepageImageCardsWIthLink';
  id: Scalars['ID']['output'];
  imageCardLink?: Maybe<Array<Maybe<ComponentUiImageCardLink>>>;
};

export type ComponentHomepageImageCardsWIthLinkImageCardLinkArgs = {
  filters?: InputMaybe<ComponentUiImageCardLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentHomepageImageCardsWIthLinkInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  imageCardLink?: InputMaybe<Array<InputMaybe<ComponentUiImageCardLinkInput>>>;
};

export type ComponentHomepagePartners = {
  __typename?: 'ComponentHomepagePartners';
  article?: Maybe<Array<Maybe<ComponentUiArticle>>>;
  button?: Maybe<ComponentUiButton>;
  icon?: Maybe<Array<Maybe<ComponentUiIcon>>>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentHomepagePartnersArticleArgs = {
  filters?: InputMaybe<ComponentUiArticleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentHomepagePartnersIconArgs = {
  filters?: InputMaybe<ComponentUiIconFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentHomepagePartnersInput = {
  article?: InputMaybe<Array<InputMaybe<ComponentUiArticleInput>>>;
  button?: InputMaybe<ComponentUiButtonInput>;
  icon?: InputMaybe<Array<InputMaybe<ComponentUiIconInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentNavigationClosedMenuLinks = {
  __typename?: 'ComponentNavigationClosedMenuLinks';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export type ComponentNavigationClosedMenuLinksFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentNavigationClosedMenuLinksFiltersInput>>
  >;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentNavigationClosedMenuLinksFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentNavigationClosedMenuLinksFiltersInput>>
  >;
  path?: InputMaybe<StringFilterInput>;
};

export type ComponentNavigationClosedMenuLinksInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentNavigationContact = {
  __typename?: 'ComponentNavigationContact';
  id: Scalars['ID']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  linkedin?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  tiktok?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
};

export type ComponentNavigationContactInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  tiktok?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentNavigationIconLink = {
  __typename?: 'ComponentNavigationIconLink';
  alt: Scalars['String']['output'];
  icon: UploadFileEntityResponse;
  id: Scalars['ID']['output'];
  path: Scalars['String']['output'];
};

export type ComponentNavigationLink = {
  __typename?: 'ComponentNavigationLink';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export type ComponentNavigationLinkFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentNavigationLinkFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentNavigationLinkFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentNavigationLinkFiltersInput>>>;
  path?: InputMaybe<StringFilterInput>;
};

export type ComponentNavigationLinkInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentNavigationMenuLinksClosed = {
  __typename?: 'ComponentNavigationMenuLinksClosed';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  path: Scalars['String']['output'];
  sub_link?: Maybe<SubLinkEntityResponse>;
};

export type ComponentNavigationMenuLinksClosedFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentNavigationMenuLinksClosedFiltersInput>>
  >;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentNavigationMenuLinksClosedFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentNavigationMenuLinksClosedFiltersInput>>
  >;
  path?: InputMaybe<StringFilterInput>;
  sub_link?: InputMaybe<SubLinkFiltersInput>;
};

export type ComponentNavigationMenuLinksClosedInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  sub_link?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentNavigationMenuLinksOpen = {
  __typename?: 'ComponentNavigationMenuLinksOpen';
  id: Scalars['ID']['output'];
};

export type ComponentNavigationNavigation = {
  __typename?: 'ComponentNavigationNavigation';
  closedMenuLinks?: Maybe<Array<Maybe<ComponentNavigationClosedMenuLinks>>>;
  id: Scalars['ID']['output'];
  openMenuLinks: Array<Maybe<ComponentNavigationMenuLinksClosed>>;
};

export type ComponentNavigationNavigationClosedMenuLinksArgs = {
  filters?: InputMaybe<ComponentNavigationClosedMenuLinksFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentNavigationNavigationOpenMenuLinksArgs = {
  filters?: InputMaybe<ComponentNavigationMenuLinksClosedFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentNavigationNavigationInput = {
  closedMenuLinks?: InputMaybe<
    Array<InputMaybe<ComponentNavigationClosedMenuLinksInput>>
  >;
  id?: InputMaybe<Scalars['ID']['input']>;
  openMenuLinks?: InputMaybe<
    Array<InputMaybe<ComponentNavigationMenuLinksClosedInput>>
  >;
};

export type ComponentPoliciesBullets = {
  __typename?: 'ComponentPoliciesBullets';
  bullet?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isIndented: Scalars['Boolean']['output'];
};

export type ComponentPoliciesDevider = {
  __typename?: 'ComponentPoliciesDevider';
  Devider?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
};

export type ComponentPoliciesDownloadLink = {
  __typename?: 'ComponentPoliciesDownloadLink';
  file?: Maybe<UploadFileEntityResponse>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
};

export type ComponentPoliciesMainHeading = {
  __typename?: 'ComponentPoliciesMainHeading';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['JSON']['output']>;
};

export type ComponentPoliciesParagpraph = {
  __typename?: 'ComponentPoliciesParagpraph';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type ComponentPoliciesRowItem = {
  __typename?: 'ComponentPoliciesRowItem';
  id: Scalars['ID']['output'];
  item?: Maybe<Scalars['String']['output']>;
};

export type ComponentPoliciesRowItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentPoliciesRowItemFiltersInput>>>;
  item?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentPoliciesRowItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentPoliciesRowItemFiltersInput>>>;
};

export type ComponentPoliciesRowItemInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  item?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentPoliciesTableHeading = {
  __typename?: 'ComponentPoliciesTableHeading';
  headingItem?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type ComponentPoliciesTableHeadingFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentPoliciesTableHeadingFiltersInput>>
  >;
  headingItem?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentPoliciesTableHeadingFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentPoliciesTableHeadingFiltersInput>>>;
};

export type ComponentPoliciesTableHeadingInput = {
  headingItem?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentPoliciesTableRow = {
  __typename?: 'ComponentPoliciesTableRow';
  id: Scalars['ID']['output'];
  table_rows?: Maybe<TableRowRelationResponseCollection>;
};

export type ComponentPoliciesTableRowTable_RowsArgs = {
  filters?: InputMaybe<TableRowFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentPoliciesTableRowInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  table_rows?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ComponentPoliciesTableTextContent = {
  __typename?: 'ComponentPoliciesTableTextContent';
  id: Scalars['ID']['output'];
  richText?: Maybe<Scalars['JSON']['output']>;
  tableHead?: Maybe<Array<Maybe<ComponentPoliciesTableHeading>>>;
  tableRow?: Maybe<ComponentPoliciesTableRow>;
};

export type ComponentPoliciesTableTextContentTableHeadArgs = {
  filters?: InputMaybe<ComponentPoliciesTableHeadingFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentPoliciesTableTextContentInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  richText?: InputMaybe<Scalars['JSON']['input']>;
  tableHead?: InputMaybe<Array<InputMaybe<ComponentPoliciesTableHeadingInput>>>;
  tableRow?: InputMaybe<ComponentPoliciesTableRowInput>;
};

export type ComponentPoliciesTitleTextContent = {
  __typename?: 'ComponentPoliciesTitleTextContent';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['JSON']['output']>;
};

export type ComponentPowertrainTechnologies = {
  __typename?: 'ComponentPowertrainTechnologies';
  anchor?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  paragraphs?: Maybe<Array<Maybe<ComponentUiParagraph>>>;
  spec_tables?: Maybe<SpecTableRelationResponseCollection>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentPowertrainTechnologiesParagraphsArgs = {
  filters?: InputMaybe<ComponentUiParagraphFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentPowertrainTechnologiesSpec_TablesArgs = {
  filters?: InputMaybe<SpecTableFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentPowertrainTechnologiesInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ComponentUiImageInput>;
  paragraphs?: InputMaybe<Array<InputMaybe<ComponentUiParagraphInput>>>;
  spec_tables?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentPowertrainTechnologiesTable = {
  __typename?: 'ComponentPowertrainTechnologiesTable';
  id: Scalars['ID']['output'];
  spec?: Maybe<Array<Maybe<ComponentUiSpec>>>;
  table_label?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentPowertrainTechnologiesTableSpecArgs = {
  filters?: InputMaybe<ComponentUiSpecFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedLargeImageSection = {
  __typename?: 'ComponentSharedLargeImageSection';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedLargeImageSectionInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<ComponentUiButtonInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ComponentUiImageInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedMetaSocial = {
  __typename?: 'ComponentSharedMetaSocial';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
  title: Scalars['String']['output'];
};

export type ComponentSharedMetaSocialFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
  socialNetwork?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedMetaSocialInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  socialNetwork?: InputMaybe<Enum_Componentsharedmetasocial_Socialnetwork>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedNewsroom = {
  __typename?: 'ComponentSharedNewsroom';
  anchor?: Maybe<Scalars['String']['output']>;
  articles?: Maybe<ArticleRelationResponseCollection>;
  button?: Maybe<ComponentUiButton>;
  id: Scalars['ID']['output'];
  logo?: Maybe<Array<Maybe<ComponentUiIcon>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedNewsroomArticlesArgs = {
  filters?: InputMaybe<ArticleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedNewsroomLogoArgs = {
  filters?: InputMaybe<ComponentUiIconFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedNewsroomInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  articles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  button?: InputMaybe<ComponentUiButtonInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  logo?: InputMaybe<Array<InputMaybe<ComponentUiIconInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedProductImageBlock = {
  __typename?: 'ComponentSharedProductImageBlock';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  gallery?: Maybe<Array<Maybe<ComponentUiImage>>>;
  id: Scalars['ID']['output'];
  largeSpec?: Maybe<Array<Maybe<ComponentUiSpec>>>;
  spec_tables?: Maybe<SpecTableRelationResponseCollection>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedProductImageBlockGalleryArgs = {
  filters?: InputMaybe<ComponentUiImageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedProductImageBlockLargeSpecArgs = {
  filters?: InputMaybe<ComponentUiSpecFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedProductImageBlockSpec_TablesArgs = {
  filters?: InputMaybe<SpecTableFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedProductImageBlockFiltersInput = {
  anchor?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<
    Array<InputMaybe<ComponentSharedProductImageBlockFiltersInput>>
  >;
  button?: InputMaybe<ComponentUiButtonFiltersInput>;
  gallery?: InputMaybe<ComponentUiImageFiltersInput>;
  largeSpec?: InputMaybe<ComponentUiSpecFiltersInput>;
  not?: InputMaybe<ComponentSharedProductImageBlockFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentSharedProductImageBlockFiltersInput>>
  >;
  spec_tables?: InputMaybe<SpecTableFiltersInput>;
  subtitle?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedProductImageBlockInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<ComponentUiButtonInput>;
  gallery?: InputMaybe<Array<InputMaybe<ComponentUiImageInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  largeSpec?: InputMaybe<Array<InputMaybe<ComponentUiSpecInput>>>;
  spec_tables?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedProductSpecsBlocks = {
  __typename?: 'ComponentSharedProductSpecsBlocks';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  specs_options?: Maybe<SpecsOptionRelationResponseCollection>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedProductSpecsBlocksSpecs_OptionsArgs = {
  filters?: InputMaybe<SpecsOptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedProductSpecsBlocksFiltersInput = {
  anchor?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<
    Array<InputMaybe<ComponentSharedProductSpecsBlocksFiltersInput>>
  >;
  not?: InputMaybe<ComponentSharedProductSpecsBlocksFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentSharedProductSpecsBlocksFiltersInput>>
  >;
  specs_options?: InputMaybe<SpecsOptionFiltersInput>;
  subtitle?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedProductSpecsBlocksInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  specs_options?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedSectionCards = {
  __typename?: 'ComponentSharedSectionCards';
  anchor?: Maybe<Scalars['String']['output']>;
  iconCard?: Maybe<Array<Maybe<ComponentUiIconCard>>>;
  id: Scalars['ID']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ComponentSharedSectionCardsIconCardArgs = {
  filters?: InputMaybe<ComponentUiIconCardFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedSectionCardsInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  iconCard?: InputMaybe<Array<InputMaybe<ComponentUiIconCardInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedSeo = {
  __typename?: 'ComponentSharedSeo';
  canonicalURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keywords?: Maybe<Scalars['String']['output']>;
  metaDescription: Scalars['String']['output'];
  metaImage?: Maybe<UploadFileEntityResponse>;
  metaRobots?: Maybe<Scalars['String']['output']>;
  metaSocial?: Maybe<Array<Maybe<ComponentSharedMetaSocial>>>;
  metaTitle: Scalars['String']['output'];
  metaViewport?: Maybe<Scalars['String']['output']>;
  structuredData?: Maybe<Scalars['JSON']['output']>;
};

export type ComponentSharedSeoMetaSocialArgs = {
  filters?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedSeoInput = {
  canonicalURL?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaImage?: InputMaybe<Scalars['ID']['input']>;
  metaRobots?: InputMaybe<Scalars['String']['input']>;
  metaSocial?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialInput>>>;
  metaTitle?: InputMaybe<Scalars['String']['input']>;
  metaViewport?: InputMaybe<Scalars['String']['input']>;
  structuredData?: InputMaybe<Scalars['JSON']['input']>;
};

export type ComponentSharedSliderImageSection = {
  __typename?: 'ComponentSharedSliderImageSection';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  id: Scalars['ID']['output'];
  imageCardLink?: Maybe<Array<Maybe<ComponentUiImageCardLink>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedSliderImageSectionImageCardLinkArgs = {
  filters?: InputMaybe<ComponentUiImageCardLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedSliderImageSectionInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<ComponentUiButtonInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageCardLink?: InputMaybe<Array<InputMaybe<ComponentUiImageCardLinkInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedTwoImagesGrid = {
  __typename?: 'ComponentSharedTwoImagesGrid';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imagesGridItem?: Maybe<Array<Maybe<ComponentUiCertification>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedTwoImagesGridImagesGridItemArgs = {
  filters?: InputMaybe<ComponentUiCertificationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedTwoImagesGridFiltersInput = {
  anchor?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentSharedTwoImagesGridFiltersInput>>>;
  imagesGridItem?: InputMaybe<ComponentUiCertificationFiltersInput>;
  not?: InputMaybe<ComponentSharedTwoImagesGridFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedTwoImagesGridFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedTwoImagesGridInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imagesGridItem?: InputMaybe<Array<InputMaybe<ComponentUiCertificationInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedValues = {
  __typename?: 'ComponentSharedValues';
  anchor?: Maybe<Scalars['String']['output']>;
  cards?: Maybe<Array<Maybe<ComponentUiIconCard>>>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedValuesCardsArgs = {
  filters?: InputMaybe<ComponentUiIconCardFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedValuesInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  cards?: InputMaybe<Array<InputMaybe<ComponentUiIconCardInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiArticle = {
  __typename?: 'ComponentUiArticle';
  alt: Scalars['String']['output'];
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img_desktop: UploadFileEntityResponse;
  img_mobile?: Maybe<UploadFileEntityResponse>;
  link_path: Scalars['String']['output'];
  tag: Scalars['String']['output'];
  tagColor?: Maybe<Enum_Componentuiarticle_Tagcolor>;
  title: Scalars['String']['output'];
};

export type ComponentUiArticleFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiArticleFiltersInput>>>;
  date?: InputMaybe<StringFilterInput>;
  link_path?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiArticleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiArticleFiltersInput>>>;
  tag?: InputMaybe<StringFilterInput>;
  tagColor?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiArticleInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  img_desktop?: InputMaybe<Scalars['ID']['input']>;
  img_mobile?: InputMaybe<Scalars['ID']['input']>;
  link_path?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  tagColor?: InputMaybe<Enum_Componentuiarticle_Tagcolor>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiButton = {
  __typename?: 'ComponentUiButton';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  path?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiButtonFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiButtonFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiButtonFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiButtonFiltersInput>>>;
  path?: InputMaybe<StringFilterInput>;
};

export type ComponentUiButtonInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiCampus = {
  __typename?: 'ComponentUiCampus';
  Text?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  spec?: Maybe<Array<Maybe<ComponentUiSpec>>>;
};

export type ComponentUiCampusSpecArgs = {
  filters?: InputMaybe<ComponentUiSpecFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiCampusFiltersInput = {
  Text?: InputMaybe<StringFilterInput>;
  Title?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiCampusFiltersInput>>>;
  image?: InputMaybe<ComponentUiImageFiltersInput>;
  not?: InputMaybe<ComponentUiCampusFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiCampusFiltersInput>>>;
  spec?: InputMaybe<ComponentUiSpecFiltersInput>;
};

export type ComponentUiCampusInput = {
  Text?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ComponentUiImageInput>;
  spec?: InputMaybe<Array<InputMaybe<ComponentUiSpecInput>>>;
};

export type ComponentUiCard = {
  __typename?: 'ComponentUiCard';
  Title?: Maybe<Scalars['String']['output']>;
  button?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  file?: Maybe<UploadFileRelationResponseCollection>;
  id: Scalars['ID']['output'];
};

export type ComponentUiCardFileArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiCardFiltersInput = {
  Title?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiCardFiltersInput>>>;
  button?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiCardFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiCardFiltersInput>>>;
};

export type ComponentUiCardInput = {
  Title?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentUiCertification = {
  __typename?: 'ComponentUiCertification';
  alt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img_desktop: UploadFileEntityResponse;
  img_mobile?: Maybe<UploadFileEntityResponse>;
  tag?: Maybe<Scalars['String']['output']>;
  tagColor?: Maybe<Enum_Componentuicertification_Tagcolor>;
  title: Scalars['String']['output'];
};

export type ComponentUiCertificationFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiCertificationFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiCertificationFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiCertificationFiltersInput>>>;
  tag?: InputMaybe<StringFilterInput>;
  tagColor?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiCertificationInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  img_desktop?: InputMaybe<Scalars['ID']['input']>;
  img_mobile?: InputMaybe<Scalars['ID']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  tagColor?: InputMaybe<Enum_Componentuicertification_Tagcolor>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiContent = {
  __typename?: 'ComponentUiContent';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiContentFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiContentFiltersInput>>>;
  not?: InputMaybe<ComponentUiContentFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiContentFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
};

export type ComponentUiDenseSpec = {
  __typename?: 'ComponentUiDenseSpec';
  id: Scalars['ID']['output'];
  lable?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Array<Maybe<ComponentUiValue>>>;
};

export type ComponentUiDenseSpecValueArgs = {
  filters?: InputMaybe<ComponentUiValueFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiFooterRow = {
  __typename?: 'ComponentUiFooterRow';
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiFooterRowIcons = {
  __typename?: 'ComponentUiFooterRowIcons';
  icon?: Maybe<Array<Maybe<ComponentUiIcon>>>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiFooterRowIconsIconArgs = {
  filters?: InputMaybe<ComponentUiIconFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiGraph = {
  __typename?: 'ComponentUiGraph';
  id: Scalars['ID']['output'];
  image: UploadFileEntityResponse;
  label?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiGraphFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiGraphFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiGraphFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiGraphFiltersInput>>>;
};

export type ComponentUiGraphInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiGuidelines = {
  __typename?: 'ComponentUiGuidelines';
  anchor?: Maybe<Scalars['String']['output']>;
  card?: Maybe<Array<Maybe<ComponentUiCard>>>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiGuidelinesCardArgs = {
  filters?: InputMaybe<ComponentUiCardFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiGuidelinesInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  card?: InputMaybe<Array<InputMaybe<ComponentUiCardInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiHeading = {
  __typename?: 'ComponentUiHeading';
  id: Scalars['ID']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiHeadingFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiHeadingFiltersInput>>>;
  not?: InputMaybe<ComponentUiHeadingFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiHeadingFiltersInput>>>;
  subtitle?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiHeadingInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiHeadline = {
  __typename?: 'ComponentUiHeadline';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  link_path?: Maybe<Scalars['String']['output']>;
  link_text?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ComponentUiHeroImageSection = {
  __typename?: 'ComponentUiHeroImageSection';
  button?: Maybe<ComponentUiButton>;
  heroImage?: Maybe<ComponentUiImage>;
  id: Scalars['ID']['output'];
  previewVideo?: Maybe<ComponentUiVideo>;
  previewVideoLength?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ComponentUiHeroImageSectionInput = {
  button?: InputMaybe<ComponentUiButtonInput>;
  heroImage?: InputMaybe<ComponentUiImageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  previewVideo?: InputMaybe<ComponentUiVideoInput>;
  previewVideoLength?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiHeroVideoSection = {
  __typename?: 'ComponentUiHeroVideoSection';
  button?: Maybe<ComponentUiButton>;
  heroVideo?: Maybe<ComponentUiVideo>;
  id: Scalars['ID']['output'];
  previewVideo?: Maybe<ComponentUiVideo>;
  previewVideoLength?: Maybe<Scalars['String']['output']>;
  showPreviewVideo?: Maybe<Scalars['Boolean']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ComponentUiHeroVideoSectionInput = {
  button?: InputMaybe<ComponentUiButtonInput>;
  heroVideo?: InputMaybe<ComponentUiVideoInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  previewVideo?: InputMaybe<ComponentUiVideoInput>;
  previewVideoLength?: InputMaybe<Scalars['String']['input']>;
  showPreviewVideo?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiHistory = {
  __typename?: 'ComponentUiHistory';
  Title?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  item?: Maybe<Array<Maybe<ComponentUiHistoryItem>>>;
  text?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiHistoryItemArgs = {
  filters?: InputMaybe<ComponentUiHistoryItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiHistoryItem = {
  __typename?: 'ComponentUiHistoryItem';
  alt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  extra_info?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  img_desktop_png_2x?: Maybe<UploadFileEntityResponse>;
  img_desktop_png_3x?: Maybe<UploadFileEntityResponse>;
  img_desktop_webp?: Maybe<UploadFileEntityResponse>;
  img_mobile_png_2x?: Maybe<UploadFileEntityResponse>;
  img_mobile_png_3x?: Maybe<UploadFileEntityResponse>;
  img_mobile_webp?: Maybe<UploadFileEntityResponse>;
  img_tablet_png_2x?: Maybe<UploadFileEntityResponse>;
  img_tablet_png_3x?: Maybe<UploadFileEntityResponse>;
  img_tablet_webp?: Maybe<UploadFileEntityResponse>;
  title?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiHistoryItemFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiHistoryItemFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  extra_info?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiHistoryItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiHistoryItemFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
  year?: InputMaybe<StringFilterInput>;
};

export type ComponentUiIcon = {
  __typename?: 'ComponentUiIcon';
  alt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  svg: UploadFileEntityResponse;
};

export type ComponentUiIconCard = {
  __typename?: 'ComponentUiIconCard';
  description: Scalars['String']['output'];
  icon: UploadFileEntityResponse;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type ComponentUiIconCardFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiIconCardFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiIconCardFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiIconCardFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiIconCardInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiIconFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiIconFiltersInput>>>;
  not?: InputMaybe<ComponentUiIconFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiIconFiltersInput>>>;
};

export type ComponentUiIconInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  svg?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentUiIconLink = {
  __typename?: 'ComponentUiIconLink';
  icon: UploadFileEntityResponse;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiImage = {
  __typename?: 'ComponentUiImage';
  alt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img_desktop: UploadFileEntityResponse;
  img_mobile?: Maybe<UploadFileEntityResponse>;
};

export type ComponentUiImageCardLink = {
  __typename?: 'ComponentUiImageCardLink';
  alt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  img_desktop: UploadFileEntityResponse;
  img_mobil?: Maybe<UploadFileEntityResponse>;
  link_label?: Maybe<Scalars['String']['output']>;
  link_path?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiImageCardLinkFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiImageCardLinkFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  link_label?: InputMaybe<StringFilterInput>;
  link_path?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiImageCardLinkFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiImageCardLinkFiltersInput>>>;
  tag?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiImageCardLinkInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  img_desktop?: InputMaybe<Scalars['ID']['input']>;
  img_mobil?: InputMaybe<Scalars['ID']['input']>;
  link_label?: InputMaybe<Scalars['String']['input']>;
  link_path?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiImageFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiImageFiltersInput>>>;
  not?: InputMaybe<ComponentUiImageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiImageFiltersInput>>>;
};

export type ComponentUiImageInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  img_desktop?: InputMaybe<Scalars['ID']['input']>;
  img_mobile?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentUiList = {
  __typename?: 'ComponentUiList';
  id: Scalars['ID']['output'];
  indent?: Maybe<Scalars['Boolean']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiListFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiListFiltersInput>>>;
  indent?: InputMaybe<BooleanFilterInput>;
  not?: InputMaybe<ComponentUiListFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiListFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
};

export type ComponentUiMiniature = {
  __typename?: 'ComponentUiMiniature';
  alt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  miniature?: Maybe<UploadFileEntityResponse>;
};

export type ComponentUiNews = {
  __typename?: 'ComponentUiNews';
  article?: Maybe<Array<Maybe<ComponentUiArticle>>>;
  button_label?: Maybe<Scalars['String']['output']>;
  button_path?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiNewsArticleArgs = {
  filters?: InputMaybe<ComponentUiArticleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiNextVideo = {
  __typename?: 'ComponentUiNextVideo';
  blurhash?: Maybe<Scalars['String']['output']>;
  full_video: UploadFileEntityResponse;
  id: Scalars['ID']['output'];
  poster: UploadFileEntityResponse;
  poster_alt?: Maybe<Scalars['String']['output']>;
  video_length: Scalars['String']['output'];
};

export type ComponentUiOptions = {
  __typename?: 'ComponentUiOptions';
  id: Scalars['ID']['output'];
  option?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiParagraph = {
  __typename?: 'ComponentUiParagraph';
  id: Scalars['ID']['output'];
  paragraphs?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiParagraphFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiParagraphFiltersInput>>>;
  not?: InputMaybe<ComponentUiParagraphFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiParagraphFiltersInput>>>;
  paragraphs?: InputMaybe<StringFilterInput>;
};

export type ComponentUiParagraphInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  paragraphs?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiPartnersSection = {
  __typename?: 'ComponentUiPartnersSection';
  Title?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Array<Maybe<ComponentUiIcon>>>;
  id: Scalars['ID']['output'];
};

export type ComponentUiPartnersSectionIconArgs = {
  filters?: InputMaybe<ComponentUiIconFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiPolicySection = {
  __typename?: 'ComponentUiPolicySection';
  content?: Maybe<Array<Maybe<ComponentUiContent>>>;
  cookiesName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  list?: Maybe<Array<Maybe<ComponentUiList>>>;
  table?: Maybe<ComponentUiTable>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiPolicySectionContentArgs = {
  filters?: InputMaybe<ComponentUiContentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiPolicySectionListArgs = {
  filters?: InputMaybe<ComponentUiListFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiSection = {
  __typename?: 'ComponentUiSection';
  headline?: Maybe<ComponentUiHeadline>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  image2?: Maybe<ComponentUiImage>;
};

export type ComponentUiSpec = {
  __typename?: 'ComponentUiSpec';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiSpecFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiSpecFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiSpecFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiSpecFiltersInput>>>;
  value?: InputMaybe<StringFilterInput>;
};

export type ComponentUiSpecInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiSpecTable = {
  __typename?: 'ComponentUiSpecTable';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  spec: Array<Maybe<ComponentUiSpec>>;
};

export type ComponentUiSpecTableSpecArgs = {
  filters?: InputMaybe<ComponentUiSpecFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiSpecTableFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiSpecTableFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiSpecTableFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiSpecTableFiltersInput>>>;
  spec?: InputMaybe<ComponentUiSpecFiltersInput>;
};

export type ComponentUiSpecTableInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  spec?: InputMaybe<Array<InputMaybe<ComponentUiSpecInput>>>;
};

export type ComponentUiTable = {
  __typename?: 'ComponentUiTable';
  head?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  rows?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiTeam = {
  __typename?: 'ComponentUiTeam';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  team_mate?: Maybe<Array<Maybe<ComponentUiTeamMate>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiTeamTeam_MateArgs = {
  filters?: InputMaybe<ComponentUiTeamMateFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiTeamInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  team_mate?: InputMaybe<Array<InputMaybe<ComponentUiTeamMateInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiTeamMate = {
  __typename?: 'ComponentUiTeamMate';
  alt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  img_desktop?: Maybe<UploadFileEntityResponse>;
  img_mobile?: Maybe<UploadFileEntityResponse>;
  last_name?: Maybe<Scalars['String']['output']>;
  miniature?: Maybe<UploadFileEntityResponse>;
  name?: Maybe<Scalars['String']['output']>;
  positions?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiTeamMateFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiTeamMateFiltersInput>>>;
  last_name?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiTeamMateFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiTeamMateFiltersInput>>>;
  positions?: InputMaybe<StringFilterInput>;
};

export type ComponentUiTeamMateInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  img_desktop?: InputMaybe<Scalars['ID']['input']>;
  img_mobile?: InputMaybe<Scalars['ID']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  miniature?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  positions?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiValue = {
  __typename?: 'ComponentUiValue';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiValueFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiValueFiltersInput>>>;
  not?: InputMaybe<ComponentUiValueFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiValueFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
};

export type ComponentUiVideo = {
  __typename?: 'ComponentUiVideo';
  autoplay?: Maybe<Scalars['Boolean']['output']>;
  controls?: Maybe<Scalars['Boolean']['output']>;
  fullscreen?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  loop?: Maybe<Scalars['Boolean']['output']>;
  mobilePoster?: Maybe<UploadFileEntityResponse>;
  muted?: Maybe<Scalars['Boolean']['output']>;
  pauseVideo?: Maybe<Scalars['Boolean']['output']>;
  poster: UploadFileEntityResponse;
  poster_alt?: Maybe<Scalars['String']['output']>;
  video?: Maybe<UploadFileEntityResponse>;
};

export type ComponentUiVideoInput = {
  autoplay?: InputMaybe<Scalars['Boolean']['input']>;
  controls?: InputMaybe<Scalars['Boolean']['input']>;
  fullscreen?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  loop?: InputMaybe<Scalars['Boolean']['input']>;
  mobilePoster?: InputMaybe<Scalars['ID']['input']>;
  muted?: InputMaybe<Scalars['Boolean']['input']>;
  pauseVideo?: InputMaybe<Scalars['Boolean']['input']>;
  poster?: InputMaybe<Scalars['ID']['input']>;
  poster_alt?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Scalars['ID']['input']>;
};

export type ContactFomResponse = {
  __typename?: 'ContactFomResponse';
  error?: Maybe<ErrorResult>;
  id?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ContactFormDataInput = {
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  interest: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  receiveOffers?: InputMaybe<Scalars['Boolean']['input']>;
  terms: Scalars['Boolean']['input'];
};

export type CookiePolicy = {
  __typename?: 'CookiePolicy';
  cookiePolicy?: Maybe<Array<Maybe<CookiePolicyCookiePolicyDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CookiePolicyCookiePolicyDynamicZone =
  | ComponentPoliciesDevider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export type CookiePolicyEntity = {
  __typename?: 'CookiePolicyEntity';
  attributes?: Maybe<CookiePolicy>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CookiePolicyEntityResponse = {
  __typename?: 'CookiePolicyEntityResponse';
  data?: Maybe<CookiePolicyEntity>;
};

export type CookiePolicyInput = {
  cookiePolicy?: InputMaybe<
    Array<Scalars['CookiePolicyCookiePolicyDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CookieSetting = {
  __typename?: 'CookieSetting';
  actionButtonLabel?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  necessaryCookiesHeading?: Maybe<Scalars['String']['output']>;
  necessaryCookiesTable?: Maybe<ComponentPoliciesTableTextContent>;
  optionalCookiesHeading?: Maybe<Scalars['String']['output']>;
  optionalCookiesTale?: Maybe<ComponentPoliciesTableTextContent>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  redirectButton?: Maybe<ComponentUiButton>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CookieSettingEntity = {
  __typename?: 'CookieSettingEntity';
  attributes?: Maybe<CookieSetting>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CookieSettingEntityResponse = {
  __typename?: 'CookieSettingEntityResponse';
  data?: Maybe<CookieSettingEntity>;
};

export type CookieSettingInput = {
  actionButtonLabel?: InputMaybe<Scalars['String']['input']>;
  necessaryCookiesHeading?: InputMaybe<Scalars['String']['input']>;
  necessaryCookiesTable?: InputMaybe<ComponentPoliciesTableTextContentInput>;
  optionalCookiesHeading?: InputMaybe<Scalars['String']['input']>;
  optionalCookiesTale?: InputMaybe<ComponentPoliciesTableTextContentInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  redirectButton?: InputMaybe<ComponentUiButtonInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CookiesBanner = {
  __typename?: 'CookiesBanner';
  actionButtonLabel?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  policyButton?: Maybe<ComponentUiButton>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CookiesBannerEntity = {
  __typename?: 'CookiesBannerEntity';
  attributes?: Maybe<CookiesBanner>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CookiesBannerEntityResponse = {
  __typename?: 'CookiesBannerEntityResponse';
  data?: Maybe<CookiesBannerEntity>;
};

export type CookiesBannerInput = {
  actionButtonLabel?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  policyButton?: InputMaybe<ComponentUiButtonInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  contains?: InputMaybe<Scalars['DateTime']['input']>;
  containsi?: InputMaybe<Scalars['DateTime']['input']>;
  endsWith?: InputMaybe<Scalars['DateTime']['input']>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  eqi?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  ne?: InputMaybe<Scalars['DateTime']['input']>;
  nei?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']['input']>;
  notContainsi?: InputMaybe<Scalars['DateTime']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum Enum_Componentarticlearticlepreview_Tagcolor {
  Dark = 'dark',
  Default = 'default',
  Light = 'light',
}

export enum Enum_Componentsharedmetasocial_Socialnetwork {
  Facebook = 'Facebook',
  Twitter = 'Twitter',
}

export enum Enum_Componentuiarticle_Tagcolor {
  Dark = 'dark',
  Default = 'default',
  Light = 'light',
}

export enum Enum_Componentuicertification_Tagcolor {
  Dark = 'dark',
  Default = 'default',
  Light = 'light',
}

export enum Enum_Sitemapsitemap_Type {
  DefaultHreflang = 'default_hreflang',
  Index = 'index',
}

export type Electronic = {
  __typename?: 'Electronic';
  cardsSection?: Maybe<ComponentSharedSectionCards>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroVideoSection: ComponentUiHeroVideoSection;
  newsroom?: Maybe<ComponentSharedNewsroom>;
  productImageBlock?: Maybe<Array<Maybe<ComponentSharedProductImageBlock>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  sliderImageSection?: Maybe<ComponentSharedSliderImageSection>;
  twoImagesGrid?: Maybe<ComponentSharedTwoImagesGrid>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ElectronicProductImageBlockArgs = {
  filters?: InputMaybe<ComponentSharedProductImageBlockFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ElectronicEntity = {
  __typename?: 'ElectronicEntity';
  attributes?: Maybe<Electronic>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ElectronicEntityResponse = {
  __typename?: 'ElectronicEntityResponse';
  data?: Maybe<ElectronicEntity>;
};

export type ElectronicInput = {
  cardsSection?: InputMaybe<ComponentSharedSectionCardsInput>;
  heroVideoSection?: InputMaybe<ComponentUiHeroVideoSectionInput>;
  newsroom?: InputMaybe<ComponentSharedNewsroomInput>;
  productImageBlock?: InputMaybe<
    Array<InputMaybe<ComponentSharedProductImageBlockInput>>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  sliderImageSection?: InputMaybe<ComponentSharedSliderImageSectionInput>;
  twoImagesGrid?: InputMaybe<ComponentSharedTwoImagesGridInput>;
};

export type EnvironmentalPolicy = {
  __typename?: 'EnvironmentalPolicy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  policies?: Maybe<Array<Maybe<EnvironmentalPolicyPoliciesDynamicZone>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EnvironmentalPolicyEntity = {
  __typename?: 'EnvironmentalPolicyEntity';
  attributes?: Maybe<EnvironmentalPolicy>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type EnvironmentalPolicyEntityResponse = {
  __typename?: 'EnvironmentalPolicyEntityResponse';
  data?: Maybe<EnvironmentalPolicyEntity>;
};

export type EnvironmentalPolicyInput = {
  policies?: InputMaybe<
    Array<Scalars['EnvironmentalPolicyPoliciesDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnvironmentalPolicyPoliciesDynamicZone =
  | ComponentPoliciesDevider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export type Error = {
  __typename?: 'Error';
  code: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type ErrorResult = {
  __typename?: 'ErrorResult';
  fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  message?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['String']['output']>;
};

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  contains?: InputMaybe<Scalars['Float']['input']>;
  containsi?: InputMaybe<Scalars['Float']['input']>;
  endsWith?: InputMaybe<Scalars['Float']['input']>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  eqi?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  nei?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']['input']>;
  notContainsi?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  startsWith?: InputMaybe<Scalars['Float']['input']>;
};

export type Footer = {
  __typename?: 'Footer';
  column?: Maybe<Array<Maybe<ComponentFooterColumn>>>;
  contact?: Maybe<ComponentFooterContact>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  policies?: Maybe<ComponentFooterPolicies>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  socials?: Maybe<ComponentFooterSocials>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FooterColumnArgs = {
  filters?: InputMaybe<ComponentFooterColumnFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FooterEntity = {
  __typename?: 'FooterEntity';
  attributes?: Maybe<Footer>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type FooterEntityResponse = {
  __typename?: 'FooterEntityResponse';
  data?: Maybe<FooterEntity>;
};

export type FooterInput = {
  column?: InputMaybe<Array<InputMaybe<ComponentFooterColumnInput>>>;
  contact?: InputMaybe<ComponentFooterContactInput>;
  policies?: InputMaybe<ComponentFooterPoliciesInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  socials?: InputMaybe<ComponentFooterSocialsInput>;
};

export type GenericMorph =
  | Article
  | Battery
  | Bms
  | Company
  | ComponentArticleArticleHero
  | ComponentArticleArticlePreview
  | ComponentArticleGallery
  | ComponentArticleTextContent
  | ComponentCompanyCampuses
  | ComponentCompanyFacilities
  | ComponentCompanyMap
  | ComponentFooterColumn
  | ComponentFooterContact
  | ComponentFooterLink
  | ComponentFooterPolicies
  | ComponentFooterSocials
  | ComponentHomepageImageCardsWIthLink
  | ComponentHomepagePartners
  | ComponentNavigationClosedMenuLinks
  | ComponentNavigationContact
  | ComponentNavigationIconLink
  | ComponentNavigationLink
  | ComponentNavigationMenuLinksClosed
  | ComponentNavigationMenuLinksOpen
  | ComponentNavigationNavigation
  | ComponentPoliciesBullets
  | ComponentPoliciesDevider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesParagpraph
  | ComponentPoliciesRowItem
  | ComponentPoliciesTableHeading
  | ComponentPoliciesTableRow
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | ComponentPowertrainTechnologies
  | ComponentPowertrainTechnologiesTable
  | ComponentSharedLargeImageSection
  | ComponentSharedMetaSocial
  | ComponentSharedNewsroom
  | ComponentSharedProductImageBlock
  | ComponentSharedProductSpecsBlocks
  | ComponentSharedSectionCards
  | ComponentSharedSeo
  | ComponentSharedSliderImageSection
  | ComponentSharedTwoImagesGrid
  | ComponentSharedValues
  | ComponentUiArticle
  | ComponentUiButton
  | ComponentUiCampus
  | ComponentUiCard
  | ComponentUiCertification
  | ComponentUiContent
  | ComponentUiDenseSpec
  | ComponentUiFooterRow
  | ComponentUiFooterRowIcons
  | ComponentUiGraph
  | ComponentUiGuidelines
  | ComponentUiHeading
  | ComponentUiHeadline
  | ComponentUiHeroImageSection
  | ComponentUiHeroVideoSection
  | ComponentUiHistory
  | ComponentUiHistoryItem
  | ComponentUiIcon
  | ComponentUiIconCard
  | ComponentUiIconLink
  | ComponentUiImage
  | ComponentUiImageCardLink
  | ComponentUiList
  | ComponentUiMiniature
  | ComponentUiNews
  | ComponentUiNextVideo
  | ComponentUiOptions
  | ComponentUiParagraph
  | ComponentUiPartnersSection
  | ComponentUiPolicySection
  | ComponentUiSection
  | ComponentUiSpec
  | ComponentUiSpecTable
  | ComponentUiTable
  | ComponentUiTeam
  | ComponentUiTeamMate
  | ComponentUiValue
  | ComponentUiVideo
  | CookiePolicy
  | CookieSetting
  | CookiesBanner
  | Electronic
  | EnvironmentalPolicy
  | Footer
  | GetInTouch
  | Home
  | I18NLocale
  | IriPage
  | Navigation
  | PartnersSection
  | Powertrain
  | PrivacyPolicy
  | SitemapSitemap
  | SitemapSitemapCache
  | Software
  | SpecTable
  | SpecsOption
  | SubLink
  | TableRow
  | TermsAndCondition
  | UploadFile
  | UploadFolder
  | UsersPermissionsPermission
  | UsersPermissionsRole
  | UsersPermissionsUser
  | WhistleblowingPolicy;

export type GetInTouch = {
  __typename?: 'GetInTouch';
  anchor?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  first_line?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Array<Maybe<ComponentUiSpec>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  second_line?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GetInTouchOptionsArgs = {
  filters?: InputMaybe<ComponentUiSpecFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GetInTouchEntity = {
  __typename?: 'GetInTouchEntity';
  attributes?: Maybe<GetInTouch>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type GetInTouchEntityResponse = {
  __typename?: 'GetInTouchEntityResponse';
  data?: Maybe<GetInTouchEntity>;
};

export type GetInTouchInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  first_line?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<InputMaybe<ComponentUiSpecInput>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  second_line?: InputMaybe<Scalars['String']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type Home = {
  __typename?: 'Home';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroVideoSection: ComponentUiHeroVideoSection;
  imageCardsWithLink?: Maybe<ComponentHomepageImageCardsWIthLink>;
  largeImageSection?: Maybe<ComponentSharedLargeImageSection>;
  newsroom?: Maybe<ComponentSharedNewsroom>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type HomeEntity = {
  __typename?: 'HomeEntity';
  attributes?: Maybe<Home>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type HomeEntityResponse = {
  __typename?: 'HomeEntityResponse';
  data?: Maybe<HomeEntity>;
};

export type HomeInput = {
  heroVideoSection?: InputMaybe<ComponentUiHeroVideoSectionInput>;
  imageCardsWithLink?: InputMaybe<ComponentHomepageImageCardsWIthLinkInput>;
  largeImageSection?: InputMaybe<ComponentSharedLargeImageSectionInput>;
  newsroom?: InputMaybe<ComponentSharedNewsroomInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  containsi?: InputMaybe<Scalars['ID']['input']>;
  endsWith?: InputMaybe<Scalars['ID']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  eqi?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  nei?: InputMaybe<Scalars['ID']['input']>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars['ID']['input']>;
  notContainsi?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startsWith?: InputMaybe<Scalars['ID']['input']>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  contains?: InputMaybe<Scalars['Int']['input']>;
  containsi?: InputMaybe<Scalars['Int']['input']>;
  endsWith?: InputMaybe<Scalars['Int']['input']>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  eqi?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
  nei?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']['input']>;
  notContainsi?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  startsWith?: InputMaybe<Scalars['Int']['input']>;
};

export type IriPage = {
  __typename?: 'IriPage';
  contents?: Maybe<Array<Maybe<IriPageContentsDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type IriPageContentsDynamicZone =
  | ComponentPoliciesDevider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export type IriPageEntity = {
  __typename?: 'IriPageEntity';
  attributes?: Maybe<IriPage>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type IriPageEntityResponse = {
  __typename?: 'IriPageEntityResponse';
  data?: Maybe<IriPageEntity>;
};

export type IriPageInput = {
  contents?: InputMaybe<
    Array<Scalars['IriPageContentsDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  contains?: InputMaybe<Scalars['JSON']['input']>;
  containsi?: InputMaybe<Scalars['JSON']['input']>;
  endsWith?: InputMaybe<Scalars['JSON']['input']>;
  eq?: InputMaybe<Scalars['JSON']['input']>;
  eqi?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  ne?: InputMaybe<Scalars['JSON']['input']>;
  nei?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']['input']>;
  notContainsi?: InputMaybe<Scalars['JSON']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  startsWith?: InputMaybe<Scalars['JSON']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  createArticle?: Maybe<ArticleEntityResponse>;
  createSitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  createSitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  createSpecTable?: Maybe<SpecTableEntityResponse>;
  createSpecsOption?: Maybe<SpecsOptionEntityResponse>;
  createSubLink?: Maybe<SubLinkEntityResponse>;
  createTableRow?: Maybe<TableRowEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteArticle?: Maybe<ArticleEntityResponse>;
  deleteBattery?: Maybe<BatteryEntityResponse>;
  deleteBms?: Maybe<BmsEntityResponse>;
  deleteCompany?: Maybe<CompanyEntityResponse>;
  deleteCookiePolicy?: Maybe<CookiePolicyEntityResponse>;
  deleteCookieSetting?: Maybe<CookieSettingEntityResponse>;
  deleteCookiesBanner?: Maybe<CookiesBannerEntityResponse>;
  deleteElectronic?: Maybe<ElectronicEntityResponse>;
  deleteEnvironmentalPolicy?: Maybe<EnvironmentalPolicyEntityResponse>;
  deleteFooter?: Maybe<FooterEntityResponse>;
  deleteGetInTouch?: Maybe<GetInTouchEntityResponse>;
  deleteHome?: Maybe<HomeEntityResponse>;
  deleteIriPage?: Maybe<IriPageEntityResponse>;
  deleteNavigation?: Maybe<NavigationEntityResponse>;
  deletePartnersSection?: Maybe<PartnersSectionEntityResponse>;
  deletePowertrain?: Maybe<PowertrainEntityResponse>;
  deletePrivacyPolicy?: Maybe<PrivacyPolicyEntityResponse>;
  deleteSitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  deleteSitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  deleteSoftware?: Maybe<SoftwareEntityResponse>;
  deleteSpecTable?: Maybe<SpecTableEntityResponse>;
  deleteSpecsOption?: Maybe<SpecsOptionEntityResponse>;
  deleteSubLink?: Maybe<SubLinkEntityResponse>;
  deleteTableRow?: Maybe<TableRowEntityResponse>;
  deleteTermsAndCondition?: Maybe<TermsAndConditionEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteWhistleblowingPolicy?: Maybe<WhistleblowingPolicyEntityResponse>;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  sendContactForm?: Maybe<ContactFomResponse>;
  updateArticle?: Maybe<ArticleEntityResponse>;
  updateBattery?: Maybe<BatteryEntityResponse>;
  updateBms?: Maybe<BmsEntityResponse>;
  updateCompany?: Maybe<CompanyEntityResponse>;
  updateCookiePolicy?: Maybe<CookiePolicyEntityResponse>;
  updateCookieSetting?: Maybe<CookieSettingEntityResponse>;
  updateCookiesBanner?: Maybe<CookiesBannerEntityResponse>;
  updateElectronic?: Maybe<ElectronicEntityResponse>;
  updateEnvironmentalPolicy?: Maybe<EnvironmentalPolicyEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateFooter?: Maybe<FooterEntityResponse>;
  updateGetInTouch?: Maybe<GetInTouchEntityResponse>;
  updateHome?: Maybe<HomeEntityResponse>;
  updateIriPage?: Maybe<IriPageEntityResponse>;
  updateNavigation?: Maybe<NavigationEntityResponse>;
  updatePartnersSection?: Maybe<PartnersSectionEntityResponse>;
  updatePowertrain?: Maybe<PowertrainEntityResponse>;
  updatePrivacyPolicy?: Maybe<PrivacyPolicyEntityResponse>;
  updateSitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  updateSitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  updateSoftware?: Maybe<SoftwareEntityResponse>;
  updateSpecTable?: Maybe<SpecTableEntityResponse>;
  updateSpecsOption?: Maybe<SpecsOptionEntityResponse>;
  updateSubLink?: Maybe<SubLinkEntityResponse>;
  updateTableRow?: Maybe<TableRowEntityResponse>;
  updateTermsAndCondition?: Maybe<TermsAndConditionEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  updateWhistleblowingPolicy?: Maybe<WhistleblowingPolicyEntityResponse>;
  upload: UploadFileEntityResponse;
};

export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type MutationCreateArticleArgs = {
  data: ArticleInput;
};

export type MutationCreateSitemapSitemapArgs = {
  data: SitemapSitemapInput;
};

export type MutationCreateSitemapSitemapCacheArgs = {
  data: SitemapSitemapCacheInput;
};

export type MutationCreateSpecTableArgs = {
  data: SpecTableInput;
};

export type MutationCreateSpecsOptionArgs = {
  data: SpecsOptionInput;
};

export type MutationCreateSubLinkArgs = {
  data: SubLinkInput;
};

export type MutationCreateTableRowArgs = {
  data: TableRowInput;
};

export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};

export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};

export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};

export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};

export type MutationDeleteArticleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSitemapSitemapArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSitemapSitemapCacheArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSpecTableArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSpecsOptionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSubLinkArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteTableRowArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String']['input'];
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};

export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  files: Array<InputMaybe<Scalars['Upload']['input']>>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};

export type MutationRemoveFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationResetPasswordArgs = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type MutationSendContactFormArgs = {
  data: ContactFormDataInput;
};

export type MutationUpdateArticleArgs = {
  data: ArticleInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateBatteryArgs = {
  data: BatteryInput;
};

export type MutationUpdateBmsArgs = {
  data: BmsInput;
};

export type MutationUpdateCompanyArgs = {
  data: CompanyInput;
};

export type MutationUpdateCookiePolicyArgs = {
  data: CookiePolicyInput;
};

export type MutationUpdateCookieSettingArgs = {
  data: CookieSettingInput;
};

export type MutationUpdateCookiesBannerArgs = {
  data: CookiesBannerInput;
};

export type MutationUpdateElectronicArgs = {
  data: ElectronicInput;
};

export type MutationUpdateEnvironmentalPolicyArgs = {
  data: EnvironmentalPolicyInput;
};

export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID']['input'];
  info?: InputMaybe<FileInfoInput>;
};

export type MutationUpdateFooterArgs = {
  data: FooterInput;
};

export type MutationUpdateGetInTouchArgs = {
  data: GetInTouchInput;
};

export type MutationUpdateHomeArgs = {
  data: HomeInput;
};

export type MutationUpdateIriPageArgs = {
  data: IriPageInput;
};

export type MutationUpdateNavigationArgs = {
  data: NavigationInput;
};

export type MutationUpdatePartnersSectionArgs = {
  data: PartnersSectionInput;
};

export type MutationUpdatePowertrainArgs = {
  data: PowertrainInput;
};

export type MutationUpdatePrivacyPolicyArgs = {
  data: PrivacyPolicyInput;
};

export type MutationUpdateSitemapSitemapArgs = {
  data: SitemapSitemapInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateSitemapSitemapCacheArgs = {
  data: SitemapSitemapCacheInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateSoftwareArgs = {
  data: SoftwareInput;
};

export type MutationUpdateSpecTableArgs = {
  data: SpecTableInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateSpecsOptionArgs = {
  data: SpecsOptionInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateSubLinkArgs = {
  data: SubLinkInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateTableRowArgs = {
  data: TableRowInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateTermsAndConditionArgs = {
  data: TermsAndConditionInput;
};

export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateWhistleblowingPolicyArgs = {
  data: WhistleblowingPolicyInput;
};

export type MutationUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type Navigation = {
  __typename?: 'Navigation';
  contact: ComponentNavigationContact;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  navigation: ComponentNavigationNavigation;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type NavigationEntity = {
  __typename?: 'NavigationEntity';
  attributes?: Maybe<Navigation>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type NavigationEntityResponse = {
  __typename?: 'NavigationEntityResponse';
  data?: Maybe<NavigationEntity>;
};

export type NavigationInput = {
  contact?: InputMaybe<ComponentNavigationContactInput>;
  navigation?: InputMaybe<ComponentNavigationNavigationInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type PartnersSection = {
  __typename?: 'PartnersSection';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  partners?: Maybe<ComponentHomepagePartners>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PartnersSectionEntity = {
  __typename?: 'PartnersSectionEntity';
  attributes?: Maybe<PartnersSection>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type PartnersSectionEntityResponse = {
  __typename?: 'PartnersSectionEntityResponse';
  data?: Maybe<PartnersSectionEntity>;
};

export type PartnersSectionInput = {
  partners?: InputMaybe<ComponentHomepagePartnersInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Powertrain = {
  __typename?: 'Powertrain';
  cardsSection?: Maybe<ComponentSharedSectionCards>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroVideoSection: ComponentUiHeroVideoSection;
  newsroom?: Maybe<ComponentSharedNewsroom>;
  productSpecsBlocks?: Maybe<Array<Maybe<ComponentSharedProductSpecsBlocks>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  technologies?: Maybe<ComponentPowertrainTechnologies>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PowertrainProductSpecsBlocksArgs = {
  filters?: InputMaybe<ComponentSharedProductSpecsBlocksFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PowertrainEntity = {
  __typename?: 'PowertrainEntity';
  attributes?: Maybe<Powertrain>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type PowertrainEntityResponse = {
  __typename?: 'PowertrainEntityResponse';
  data?: Maybe<PowertrainEntity>;
};

export type PowertrainInput = {
  cardsSection?: InputMaybe<ComponentSharedSectionCardsInput>;
  heroVideoSection?: InputMaybe<ComponentUiHeroVideoSectionInput>;
  newsroom?: InputMaybe<ComponentSharedNewsroomInput>;
  productSpecsBlocks?: InputMaybe<
    Array<InputMaybe<ComponentSharedProductSpecsBlocksInput>>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  technologies?: InputMaybe<ComponentPowertrainTechnologiesInput>;
};

export type PrivacyPolicy = {
  __typename?: 'PrivacyPolicy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  policies?: Maybe<Array<Maybe<PrivacyPolicyPoliciesDynamicZone>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PrivacyPolicyEntity = {
  __typename?: 'PrivacyPolicyEntity';
  attributes?: Maybe<PrivacyPolicy>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type PrivacyPolicyEntityResponse = {
  __typename?: 'PrivacyPolicyEntityResponse';
  data?: Maybe<PrivacyPolicyEntity>;
};

export type PrivacyPolicyInput = {
  policies?: InputMaybe<
    Array<Scalars['PrivacyPolicyPoliciesDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PrivacyPolicyPoliciesDynamicZone =
  | ComponentPoliciesDevider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW',
}

export type Query = {
  __typename?: 'Query';
  article?: Maybe<ArticleEntityResponse>;
  articles?: Maybe<ArticleEntityResponseCollection>;
  battery?: Maybe<BatteryEntityResponse>;
  bms?: Maybe<BmsEntityResponse>;
  company?: Maybe<CompanyEntityResponse>;
  cookiePolicy?: Maybe<CookiePolicyEntityResponse>;
  cookieSetting?: Maybe<CookieSettingEntityResponse>;
  cookiesBanner?: Maybe<CookiesBannerEntityResponse>;
  electronic?: Maybe<ElectronicEntityResponse>;
  environmentalPolicy?: Maybe<EnvironmentalPolicyEntityResponse>;
  footer?: Maybe<FooterEntityResponse>;
  getInTouch?: Maybe<GetInTouchEntityResponse>;
  home?: Maybe<HomeEntityResponse>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  iriPage?: Maybe<IriPageEntityResponse>;
  me?: Maybe<UsersPermissionsMe>;
  navigation?: Maybe<NavigationEntityResponse>;
  partnersSection?: Maybe<PartnersSectionEntityResponse>;
  powertrain?: Maybe<PowertrainEntityResponse>;
  privacyPolicy?: Maybe<PrivacyPolicyEntityResponse>;
  sitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  sitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  sitemapSitemapCaches?: Maybe<SitemapSitemapCacheEntityResponseCollection>;
  sitemapSitemaps?: Maybe<SitemapSitemapEntityResponseCollection>;
  software?: Maybe<SoftwareEntityResponse>;
  specTable?: Maybe<SpecTableEntityResponse>;
  specTables?: Maybe<SpecTableEntityResponseCollection>;
  specsOption?: Maybe<SpecsOptionEntityResponse>;
  specsOptions?: Maybe<SpecsOptionEntityResponseCollection>;
  subLink?: Maybe<SubLinkEntityResponse>;
  subLinks?: Maybe<SubLinkEntityResponseCollection>;
  tableRow?: Maybe<TableRowEntityResponse>;
  tableRows?: Maybe<TableRowEntityResponseCollection>;
  termsAndCondition?: Maybe<TermsAndConditionEntityResponse>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
  whistleblowingPolicy?: Maybe<WhistleblowingPolicyEntityResponse>;
};

export type QueryArticleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryArticlesArgs = {
  filters?: InputMaybe<ArticleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryBatteryArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryBmsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCompanyArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCookiePolicyArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCookieSettingArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCookiesBannerArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryElectronicArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryEnvironmentalPolicyArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryFooterArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryGetInTouchArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryHomeArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryIriPageArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryNavigationArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryPartnersSectionArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryPowertrainArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryPrivacyPolicyArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QuerySitemapSitemapArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySitemapSitemapCacheArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySitemapSitemapCachesArgs = {
  filters?: InputMaybe<SitemapSitemapCacheFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerySitemapSitemapsArgs = {
  filters?: InputMaybe<SitemapSitemapFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerySoftwareArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QuerySpecTableArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySpecTablesArgs = {
  filters?: InputMaybe<SpecTableFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerySpecsOptionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySpecsOptionsArgs = {
  filters?: InputMaybe<SpecsOptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerySubLinkArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySubLinksArgs = {
  filters?: InputMaybe<SubLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryTableRowArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryTableRowsArgs = {
  filters?: InputMaybe<TableRowFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryTermsAndConditionArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryWhistleblowingPolicyArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type SitemapSitemap = {
  __typename?: 'SitemapSitemap';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  delta?: Maybe<Scalars['Int']['output']>;
  link_count?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  sitemap_string: Scalars['String']['output'];
  type?: Maybe<Enum_Sitemapsitemap_Type>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SitemapSitemapCache = {
  __typename?: 'SitemapSitemapCache';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  sitemap_id: Scalars['Int']['output'];
  sitemap_json: Scalars['JSON']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SitemapSitemapCacheEntity = {
  __typename?: 'SitemapSitemapCacheEntity';
  attributes?: Maybe<SitemapSitemapCache>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SitemapSitemapCacheEntityResponse = {
  __typename?: 'SitemapSitemapCacheEntityResponse';
  data?: Maybe<SitemapSitemapCacheEntity>;
};

export type SitemapSitemapCacheEntityResponseCollection = {
  __typename?: 'SitemapSitemapCacheEntityResponseCollection';
  data: Array<SitemapSitemapCacheEntity>;
  meta: ResponseCollectionMeta;
};

export type SitemapSitemapCacheFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SitemapSitemapCacheFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SitemapSitemapCacheFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SitemapSitemapCacheFiltersInput>>>;
  sitemap_id?: InputMaybe<IntFilterInput>;
  sitemap_json?: InputMaybe<JsonFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SitemapSitemapCacheInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  sitemap_id?: InputMaybe<Scalars['Int']['input']>;
  sitemap_json?: InputMaybe<Scalars['JSON']['input']>;
};

export type SitemapSitemapEntity = {
  __typename?: 'SitemapSitemapEntity';
  attributes?: Maybe<SitemapSitemap>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SitemapSitemapEntityResponse = {
  __typename?: 'SitemapSitemapEntityResponse';
  data?: Maybe<SitemapSitemapEntity>;
};

export type SitemapSitemapEntityResponseCollection = {
  __typename?: 'SitemapSitemapEntityResponseCollection';
  data: Array<SitemapSitemapEntity>;
  meta: ResponseCollectionMeta;
};

export type SitemapSitemapFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SitemapSitemapFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  delta?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  link_count?: InputMaybe<IntFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SitemapSitemapFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SitemapSitemapFiltersInput>>>;
  sitemap_string?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SitemapSitemapInput = {
  delta?: InputMaybe<Scalars['Int']['input']>;
  link_count?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sitemap_string?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Enum_Sitemapsitemap_Type>;
};

export type Software = {
  __typename?: 'Software';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroVideoSection: ComponentUiHeroVideoSection;
  newsroom?: Maybe<ComponentSharedNewsroom>;
  productImageBlock?: Maybe<Array<Maybe<ComponentSharedProductImageBlock>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SoftwareProductImageBlockArgs = {
  filters?: InputMaybe<ComponentSharedProductImageBlockFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SoftwareEntity = {
  __typename?: 'SoftwareEntity';
  attributes?: Maybe<Software>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SoftwareEntityResponse = {
  __typename?: 'SoftwareEntityResponse';
  data?: Maybe<SoftwareEntity>;
};

export type SoftwareInput = {
  heroVideoSection?: InputMaybe<ComponentUiHeroVideoSectionInput>;
  newsroom?: InputMaybe<ComponentSharedNewsroomInput>;
  productImageBlock?: InputMaybe<
    Array<InputMaybe<ComponentSharedProductImageBlockInput>>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpecTable = {
  __typename?: 'SpecTable';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  feature: Array<Maybe<ComponentUiSpecTable>>;
  heading?: Maybe<ComponentUiHeading>;
  name?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  specs_option?: Maybe<SpecsOptionEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SpecTableFeatureArgs = {
  filters?: InputMaybe<ComponentUiSpecTableFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SpecTableEntity = {
  __typename?: 'SpecTableEntity';
  attributes?: Maybe<SpecTable>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SpecTableEntityResponse = {
  __typename?: 'SpecTableEntityResponse';
  data?: Maybe<SpecTableEntity>;
};

export type SpecTableEntityResponseCollection = {
  __typename?: 'SpecTableEntityResponseCollection';
  data: Array<SpecTableEntity>;
  meta: ResponseCollectionMeta;
};

export type SpecTableFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SpecTableFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  feature?: InputMaybe<ComponentUiSpecTableFiltersInput>;
  heading?: InputMaybe<ComponentUiHeadingFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SpecTableFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SpecTableFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  specs_option?: InputMaybe<SpecsOptionFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SpecTableInput = {
  feature?: InputMaybe<Array<InputMaybe<ComponentUiSpecTableInput>>>;
  heading?: InputMaybe<ComponentUiHeadingInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  specs_option?: InputMaybe<Scalars['ID']['input']>;
};

export type SpecTableRelationResponseCollection = {
  __typename?: 'SpecTableRelationResponseCollection';
  data: Array<SpecTableEntity>;
};

export type SpecsOption = {
  __typename?: 'SpecsOption';
  button?: Maybe<ComponentUiButton>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  gallery?: Maybe<Array<Maybe<ComponentUiImage>>>;
  graph?: Maybe<Array<Maybe<ComponentUiGraph>>>;
  largeSpec?: Maybe<Array<Maybe<ComponentUiSpec>>>;
  option?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  spec_tables?: Maybe<SpecTableRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SpecsOptionGalleryArgs = {
  filters?: InputMaybe<ComponentUiImageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SpecsOptionGraphArgs = {
  filters?: InputMaybe<ComponentUiGraphFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SpecsOptionLargeSpecArgs = {
  filters?: InputMaybe<ComponentUiSpecFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SpecsOptionSpec_TablesArgs = {
  filters?: InputMaybe<SpecTableFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SpecsOptionEntity = {
  __typename?: 'SpecsOptionEntity';
  attributes?: Maybe<SpecsOption>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SpecsOptionEntityResponse = {
  __typename?: 'SpecsOptionEntityResponse';
  data?: Maybe<SpecsOptionEntity>;
};

export type SpecsOptionEntityResponseCollection = {
  __typename?: 'SpecsOptionEntityResponseCollection';
  data: Array<SpecsOptionEntity>;
  meta: ResponseCollectionMeta;
};

export type SpecsOptionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SpecsOptionFiltersInput>>>;
  button?: InputMaybe<ComponentUiButtonFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  gallery?: InputMaybe<ComponentUiImageFiltersInput>;
  graph?: InputMaybe<ComponentUiGraphFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  largeSpec?: InputMaybe<ComponentUiSpecFiltersInput>;
  not?: InputMaybe<SpecsOptionFiltersInput>;
  option?: InputMaybe<StringFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SpecsOptionFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  spec_tables?: InputMaybe<SpecTableFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SpecsOptionInput = {
  button?: InputMaybe<ComponentUiButtonInput>;
  gallery?: InputMaybe<Array<InputMaybe<ComponentUiImageInput>>>;
  graph?: InputMaybe<Array<InputMaybe<ComponentUiGraphInput>>>;
  largeSpec?: InputMaybe<Array<InputMaybe<ComponentUiSpecInput>>>;
  option?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  spec_tables?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type SpecsOptionRelationResponseCollection = {
  __typename?: 'SpecsOptionRelationResponseCollection';
  data: Array<SpecsOptionEntity>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  containsi?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  eqi?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nei?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  notContainsi?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type SubLink = {
  __typename?: 'SubLink';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  link: Array<Maybe<ComponentNavigationLink>>;
  name?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SubLinkLinkArgs = {
  filters?: InputMaybe<ComponentNavigationLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SubLinkEntity = {
  __typename?: 'SubLinkEntity';
  attributes?: Maybe<SubLink>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SubLinkEntityResponse = {
  __typename?: 'SubLinkEntityResponse';
  data?: Maybe<SubLinkEntity>;
};

export type SubLinkEntityResponseCollection = {
  __typename?: 'SubLinkEntityResponseCollection';
  data: Array<SubLinkEntity>;
  meta: ResponseCollectionMeta;
};

export type SubLinkFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SubLinkFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  link?: InputMaybe<ComponentNavigationLinkFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SubLinkFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SubLinkFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SubLinkInput = {
  link?: InputMaybe<Array<InputMaybe<ComponentNavigationLinkInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TableRow = {
  __typename?: 'TableRow';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  rows?: Maybe<Array<Maybe<ComponentPoliciesRowItem>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TableRowRowsArgs = {
  filters?: InputMaybe<ComponentPoliciesRowItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TableRowEntity = {
  __typename?: 'TableRowEntity';
  attributes?: Maybe<TableRow>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type TableRowEntityResponse = {
  __typename?: 'TableRowEntityResponse';
  data?: Maybe<TableRowEntity>;
};

export type TableRowEntityResponseCollection = {
  __typename?: 'TableRowEntityResponseCollection';
  data: Array<TableRowEntity>;
  meta: ResponseCollectionMeta;
};

export type TableRowFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<TableRowFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<TableRowFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<TableRowFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  rows?: InputMaybe<ComponentPoliciesRowItemFiltersInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type TableRowInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  rows?: InputMaybe<Array<InputMaybe<ComponentPoliciesRowItemInput>>>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TableRowRelationResponseCollection = {
  __typename?: 'TableRowRelationResponseCollection';
  data: Array<TableRowEntity>;
};

export type TermsAndCondition = {
  __typename?: 'TermsAndCondition';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  policies?: Maybe<Array<Maybe<TermsAndConditionPoliciesDynamicZone>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TermsAndConditionEntity = {
  __typename?: 'TermsAndConditionEntity';
  attributes?: Maybe<TermsAndCondition>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type TermsAndConditionEntityResponse = {
  __typename?: 'TermsAndConditionEntityResponse';
  data?: Maybe<TermsAndConditionEntity>;
};

export type TermsAndConditionInput = {
  policies?: InputMaybe<
    Array<Scalars['TermsAndConditionPoliciesDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TermsAndConditionPoliciesDynamicZone =
  | ComponentPoliciesDevider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  ext?: Maybe<Scalars['String']['output']>;
  formats?: Maybe<Scalars['JSON']['output']>;
  hash: Scalars['String']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  mime: Scalars['String']['output'];
  name: Scalars['String']['output'];
  previewUrl?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  provider_metadata?: Maybe<Scalars['JSON']['output']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  blurhash?: InputMaybe<StringFilterInput>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  blurhash?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  ext?: InputMaybe<Scalars['String']['input']>;
  folder?: InputMaybe<Scalars['ID']['input']>;
  folderPath?: InputMaybe<Scalars['String']['input']>;
  formats?: InputMaybe<Scalars['JSON']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  previewUrl?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_metadata?: InputMaybe<Scalars['JSON']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String']['output'];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String']['output'];
  pathId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['ID']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  pathId?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String']['input'];
  password: Scalars['String']['input'];
  provider?: Scalars['String']['input'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']['output']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};

export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  confirmationToken?: InputMaybe<Scalars['String']['input']>;
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['ID']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type WhistleblowingPolicy = {
  __typename?: 'WhistleblowingPolicy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  policies?: Maybe<Array<Maybe<WhistleblowingPolicyPoliciesDynamicZone>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type WhistleblowingPolicyEntity = {
  __typename?: 'WhistleblowingPolicyEntity';
  attributes?: Maybe<WhistleblowingPolicy>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type WhistleblowingPolicyEntityResponse = {
  __typename?: 'WhistleblowingPolicyEntityResponse';
  data?: Maybe<WhistleblowingPolicyEntity>;
};

export type WhistleblowingPolicyInput = {
  policies?: InputMaybe<
    Array<Scalars['WhistleblowingPolicyPoliciesDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WhistleblowingPolicyPoliciesDynamicZone =
  | ComponentPoliciesDevider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export type ArticlePreviewFragment = {
  __typename?: 'ComponentArticleArticlePreview';
  title?: string | null;
  date?: string | null;
  alt?: string | null;
  link_path?: string | null;
  tag?: string | null;
  tagColor?: Enum_Componentarticlearticlepreview_Tagcolor | null;
  img_desktop?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
  img_mobile?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ArticleFragment = {
  __typename?: 'Article';
  slug?: string | null;
  preview?: {
    __typename?: 'ComponentArticleArticlePreview';
    title?: string | null;
    date?: string | null;
    alt?: string | null;
    link_path?: string | null;
    tag?: string | null;
    tagColor?: Enum_Componentarticlearticlepreview_Tagcolor | null;
    img_desktop?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  hero?: {
    __typename?: 'ComponentArticleArticleHero';
    title?: string | null;
    image?: {
      __typename?: 'ComponentUiImage';
      id: string;
      alt: string;
      img_desktop: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      };
      img_mobile?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  content?: Array<
    | {
        __typename?: 'ComponentArticleGallery';
        images?: Array<{
          __typename?: 'ComponentUiImage';
          id: string;
          alt: string;
          img_desktop: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          };
          img_mobile?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      }
    | {
        __typename?: 'ComponentArticleTextContent';
        title?: string | null;
        text?: string | null;
      }
    | { __typename?: 'Error' }
    | null
  > | null;
};

export type BatteryHeroQueryVariables = Exact<{ [key: string]: never }>;

export type BatteryHeroQuery = {
  __typename?: 'Query';
  battery?: {
    __typename?: 'BatteryEntityResponse';
    data?: {
      __typename?: 'BatteryEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Battery';
        heroVideoSection: {
          __typename?: 'ComponentUiHeroVideoSection';
          id: string;
          title: string;
          subtitle?: string | null;
          previewVideoLength?: string | null;
          showPreviewVideo?: boolean | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            autoplay?: boolean | null;
            controls?: boolean | null;
            fullscreen?: boolean | null;
            id: string;
            loop?: boolean | null;
            muted?: boolean | null;
            pauseVideo?: boolean | null;
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          previewVideo?: {
            __typename?: 'ComponentUiVideo';
            autoplay?: boolean | null;
            controls?: boolean | null;
            fullscreen?: boolean | null;
            id: string;
            loop?: boolean | null;
            muted?: boolean | null;
            pauseVideo?: boolean | null;
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
      } | null;
    } | null;
  } | null;
};

export type BatteryCardsSectionQueryVariables = Exact<{ [key: string]: never }>;

export type BatteryCardsSectionQuery = {
  __typename?: 'Query';
  battery?: {
    __typename?: 'BatteryEntityResponse';
    data?: {
      __typename?: 'BatteryEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Battery';
        cardsSection: {
          __typename?: 'ComponentSharedSectionCards';
          title: string;
          anchor?: string | null;
          subtitle?: string | null;
          iconCard?: Array<{
            __typename?: 'ComponentUiIconCard';
            id: string;
            title: string;
            description: string;
            icon: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
        };
      } | null;
    } | null;
  } | null;
};

export type BatteryProductImageBlockQueryVariables = Exact<{
  [key: string]: never;
}>;

export type BatteryProductImageBlockQuery = {
  __typename?: 'Query';
  battery?: {
    __typename?: 'BatteryEntityResponse';
    data?: {
      __typename?: 'BatteryEntity';
      attributes?: {
        __typename?: 'Battery';
        productImageBlock?: {
          __typename?: 'ComponentSharedProductImageBlock';
          title?: string | null;
          subtitle?: string | null;
          anchor?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
          } | null;
          largeSpec?: Array<{
            __typename?: 'ComponentUiSpec';
            label?: string | null;
            value?: string | null;
          } | null> | null;
          gallery?: Array<{
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          spec_tables?: {
            __typename?: 'SpecTableRelationResponseCollection';
            data: Array<{
              __typename?: 'SpecTableEntity';
              attributes?: {
                __typename?: 'SpecTable';
                heading?: {
                  __typename?: 'ComponentUiHeading';
                  title?: string | null;
                  subtitle?: string | null;
                } | null;
                feature: Array<{
                  __typename?: 'ComponentUiSpecTable';
                  label?: string | null;
                  spec: Array<{
                    __typename?: 'ComponentUiSpec';
                    id: string;
                    label?: string | null;
                    value?: string | null;
                  } | null>;
                } | null>;
              } | null;
            }>;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BatteryTwoImagesGridQueryVariables = Exact<{
  [key: string]: never;
}>;

export type BatteryTwoImagesGridQuery = {
  __typename?: 'Query';
  battery?: {
    __typename?: 'BatteryEntityResponse';
    data?: {
      __typename?: 'BatteryEntity';
      attributes?: {
        __typename?: 'Battery';
        twoImagesGrid?: {
          __typename?: 'ComponentSharedTwoImagesGrid';
          title?: string | null;
          anchor?: string | null;
          imagesGridItem?: Array<{
            __typename?: 'ComponentUiCertification';
            title: string;
            description: string;
            tag?: string | null;
            tagColor?: Enum_Componentuicertification_Tagcolor | null;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BatteryLargeImageQueryVariables = Exact<{ [key: string]: never }>;

export type BatteryLargeImageQuery = {
  __typename?: 'Query';
  battery?: {
    __typename?: 'BatteryEntityResponse';
    data?: {
      __typename?: 'BatteryEntity';
      attributes?: {
        __typename?: 'Battery';
        largeImageSection?: {
          __typename?: 'ComponentSharedLargeImageSection';
          anchor?: string | null;
          title?: string | null;
          description?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
          } | null;
          image?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BatteryNewsroomQueryVariables = Exact<{ [key: string]: never }>;

export type BatteryNewsroomQuery = {
  __typename?: 'Query';
  battery?: {
    __typename?: 'BatteryEntityResponse';
    data?: {
      __typename?: 'BatteryEntity';
      attributes?: {
        __typename?: 'Battery';
        newsroom?: {
          __typename?: 'ComponentSharedNewsroom';
          title?: string | null;
          anchor?: string | null;
          logo?: Array<{
            __typename?: 'ComponentUiIcon';
            id: string;
            alt: string;
            svg: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
          articles?: {
            __typename?: 'ArticleRelationResponseCollection';
            data: Array<{
              __typename?: 'ArticleEntity';
              attributes?: {
                __typename?: 'Article';
                slug?: string | null;
                preview?: {
                  __typename?: 'ComponentArticleArticlePreview';
                  title?: string | null;
                  date?: string | null;
                  alt?: string | null;
                  link_path?: string | null;
                  tag?: string | null;
                  tagColor?: Enum_Componentarticlearticlepreview_Tagcolor | null;
                  img_desktop?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                hero?: {
                  __typename?: 'ComponentArticleArticleHero';
                  title?: string | null;
                  image?: {
                    __typename?: 'ComponentUiImage';
                    id: string;
                    alt: string;
                    img_desktop: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    };
                    img_mobile?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                content?: Array<
                  | {
                      __typename?: 'ComponentArticleGallery';
                      images?: Array<{
                        __typename?: 'ComponentUiImage';
                        id: string;
                        alt: string;
                        img_desktop: {
                          __typename?: 'UploadFileEntityResponse';
                          data?: {
                            __typename?: 'UploadFileEntity';
                            id?: string | null;
                            attributes?: {
                              __typename?: 'UploadFile';
                              url: string;
                              alternativeText?: string | null;
                              blurhash?: string | null;
                            } | null;
                          } | null;
                        };
                        img_mobile?: {
                          __typename?: 'UploadFileEntityResponse';
                          data?: {
                            __typename?: 'UploadFileEntity';
                            id?: string | null;
                            attributes?: {
                              __typename?: 'UploadFile';
                              url: string;
                              alternativeText?: string | null;
                              blurhash?: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'ComponentArticleTextContent';
                      title?: string | null;
                      text?: string | null;
                    }
                  | { __typename?: 'Error' }
                  | null
                > | null;
              } | null;
            }>;
          } | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BatterySeoQueryVariables = Exact<{ [key: string]: never }>;

export type BatterySeoQuery = {
  __typename?: 'Query';
  battery?: {
    __typename?: 'BatteryEntityResponse';
    data?: {
      __typename?: 'BatteryEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Battery';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BmsHeroQueryVariables = Exact<{ [key: string]: never }>;

export type BmsHeroQuery = {
  __typename?: 'Query';
  bms?: {
    __typename?: 'BmsEntityResponse';
    data?: {
      __typename?: 'BmsEntity';
      attributes?: {
        __typename?: 'Bms';
        heroVideoSection?: {
          __typename?: 'ComponentUiHeroVideoSection';
          id: string;
          title: string;
          subtitle?: string | null;
          previewVideoLength?: string | null;
          showPreviewVideo?: boolean | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            autoplay?: boolean | null;
            controls?: boolean | null;
            fullscreen?: boolean | null;
            id: string;
            loop?: boolean | null;
            muted?: boolean | null;
            pauseVideo?: boolean | null;
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          previewVideo?: {
            __typename?: 'ComponentUiVideo';
            autoplay?: boolean | null;
            controls?: boolean | null;
            fullscreen?: boolean | null;
            id: string;
            loop?: boolean | null;
            muted?: boolean | null;
            pauseVideo?: boolean | null;
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BmsIconCardsQueryVariables = Exact<{ [key: string]: never }>;

export type BmsIconCardsQuery = {
  __typename?: 'Query';
  bms?: {
    __typename?: 'BmsEntityResponse';
    data?: {
      __typename?: 'BmsEntity';
      attributes?: {
        __typename?: 'Bms';
        cardsSection?: {
          __typename?: 'ComponentSharedSectionCards';
          title: string;
          anchor?: string | null;
          subtitle?: string | null;
          iconCard?: Array<{
            __typename?: 'ComponentUiIconCard';
            id: string;
            title: string;
            description: string;
            icon: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BmsProductImageBlockQueryVariables = Exact<{
  [key: string]: never;
}>;

export type BmsProductImageBlockQuery = {
  __typename?: 'Query';
  bms?: {
    __typename?: 'BmsEntityResponse';
    data?: {
      __typename?: 'BmsEntity';
      attributes?: {
        __typename?: 'Bms';
        productImageBlock?: {
          __typename?: 'ComponentSharedProductImageBlock';
          title?: string | null;
          subtitle?: string | null;
          anchor?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
          } | null;
          largeSpec?: Array<{
            __typename?: 'ComponentUiSpec';
            label?: string | null;
            value?: string | null;
          } | null> | null;
          gallery?: Array<{
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          spec_tables?: {
            __typename?: 'SpecTableRelationResponseCollection';
            data: Array<{
              __typename?: 'SpecTableEntity';
              attributes?: {
                __typename?: 'SpecTable';
                heading?: {
                  __typename?: 'ComponentUiHeading';
                  title?: string | null;
                  subtitle?: string | null;
                } | null;
                feature: Array<{
                  __typename?: 'ComponentUiSpecTable';
                  label?: string | null;
                  spec: Array<{
                    __typename?: 'ComponentUiSpec';
                    id: string;
                    label?: string | null;
                    value?: string | null;
                  } | null>;
                } | null>;
              } | null;
            }>;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BmsProductSpecsBlockQueryVariables = Exact<{
  [key: string]: never;
}>;

export type BmsProductSpecsBlockQuery = {
  __typename?: 'Query';
  bms?: {
    __typename?: 'BmsEntityResponse';
    data?: {
      __typename?: 'BmsEntity';
      attributes?: {
        __typename?: 'Bms';
        productSpecsBlock?: {
          __typename?: 'ComponentSharedProductSpecsBlocks';
          title?: string | null;
          subtitle?: string | null;
          anchor?: string | null;
          specs_options?: {
            __typename?: 'SpecsOptionRelationResponseCollection';
            data: Array<{
              __typename?: 'SpecsOptionEntity';
              attributes?: {
                __typename?: 'SpecsOption';
                option?: string | null;
                gallery?: Array<{
                  __typename?: 'ComponentUiImage';
                  id: string;
                  alt: string;
                  img_desktop: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
                largeSpec?: Array<{
                  __typename?: 'ComponentUiSpec';
                  label?: string | null;
                  value?: string | null;
                } | null> | null;
                spec_tables?: {
                  __typename?: 'SpecTableRelationResponseCollection';
                  data: Array<{
                    __typename?: 'SpecTableEntity';
                    attributes?: {
                      __typename?: 'SpecTable';
                      feature: Array<{
                        __typename?: 'ComponentUiSpecTable';
                        label?: string | null;
                        spec: Array<{
                          __typename?: 'ComponentUiSpec';
                          id: string;
                          label?: string | null;
                          value?: string | null;
                        } | null>;
                      } | null>;
                    } | null;
                  }>;
                } | null;
                graph?: Array<{
                  __typename?: 'ComponentUiGraph';
                  label?: string | null;
                  image: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                } | null> | null;
                button?: {
                  __typename?: 'ComponentUiButton';
                  label: string;
                  path?: string | null;
                } | null;
              } | null;
            }>;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BmsTwoImagesGridQueryVariables = Exact<{ [key: string]: never }>;

export type BmsTwoImagesGridQuery = {
  __typename?: 'Query';
  bms?: {
    __typename?: 'BmsEntityResponse';
    data?: {
      __typename?: 'BmsEntity';
      attributes?: {
        __typename?: 'Bms';
        twoImagesGrid?: Array<{
          __typename?: 'ComponentSharedTwoImagesGrid';
          title?: string | null;
          anchor?: string | null;
          imagesGridItem?: Array<{
            __typename?: 'ComponentUiCertification';
            title: string;
            description: string;
            tag?: string | null;
            tagColor?: Enum_Componentuicertification_Tagcolor | null;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type BmsNewsroomQueryVariables = Exact<{ [key: string]: never }>;

export type BmsNewsroomQuery = {
  __typename?: 'Query';
  bms?: {
    __typename?: 'BmsEntityResponse';
    data?: {
      __typename?: 'BmsEntity';
      attributes?: {
        __typename?: 'Bms';
        newsroom?: {
          __typename?: 'ComponentSharedNewsroom';
          title?: string | null;
          anchor?: string | null;
          logo?: Array<{
            __typename?: 'ComponentUiIcon';
            id: string;
            alt: string;
            svg: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
          articles?: {
            __typename?: 'ArticleRelationResponseCollection';
            data: Array<{
              __typename?: 'ArticleEntity';
              attributes?: {
                __typename?: 'Article';
                slug?: string | null;
                preview?: {
                  __typename?: 'ComponentArticleArticlePreview';
                  title?: string | null;
                  date?: string | null;
                  alt?: string | null;
                  link_path?: string | null;
                  tag?: string | null;
                  tagColor?: Enum_Componentarticlearticlepreview_Tagcolor | null;
                  img_desktop?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                hero?: {
                  __typename?: 'ComponentArticleArticleHero';
                  title?: string | null;
                  image?: {
                    __typename?: 'ComponentUiImage';
                    id: string;
                    alt: string;
                    img_desktop: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    };
                    img_mobile?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                content?: Array<
                  | {
                      __typename?: 'ComponentArticleGallery';
                      images?: Array<{
                        __typename?: 'ComponentUiImage';
                        id: string;
                        alt: string;
                        img_desktop: {
                          __typename?: 'UploadFileEntityResponse';
                          data?: {
                            __typename?: 'UploadFileEntity';
                            id?: string | null;
                            attributes?: {
                              __typename?: 'UploadFile';
                              url: string;
                              alternativeText?: string | null;
                              blurhash?: string | null;
                            } | null;
                          } | null;
                        };
                        img_mobile?: {
                          __typename?: 'UploadFileEntityResponse';
                          data?: {
                            __typename?: 'UploadFileEntity';
                            id?: string | null;
                            attributes?: {
                              __typename?: 'UploadFile';
                              url: string;
                              alternativeText?: string | null;
                              blurhash?: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'ComponentArticleTextContent';
                      title?: string | null;
                      text?: string | null;
                    }
                  | { __typename?: 'Error' }
                  | null
                > | null;
              } | null;
            }>;
          } | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type BmsSeoQueryVariables = Exact<{ [key: string]: never }>;

export type BmsSeoQuery = {
  __typename?: 'Query';
  bms?: {
    __typename?: 'BmsEntityResponse';
    data?: {
      __typename?: 'BmsEntity';
      attributes?: {
        __typename?: 'Bms';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type UploadFileFragment = {
  __typename?: 'UploadFileEntityResponse';
  data?: {
    __typename?: 'UploadFileEntity';
    id?: string | null;
    attributes?: {
      __typename?: 'UploadFile';
      url: string;
      alternativeText?: string | null;
      blurhash?: string | null;
    } | null;
  } | null;
};

export type UploadDocumentFragment = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<{
    __typename?: 'UploadFileEntity';
    id?: string | null;
    attributes?: {
      __typename?: 'UploadFile';
      url: string;
      size: number;
      blurhash?: string | null;
    } | null;
  }>;
};

export type HeadlineFragment = {
  __typename?: 'ComponentUiHeadline';
  id: string;
  title: string;
  description?: string | null;
  link_path?: string | null;
  link_text?: string | null;
};

export type ButtonFragment = {
  __typename?: 'ComponentUiButton';
  id: string;
  label: string;
  path?: string | null;
};

export type ImageFragment = {
  __typename?: 'ComponentUiImage';
  id: string;
  alt: string;
  img_desktop: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  };
  img_mobile?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type IconFragment = {
  __typename?: 'ComponentUiIcon';
  id: string;
  alt: string;
  svg: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  };
};

export type IconCardFragment = {
  __typename?: 'ComponentUiIconCard';
  id: string;
  title: string;
  description: string;
  icon: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  };
};

export type ImageCardLinkFragment = {
  __typename?: 'ComponentUiImageCardLink';
  title?: string | null;
  description?: string | null;
  link_label?: string | null;
  link_path?: string | null;
  alt?: string | null;
  tag?: string | null;
  img_desktop: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  };
  img_mobil?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OptionFragment = {
  __typename?: 'ComponentUiOptions';
  id: string;
  option?: string | null;
};

export type TableFragment = {
  __typename?: 'ComponentUiSpecTable';
  label?: string | null;
  spec: Array<{
    __typename?: 'ComponentUiSpec';
    id: string;
    label?: string | null;
    value?: string | null;
  } | null>;
};

export type SpecFragment = {
  __typename?: 'ComponentUiSpec';
  id: string;
  label?: string | null;
  value?: string | null;
};

export type CardFragment = {
  __typename?: 'ComponentUiCard';
  id: string;
  Title?: string | null;
  description?: string | null;
  button?: string | null;
  file?: {
    __typename?: 'UploadFileRelationResponseCollection';
    data: Array<{
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        size: number;
        blurhash?: string | null;
      } | null;
    }>;
  } | null;
};

export type GraphFragment = {
  __typename?: 'ComponentUiGraph';
  id: string;
  label?: string | null;
  image: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  };
};

export type HeroVideoSectionFragment = {
  __typename?: 'ComponentUiHeroVideoSection';
  id: string;
  title: string;
  subtitle?: string | null;
  previewVideoLength?: string | null;
  showPreviewVideo?: boolean | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
  } | null;
  heroVideo?: {
    __typename?: 'ComponentUiVideo';
    autoplay?: boolean | null;
    controls?: boolean | null;
    fullscreen?: boolean | null;
    id: string;
    loop?: boolean | null;
    muted?: boolean | null;
    pauseVideo?: boolean | null;
    poster_alt?: string | null;
    poster: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    mobilePoster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    video?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  previewVideo?: {
    __typename?: 'ComponentUiVideo';
    autoplay?: boolean | null;
    controls?: boolean | null;
    fullscreen?: boolean | null;
    id: string;
    loop?: boolean | null;
    muted?: boolean | null;
    pauseVideo?: boolean | null;
    poster_alt?: string | null;
    poster: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    mobilePoster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    video?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HeroImageSectionFragment = {
  __typename?: 'ComponentUiHeroImageSection';
  id: string;
  title: string;
  subtitle?: string | null;
  previewVideoLength?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
  } | null;
  heroImage?: {
    __typename?: 'ComponentUiImage';
    id: string;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  previewVideo?: {
    __typename?: 'ComponentUiVideo';
    autoplay?: boolean | null;
    controls?: boolean | null;
    fullscreen?: boolean | null;
    id: string;
    loop?: boolean | null;
    muted?: boolean | null;
    pauseVideo?: boolean | null;
    poster_alt?: string | null;
    poster: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    mobilePoster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    video?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type VideoFragment = {
  __typename?: 'ComponentUiVideo';
  autoplay?: boolean | null;
  controls?: boolean | null;
  fullscreen?: boolean | null;
  id: string;
  loop?: boolean | null;
  muted?: boolean | null;
  pauseVideo?: boolean | null;
  poster_alt?: string | null;
  poster: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  };
  mobilePoster?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
  video?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type GuidelinesFragment = {
  __typename?: 'ComponentUiGuidelines';
  anchor?: string | null;
  title?: string | null;
  card?: Array<{
    __typename?: 'ComponentUiCard';
    id: string;
    Title?: string | null;
    description?: string | null;
    button?: string | null;
    file?: {
      __typename?: 'UploadFileRelationResponseCollection';
      data: Array<{
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          size: number;
          blurhash?: string | null;
        } | null;
      }>;
    } | null;
  } | null> | null;
};

export type TeamFragment = {
  __typename?: 'ComponentUiTeam';
  anchor?: string | null;
  title?: string | null;
  team_mate?: Array<{
    __typename?: 'ComponentUiTeamMate';
    alt?: string | null;
    last_name?: string | null;
    name?: string | null;
    positions?: string | null;
    img_desktop?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    miniature?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type CompanyHeroQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyHeroQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Company';
        heroImageSection: {
          __typename?: 'ComponentUiHeroImageSection';
          id: string;
          title: string;
          subtitle?: string | null;
          previewVideoLength?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
          } | null;
          heroImage?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          previewVideo?: {
            __typename?: 'ComponentUiVideo';
            autoplay?: boolean | null;
            controls?: boolean | null;
            fullscreen?: boolean | null;
            id: string;
            loop?: boolean | null;
            muted?: boolean | null;
            pauseVideo?: boolean | null;
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
      } | null;
    } | null;
  } | null;
};

export type CompanySliderImageSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanySliderImageSectionQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      attributes?: {
        __typename?: 'Company';
        sliderImageSection?: {
          __typename?: 'ComponentSharedSliderImageSection';
          id: string;
          anchor?: string | null;
          title?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
          } | null;
          imageCardLink?: Array<{
            __typename?: 'ComponentUiImageCardLink';
            title?: string | null;
            description?: string | null;
            link_label?: string | null;
            link_path?: string | null;
            alt?: string | null;
            tag?: string | null;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobil?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CompanyValuesQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyValuesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      attributes?: {
        __typename?: 'Company';
        values?: {
          __typename?: 'ComponentSharedValues';
          title?: string | null;
          anchor?: string | null;
          cards?: Array<{
            __typename?: 'ComponentUiIconCard';
            id: string;
            title: string;
            description: string;
            icon: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CompanyCampusQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyCampusQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      attributes?: {
        __typename?: 'Company';
        CampusAnchor?: string | null;
        Campus?: Array<{
          __typename?: 'ComponentUiCampus';
          id: string;
          Text?: string | null;
          Title?: string | null;
          image?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          spec?: Array<{
            __typename?: 'ComponentUiSpec';
            id: string;
            label?: string | null;
            value?: string | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type FacilitiesFragment = {
  __typename?: 'ComponentCompanyFacilities';
  anchor?: string | null;
  title?: string | null;
  map?: Array<{
    __typename?: 'ComponentCompanyMap';
    alt?: string | null;
    name?: string | null;
    img_desktop?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type CompanyFacilitiesQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyFacilitiesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      attributes?: {
        __typename?: 'Company';
        facilities?: {
          __typename?: 'ComponentCompanyFacilities';
          anchor?: string | null;
          title?: string | null;
          map?: Array<{
            __typename?: 'ComponentCompanyMap';
            alt?: string | null;
            name?: string | null;
            img_desktop?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CompanyTeamQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyTeamQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      attributes?: {
        __typename?: 'Company';
        team?: {
          __typename?: 'ComponentUiTeam';
          anchor?: string | null;
          title?: string | null;
          team_mate?: Array<{
            __typename?: 'ComponentUiTeamMate';
            alt?: string | null;
            last_name?: string | null;
            name?: string | null;
            positions?: string | null;
            img_desktop?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            miniature?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CompanyGuidelinesQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyGuidelinesQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      attributes?: {
        __typename?: 'Company';
        guidelines?: {
          __typename?: 'ComponentUiGuidelines';
          anchor?: string | null;
          title?: string | null;
          card?: Array<{
            __typename?: 'ComponentUiCard';
            id: string;
            Title?: string | null;
            description?: string | null;
            button?: string | null;
            file?: {
              __typename?: 'UploadFileRelationResponseCollection';
              data: Array<{
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  size: number;
                  blurhash?: string | null;
                } | null;
              }>;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CompanyLargeImageQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyLargeImageQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      attributes?: {
        __typename?: 'Company';
        largeImageSection?: {
          __typename?: 'ComponentSharedLargeImageSection';
          anchor?: string | null;
          title?: string | null;
          description?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
          } | null;
          image?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CompanyNewsroomQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyNewsroomQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      attributes?: {
        __typename?: 'Company';
        newsroom?: {
          __typename?: 'ComponentSharedNewsroom';
          title?: string | null;
          anchor?: string | null;
          logo?: Array<{
            __typename?: 'ComponentUiIcon';
            id: string;
            alt: string;
            svg: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
          articles?: {
            __typename?: 'ArticleRelationResponseCollection';
            data: Array<{
              __typename?: 'ArticleEntity';
              attributes?: {
                __typename?: 'Article';
                slug?: string | null;
                preview?: {
                  __typename?: 'ComponentArticleArticlePreview';
                  title?: string | null;
                  date?: string | null;
                  alt?: string | null;
                  link_path?: string | null;
                  tag?: string | null;
                  tagColor?: Enum_Componentarticlearticlepreview_Tagcolor | null;
                  img_desktop?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                hero?: {
                  __typename?: 'ComponentArticleArticleHero';
                  title?: string | null;
                  image?: {
                    __typename?: 'ComponentUiImage';
                    id: string;
                    alt: string;
                    img_desktop: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    };
                    img_mobile?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                content?: Array<
                  | {
                      __typename?: 'ComponentArticleGallery';
                      images?: Array<{
                        __typename?: 'ComponentUiImage';
                        id: string;
                        alt: string;
                        img_desktop: {
                          __typename?: 'UploadFileEntityResponse';
                          data?: {
                            __typename?: 'UploadFileEntity';
                            id?: string | null;
                            attributes?: {
                              __typename?: 'UploadFile';
                              url: string;
                              alternativeText?: string | null;
                              blurhash?: string | null;
                            } | null;
                          } | null;
                        };
                        img_mobile?: {
                          __typename?: 'UploadFileEntityResponse';
                          data?: {
                            __typename?: 'UploadFileEntity';
                            id?: string | null;
                            attributes?: {
                              __typename?: 'UploadFile';
                              url: string;
                              alternativeText?: string | null;
                              blurhash?: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'ComponentArticleTextContent';
                      title?: string | null;
                      text?: string | null;
                    }
                  | { __typename?: 'Error' }
                  | null
                > | null;
              } | null;
            }>;
          } | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CompanySeoQueryVariables = Exact<{ [key: string]: never }>;

export type CompanySeoQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Company';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ElectronicsHeroQueryVariables = Exact<{ [key: string]: never }>;

export type ElectronicsHeroQuery = {
  __typename?: 'Query';
  electronic?: {
    __typename?: 'ElectronicEntityResponse';
    data?: {
      __typename?: 'ElectronicEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Electronic';
        heroVideoSection: {
          __typename?: 'ComponentUiHeroVideoSection';
          id: string;
          title: string;
          subtitle?: string | null;
          previewVideoLength?: string | null;
          showPreviewVideo?: boolean | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            autoplay?: boolean | null;
            controls?: boolean | null;
            fullscreen?: boolean | null;
            id: string;
            loop?: boolean | null;
            muted?: boolean | null;
            pauseVideo?: boolean | null;
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          previewVideo?: {
            __typename?: 'ComponentUiVideo';
            autoplay?: boolean | null;
            controls?: boolean | null;
            fullscreen?: boolean | null;
            id: string;
            loop?: boolean | null;
            muted?: boolean | null;
            pauseVideo?: boolean | null;
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
      } | null;
    } | null;
  } | null;
};

export type ElectronicsCardsSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ElectronicsCardsSectionQuery = {
  __typename?: 'Query';
  electronic?: {
    __typename?: 'ElectronicEntityResponse';
    data?: {
      __typename?: 'ElectronicEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Electronic';
        cardsSection?: {
          __typename?: 'ComponentSharedSectionCards';
          title: string;
          anchor?: string | null;
          subtitle?: string | null;
          iconCard?: Array<{
            __typename?: 'ComponentUiIconCard';
            id: string;
            title: string;
            description: string;
            icon: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ElectronicsProductImageBlockQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ElectronicsProductImageBlockQuery = {
  __typename?: 'Query';
  electronic?: {
    __typename?: 'ElectronicEntityResponse';
    data?: {
      __typename?: 'ElectronicEntity';
      attributes?: {
        __typename?: 'Electronic';
        productImageBlock?: Array<{
          __typename?: 'ComponentSharedProductImageBlock';
          title?: string | null;
          subtitle?: string | null;
          anchor?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
          } | null;
          largeSpec?: Array<{
            __typename?: 'ComponentUiSpec';
            label?: string | null;
            value?: string | null;
          } | null> | null;
          gallery?: Array<{
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          spec_tables?: {
            __typename?: 'SpecTableRelationResponseCollection';
            data: Array<{
              __typename?: 'SpecTableEntity';
              attributes?: {
                __typename?: 'SpecTable';
                heading?: {
                  __typename?: 'ComponentUiHeading';
                  title?: string | null;
                  subtitle?: string | null;
                } | null;
                feature: Array<{
                  __typename?: 'ComponentUiSpecTable';
                  label?: string | null;
                  spec: Array<{
                    __typename?: 'ComponentUiSpec';
                    id: string;
                    label?: string | null;
                    value?: string | null;
                  } | null>;
                } | null>;
              } | null;
            }>;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type ElectronicsTwoImagesGridQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ElectronicsTwoImagesGridQuery = {
  __typename?: 'Query';
  electronic?: {
    __typename?: 'ElectronicEntityResponse';
    data?: {
      __typename?: 'ElectronicEntity';
      attributes?: {
        __typename?: 'Electronic';
        twoImagesGrid?: {
          __typename?: 'ComponentSharedTwoImagesGrid';
          title?: string | null;
          anchor?: string | null;
          imagesGridItem?: Array<{
            __typename?: 'ComponentUiCertification';
            title: string;
            description: string;
            tag?: string | null;
            tagColor?: Enum_Componentuicertification_Tagcolor | null;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ElectronicsSliderImageSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ElectronicsSliderImageSectionQuery = {
  __typename?: 'Query';
  electronic?: {
    __typename?: 'ElectronicEntityResponse';
    data?: {
      __typename?: 'ElectronicEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Electronic';
        sliderImageSection?: {
          __typename?: 'ComponentSharedSliderImageSection';
          id: string;
          anchor?: string | null;
          title?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
          } | null;
          imageCardLink?: Array<{
            __typename?: 'ComponentUiImageCardLink';
            title?: string | null;
            description?: string | null;
            link_label?: string | null;
            link_path?: string | null;
            alt?: string | null;
            tag?: string | null;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobil?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ElectronicsNewsroomQueryVariables = Exact<{ [key: string]: never }>;

export type ElectronicsNewsroomQuery = {
  __typename?: 'Query';
  electronic?: {
    __typename?: 'ElectronicEntityResponse';
    data?: {
      __typename?: 'ElectronicEntity';
      attributes?: {
        __typename?: 'Electronic';
        newsroom?: {
          __typename?: 'ComponentSharedNewsroom';
          title?: string | null;
          anchor?: string | null;
          logo?: Array<{
            __typename?: 'ComponentUiIcon';
            id: string;
            alt: string;
            svg: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
          articles?: {
            __typename?: 'ArticleRelationResponseCollection';
            data: Array<{
              __typename?: 'ArticleEntity';
              attributes?: {
                __typename?: 'Article';
                slug?: string | null;
                preview?: {
                  __typename?: 'ComponentArticleArticlePreview';
                  title?: string | null;
                  date?: string | null;
                  alt?: string | null;
                  link_path?: string | null;
                  tag?: string | null;
                  tagColor?: Enum_Componentarticlearticlepreview_Tagcolor | null;
                  img_desktop?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                hero?: {
                  __typename?: 'ComponentArticleArticleHero';
                  title?: string | null;
                  image?: {
                    __typename?: 'ComponentUiImage';
                    id: string;
                    alt: string;
                    img_desktop: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    };
                    img_mobile?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                content?: Array<
                  | {
                      __typename?: 'ComponentArticleGallery';
                      images?: Array<{
                        __typename?: 'ComponentUiImage';
                        id: string;
                        alt: string;
                        img_desktop: {
                          __typename?: 'UploadFileEntityResponse';
                          data?: {
                            __typename?: 'UploadFileEntity';
                            id?: string | null;
                            attributes?: {
                              __typename?: 'UploadFile';
                              url: string;
                              alternativeText?: string | null;
                              blurhash?: string | null;
                            } | null;
                          } | null;
                        };
                        img_mobile?: {
                          __typename?: 'UploadFileEntityResponse';
                          data?: {
                            __typename?: 'UploadFileEntity';
                            id?: string | null;
                            attributes?: {
                              __typename?: 'UploadFile';
                              url: string;
                              alternativeText?: string | null;
                              blurhash?: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'ComponentArticleTextContent';
                      title?: string | null;
                      text?: string | null;
                    }
                  | { __typename?: 'Error' }
                  | null
                > | null;
              } | null;
            }>;
          } | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ElectronicsSeoQueryVariables = Exact<{ [key: string]: never }>;

export type ElectronicsSeoQuery = {
  __typename?: 'Query';
  electronic?: {
    __typename?: 'ElectronicEntityResponse';
    data?: {
      __typename?: 'ElectronicEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Electronic';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type SendContactFormMutationVariables = Exact<{
  data: ContactFormDataInput;
}>;

export type SendContactFormMutation = {
  __typename?: 'Mutation';
  sendContactForm?: {
    __typename?: 'ContactFomResponse';
    success?: boolean | null;
  } | null;
};

export type HomeHeroQueryVariables = Exact<{ [key: string]: never }>;

export type HomeHeroQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Home';
        heroVideoSection: {
          __typename?: 'ComponentUiHeroVideoSection';
          id: string;
          title: string;
          subtitle?: string | null;
          previewVideoLength?: string | null;
          showPreviewVideo?: boolean | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            autoplay?: boolean | null;
            controls?: boolean | null;
            fullscreen?: boolean | null;
            id: string;
            loop?: boolean | null;
            muted?: boolean | null;
            pauseVideo?: boolean | null;
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          previewVideo?: {
            __typename?: 'ComponentUiVideo';
            autoplay?: boolean | null;
            controls?: boolean | null;
            fullscreen?: boolean | null;
            id: string;
            loop?: boolean | null;
            muted?: boolean | null;
            pauseVideo?: boolean | null;
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
      } | null;
    } | null;
  } | null;
};

export type ImageCardsWithLinkFragment = {
  __typename?: 'ComponentHomepageImageCardsWIthLink';
  id: string;
  imageCardLink?: Array<{
    __typename?: 'ComponentUiImageCardLink';
    title?: string | null;
    description?: string | null;
    link_label?: string | null;
    link_path?: string | null;
    alt?: string | null;
    tag?: string | null;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobil?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type HomeCardsWithLinkQueryVariables = Exact<{ [key: string]: never }>;

export type HomeCardsWithLinkQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Home';
        imageCardsWithLink?: {
          __typename?: 'ComponentHomepageImageCardsWIthLink';
          id: string;
          imageCardLink?: Array<{
            __typename?: 'ComponentUiImageCardLink';
            title?: string | null;
            description?: string | null;
            link_label?: string | null;
            link_path?: string | null;
            alt?: string | null;
            tag?: string | null;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobil?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HomeLargeImageQueryVariables = Exact<{ [key: string]: never }>;

export type HomeLargeImageQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      attributes?: {
        __typename?: 'Home';
        largeImageSection?: {
          __typename?: 'ComponentSharedLargeImageSection';
          anchor?: string | null;
          title?: string | null;
          description?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
          } | null;
          image?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HomeNewsroomQueryVariables = Exact<{ [key: string]: never }>;

export type HomeNewsroomQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      attributes?: {
        __typename?: 'Home';
        newsroom?: {
          __typename?: 'ComponentSharedNewsroom';
          title?: string | null;
          anchor?: string | null;
          logo?: Array<{
            __typename?: 'ComponentUiIcon';
            id: string;
            alt: string;
            svg: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
          articles?: {
            __typename?: 'ArticleRelationResponseCollection';
            data: Array<{
              __typename?: 'ArticleEntity';
              attributes?: {
                __typename?: 'Article';
                slug?: string | null;
                preview?: {
                  __typename?: 'ComponentArticleArticlePreview';
                  title?: string | null;
                  date?: string | null;
                  alt?: string | null;
                  link_path?: string | null;
                  tag?: string | null;
                  tagColor?: Enum_Componentarticlearticlepreview_Tagcolor | null;
                  img_desktop?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                hero?: {
                  __typename?: 'ComponentArticleArticleHero';
                  title?: string | null;
                  image?: {
                    __typename?: 'ComponentUiImage';
                    id: string;
                    alt: string;
                    img_desktop: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    };
                    img_mobile?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                content?: Array<
                  | {
                      __typename?: 'ComponentArticleGallery';
                      images?: Array<{
                        __typename?: 'ComponentUiImage';
                        id: string;
                        alt: string;
                        img_desktop: {
                          __typename?: 'UploadFileEntityResponse';
                          data?: {
                            __typename?: 'UploadFileEntity';
                            id?: string | null;
                            attributes?: {
                              __typename?: 'UploadFile';
                              url: string;
                              alternativeText?: string | null;
                              blurhash?: string | null;
                            } | null;
                          } | null;
                        };
                        img_mobile?: {
                          __typename?: 'UploadFileEntityResponse';
                          data?: {
                            __typename?: 'UploadFileEntity';
                            id?: string | null;
                            attributes?: {
                              __typename?: 'UploadFile';
                              url: string;
                              alternativeText?: string | null;
                              blurhash?: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'ComponentArticleTextContent';
                      title?: string | null;
                      text?: string | null;
                    }
                  | { __typename?: 'Error' }
                  | null
                > | null;
              } | null;
            }>;
          } | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HomeSeoQueryVariables = Exact<{ [key: string]: never }>;

export type HomeSeoQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Home';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type FooterQueryQueryVariables = Exact<{ [key: string]: never }>;

export type FooterQueryQuery = {
  __typename?: 'Query';
  footer?: {
    __typename?: 'FooterEntityResponse';
    data?: {
      __typename?: 'FooterEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Footer';
        column?: Array<{
          __typename?: 'ComponentFooterColumn';
          label?: string | null;
          link?: Array<{
            __typename?: 'ComponentFooterLink';
            label?: string | null;
            path?: string | null;
          } | null> | null;
        } | null> | null;
        policies?: {
          __typename?: 'ComponentFooterPolicies';
          label?: string | null;
          link?: Array<{
            __typename?: 'ComponentFooterLink';
            label?: string | null;
            path?: string | null;
          } | null> | null;
        } | null;
        socials?: {
          __typename?: 'ComponentFooterSocials';
          label?: string | null;
          instagram?: string | null;
          linkedin?: string | null;
          tiktok?: string | null;
          youtube?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type NavigationQueryVariables = Exact<{ [key: string]: never }>;

export type NavigationQuery = {
  __typename?: 'Query';
  navigation?: {
    __typename?: 'NavigationEntityResponse';
    data?: {
      __typename?: 'NavigationEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Navigation';
        contact: {
          __typename?: 'ComponentNavigationContact';
          label: string;
          path: string;
          instagram?: string | null;
          linkedin?: string | null;
          tiktok?: string | null;
          youtube?: string | null;
        };
        navigation: {
          __typename?: 'ComponentNavigationNavigation';
          openMenuLinks: Array<{
            __typename?: 'ComponentNavigationMenuLinksClosed';
            label: string;
            path: string;
            sub_link?: {
              __typename?: 'SubLinkEntityResponse';
              data?: {
                __typename?: 'SubLinkEntity';
                attributes?: {
                  __typename?: 'SubLink';
                  link: Array<{
                    __typename?: 'ComponentNavigationLink';
                    path: string;
                    label: string;
                  } | null>;
                } | null;
              } | null;
            } | null;
          } | null>;
          closedMenuLinks?: Array<{
            __typename?: 'ComponentNavigationClosedMenuLinks';
            label: string;
            path: string;
          } | null> | null;
        };
      } | null;
    } | null;
  } | null;
};

export type FormQueryQueryVariables = Exact<{ [key: string]: never }>;

export type FormQueryQuery = {
  __typename?: 'Query';
  getInTouch?: {
    __typename?: 'GetInTouchEntityResponse';
    data?: {
      __typename?: 'GetInTouchEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'GetInTouch';
        anchor?: string | null;
        title?: string | null;
        first_line?: string | null;
        second_line?: string | null;
        options?: Array<{
          __typename?: 'ComponentUiSpec';
          label?: string | null;
          value?: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

type PolicyFields_ComponentPoliciesDevider_Fragment = {
  __typename?: 'ComponentPoliciesDevider';
  Devider?: boolean | null;
};

type PolicyFields_ComponentPoliciesDownloadLink_Fragment = {
  __typename?: 'ComponentPoliciesDownloadLink';
  label?: string | null;
  link?: string | null;
};

type PolicyFields_ComponentPoliciesMainHeading_Fragment = {
  __typename?: 'ComponentPoliciesMainHeading';
  text?: any | null;
};

type PolicyFields_ComponentPoliciesTableTextContent_Fragment = {
  __typename?: 'ComponentPoliciesTableTextContent';
  richText?: any | null;
  tableHead?: Array<{
    __typename?: 'ComponentPoliciesTableHeading';
    headingItem?: string | null;
  } | null> | null;
  tableRow?: {
    __typename?: 'ComponentPoliciesTableRow';
    table_rows?: {
      __typename?: 'TableRowRelationResponseCollection';
      data: Array<{
        __typename?: 'TableRowEntity';
        attributes?: {
          __typename?: 'TableRow';
          rows?: Array<{
            __typename?: 'ComponentPoliciesRowItem';
            item?: string | null;
          } | null> | null;
        } | null;
      }>;
    } | null;
  } | null;
};

type PolicyFields_ComponentPoliciesTitleTextContent_Fragment = {
  __typename?: 'ComponentPoliciesTitleTextContent';
  text?: any | null;
};

type PolicyFields_Error_Fragment = { __typename?: 'Error' };

export type PolicyFieldsFragment =
  | PolicyFields_ComponentPoliciesDevider_Fragment
  | PolicyFields_ComponentPoliciesDownloadLink_Fragment
  | PolicyFields_ComponentPoliciesMainHeading_Fragment
  | PolicyFields_ComponentPoliciesTableTextContent_Fragment
  | PolicyFields_ComponentPoliciesTitleTextContent_Fragment
  | PolicyFields_Error_Fragment;

export type PrivacyPolicyQueryVariables = Exact<{ [key: string]: never }>;

export type PrivacyPolicyQuery = {
  __typename?: 'Query';
  privacyPolicy?: {
    __typename?: 'PrivacyPolicyEntityResponse';
    data?: {
      __typename?: 'PrivacyPolicyEntity';
      attributes?: {
        __typename?: 'PrivacyPolicy';
        policies?: Array<
          | {
              __typename?: 'ComponentPoliciesDevider';
              Devider?: boolean | null;
            }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHeading';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTableRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
      } | null;
    } | null;
  } | null;
};

export type EnvironmentalPolicyQueryVariables = Exact<{ [key: string]: never }>;

export type EnvironmentalPolicyQuery = {
  __typename?: 'Query';
  environmentalPolicy?: {
    __typename?: 'EnvironmentalPolicyEntityResponse';
    data?: {
      __typename?: 'EnvironmentalPolicyEntity';
      attributes?: {
        __typename?: 'EnvironmentalPolicy';
        policies?: Array<
          | {
              __typename?: 'ComponentPoliciesDevider';
              Devider?: boolean | null;
            }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHeading';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTableRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
      } | null;
    } | null;
  } | null;
};

export type CookiePolicyQueryVariables = Exact<{ [key: string]: never }>;

export type CookiePolicyQuery = {
  __typename?: 'Query';
  cookiePolicy?: {
    __typename?: 'CookiePolicyEntityResponse';
    data?: {
      __typename?: 'CookiePolicyEntity';
      attributes?: {
        __typename?: 'CookiePolicy';
        cookiePolicy?: Array<
          | {
              __typename?: 'ComponentPoliciesDevider';
              Devider?: boolean | null;
            }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHeading';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTableRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
      } | null;
    } | null;
  } | null;
};

export type TermsAndConditionsQueryVariables = Exact<{ [key: string]: never }>;

export type TermsAndConditionsQuery = {
  __typename?: 'Query';
  termsAndCondition?: {
    __typename?: 'TermsAndConditionEntityResponse';
    data?: {
      __typename?: 'TermsAndConditionEntity';
      attributes?: {
        __typename?: 'TermsAndCondition';
        policies?: Array<
          | {
              __typename?: 'ComponentPoliciesDevider';
              Devider?: boolean | null;
            }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHeading';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTableRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
      } | null;
    } | null;
  } | null;
};

export type IriPageQueryVariables = Exact<{ [key: string]: never }>;

export type IriPageQuery = {
  __typename?: 'Query';
  iriPage?: {
    __typename?: 'IriPageEntityResponse';
    data?: {
      __typename?: 'IriPageEntity';
      attributes?: {
        __typename?: 'IriPage';
        contents?: Array<
          | {
              __typename?: 'ComponentPoliciesDevider';
              Devider?: boolean | null;
            }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHeading';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTableRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
      } | null;
    } | null;
  } | null;
};

export type WhistleblowingPolicyQueryVariables = Exact<{
  [key: string]: never;
}>;

export type WhistleblowingPolicyQuery = {
  __typename?: 'Query';
  whistleblowingPolicy?: {
    __typename?: 'WhistleblowingPolicyEntityResponse';
    data?: {
      __typename?: 'WhistleblowingPolicyEntity';
      attributes?: {
        __typename?: 'WhistleblowingPolicy';
        policies?: Array<
          | {
              __typename?: 'ComponentPoliciesDevider';
              Devider?: boolean | null;
            }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHeading';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTableRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
      } | null;
    } | null;
  } | null;
};

export type CookiesSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type CookiesSettingsQuery = {
  __typename?: 'Query';
  cookieSetting?: {
    __typename?: 'CookieSettingEntityResponse';
    data?: {
      __typename?: 'CookieSettingEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'CookieSetting';
        actionButtonLabel?: string | null;
        necessaryCookiesHeading?: string | null;
        optionalCookiesHeading?: string | null;
        title?: string | null;
        necessaryCookiesTable?: {
          __typename?: 'ComponentPoliciesTableTextContent';
          richText?: any | null;
          tableHead?: Array<{
            __typename?: 'ComponentPoliciesTableHeading';
            headingItem?: string | null;
          } | null> | null;
          tableRow?: {
            __typename?: 'ComponentPoliciesTableRow';
            table_rows?: {
              __typename?: 'TableRowRelationResponseCollection';
              data: Array<{
                __typename?: 'TableRowEntity';
                attributes?: {
                  __typename?: 'TableRow';
                  rows?: Array<{
                    __typename?: 'ComponentPoliciesRowItem';
                    item?: string | null;
                  } | null> | null;
                } | null;
              }>;
            } | null;
          } | null;
        } | null;
        optionalCookiesTale?: {
          __typename?: 'ComponentPoliciesTableTextContent';
          richText?: any | null;
          tableHead?: Array<{
            __typename?: 'ComponentPoliciesTableHeading';
            headingItem?: string | null;
          } | null> | null;
          tableRow?: {
            __typename?: 'ComponentPoliciesTableRow';
            table_rows?: {
              __typename?: 'TableRowRelationResponseCollection';
              data: Array<{
                __typename?: 'TableRowEntity';
                attributes?: {
                  __typename?: 'TableRow';
                  rows?: Array<{
                    __typename?: 'ComponentPoliciesRowItem';
                    item?: string | null;
                  } | null> | null;
                } | null;
              }>;
            } | null;
          } | null;
        } | null;
        redirectButton?: {
          __typename?: 'ComponentUiButton';
          label: string;
          path?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CookiesBarQueryVariables = Exact<{ [key: string]: never }>;

export type CookiesBarQuery = {
  __typename?: 'Query';
  cookiesBanner?: {
    __typename?: 'CookiesBannerEntityResponse';
    data?: {
      __typename?: 'CookiesBannerEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'CookiesBanner';
        header?: string | null;
        text?: string | null;
        actionButtonLabel?: string | null;
        policyButton?: {
          __typename?: 'ComponentUiButton';
          label: string;
          path?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type PowertrainHeroQueryVariables = Exact<{ [key: string]: never }>;

export type PowertrainHeroQuery = {
  __typename?: 'Query';
  powertrain?: {
    __typename?: 'PowertrainEntityResponse';
    data?: {
      __typename?: 'PowertrainEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Powertrain';
        heroVideoSection: {
          __typename?: 'ComponentUiHeroVideoSection';
          id: string;
          title: string;
          subtitle?: string | null;
          previewVideoLength?: string | null;
          showPreviewVideo?: boolean | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            autoplay?: boolean | null;
            controls?: boolean | null;
            fullscreen?: boolean | null;
            id: string;
            loop?: boolean | null;
            muted?: boolean | null;
            pauseVideo?: boolean | null;
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          previewVideo?: {
            __typename?: 'ComponentUiVideo';
            autoplay?: boolean | null;
            controls?: boolean | null;
            fullscreen?: boolean | null;
            id: string;
            loop?: boolean | null;
            muted?: boolean | null;
            pauseVideo?: boolean | null;
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
      } | null;
    } | null;
  } | null;
};

export type PowertrainCardsSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PowertrainCardsSectionQuery = {
  __typename?: 'Query';
  powertrain?: {
    __typename?: 'PowertrainEntityResponse';
    data?: {
      __typename?: 'PowertrainEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Powertrain';
        cardsSection?: {
          __typename?: 'ComponentSharedSectionCards';
          id: string;
          title: string;
          subtitle?: string | null;
          iconCard?: Array<{
            __typename?: 'ComponentUiIconCard';
            id: string;
            title: string;
            description: string;
            icon: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type PowertrainSpecsFragment = {
  __typename?: 'ComponentPowertrainTechnologies';
  id: string;
  anchor?: string | null;
  title?: string | null;
  description?: string | null;
  image?: {
    __typename?: 'ComponentUiImage';
    id: string;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  paragraphs?: Array<{
    __typename?: 'ComponentUiParagraph';
    paragraphs?: string | null;
  } | null> | null;
  spec_tables?: {
    __typename?: 'SpecTableRelationResponseCollection';
    data: Array<{
      __typename?: 'SpecTableEntity';
      attributes?: {
        __typename?: 'SpecTable';
        feature: Array<{
          __typename?: 'ComponentUiSpecTable';
          label?: string | null;
          spec: Array<{
            __typename?: 'ComponentUiSpec';
            id: string;
            label?: string | null;
            value?: string | null;
          } | null>;
        } | null>;
      } | null;
    }>;
  } | null;
};

export type PowertrainSpecsQueryVariables = Exact<{ [key: string]: never }>;

export type PowertrainSpecsQuery = {
  __typename?: 'Query';
  powertrain?: {
    __typename?: 'PowertrainEntityResponse';
    data?: {
      __typename?: 'PowertrainEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Powertrain';
        technologies?: {
          __typename?: 'ComponentPowertrainTechnologies';
          id: string;
          anchor?: string | null;
          title?: string | null;
          description?: string | null;
          image?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          paragraphs?: Array<{
            __typename?: 'ComponentUiParagraph';
            paragraphs?: string | null;
          } | null> | null;
          spec_tables?: {
            __typename?: 'SpecTableRelationResponseCollection';
            data: Array<{
              __typename?: 'SpecTableEntity';
              attributes?: {
                __typename?: 'SpecTable';
                feature: Array<{
                  __typename?: 'ComponentUiSpecTable';
                  label?: string | null;
                  spec: Array<{
                    __typename?: 'ComponentUiSpec';
                    id: string;
                    label?: string | null;
                    value?: string | null;
                  } | null>;
                } | null>;
              } | null;
            }>;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type PowertrainProductSpecsBlocksQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PowertrainProductSpecsBlocksQuery = {
  __typename?: 'Query';
  powertrain?: {
    __typename?: 'PowertrainEntityResponse';
    data?: {
      __typename?: 'PowertrainEntity';
      attributes?: {
        __typename?: 'Powertrain';
        productSpecsBlocks?: Array<{
          __typename?: 'ComponentSharedProductSpecsBlocks';
          title?: string | null;
          subtitle?: string | null;
          anchor?: string | null;
          specs_options?: {
            __typename?: 'SpecsOptionRelationResponseCollection';
            data: Array<{
              __typename?: 'SpecsOptionEntity';
              attributes?: {
                __typename?: 'SpecsOption';
                option?: string | null;
                gallery?: Array<{
                  __typename?: 'ComponentUiImage';
                  id: string;
                  alt: string;
                  img_desktop: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
                largeSpec?: Array<{
                  __typename?: 'ComponentUiSpec';
                  label?: string | null;
                  value?: string | null;
                } | null> | null;
                spec_tables?: {
                  __typename?: 'SpecTableRelationResponseCollection';
                  data: Array<{
                    __typename?: 'SpecTableEntity';
                    attributes?: {
                      __typename?: 'SpecTable';
                      feature: Array<{
                        __typename?: 'ComponentUiSpecTable';
                        label?: string | null;
                        spec: Array<{
                          __typename?: 'ComponentUiSpec';
                          id: string;
                          label?: string | null;
                          value?: string | null;
                        } | null>;
                      } | null>;
                    } | null;
                  }>;
                } | null;
                graph?: Array<{
                  __typename?: 'ComponentUiGraph';
                  label?: string | null;
                  image: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                } | null> | null;
                button?: {
                  __typename?: 'ComponentUiButton';
                  label: string;
                  path?: string | null;
                } | null;
              } | null;
            }>;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type PowertrainNewsroomQueryVariables = Exact<{ [key: string]: never }>;

export type PowertrainNewsroomQuery = {
  __typename?: 'Query';
  powertrain?: {
    __typename?: 'PowertrainEntityResponse';
    data?: {
      __typename?: 'PowertrainEntity';
      attributes?: {
        __typename?: 'Powertrain';
        newsroom?: {
          __typename?: 'ComponentSharedNewsroom';
          title?: string | null;
          anchor?: string | null;
          logo?: Array<{
            __typename?: 'ComponentUiIcon';
            id: string;
            alt: string;
            svg: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
          articles?: {
            __typename?: 'ArticleRelationResponseCollection';
            data: Array<{
              __typename?: 'ArticleEntity';
              attributes?: {
                __typename?: 'Article';
                slug?: string | null;
                preview?: {
                  __typename?: 'ComponentArticleArticlePreview';
                  title?: string | null;
                  date?: string | null;
                  alt?: string | null;
                  link_path?: string | null;
                  tag?: string | null;
                  tagColor?: Enum_Componentarticlearticlepreview_Tagcolor | null;
                  img_desktop?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                hero?: {
                  __typename?: 'ComponentArticleArticleHero';
                  title?: string | null;
                  image?: {
                    __typename?: 'ComponentUiImage';
                    id: string;
                    alt: string;
                    img_desktop: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    };
                    img_mobile?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                content?: Array<
                  | {
                      __typename?: 'ComponentArticleGallery';
                      images?: Array<{
                        __typename?: 'ComponentUiImage';
                        id: string;
                        alt: string;
                        img_desktop: {
                          __typename?: 'UploadFileEntityResponse';
                          data?: {
                            __typename?: 'UploadFileEntity';
                            id?: string | null;
                            attributes?: {
                              __typename?: 'UploadFile';
                              url: string;
                              alternativeText?: string | null;
                              blurhash?: string | null;
                            } | null;
                          } | null;
                        };
                        img_mobile?: {
                          __typename?: 'UploadFileEntityResponse';
                          data?: {
                            __typename?: 'UploadFileEntity';
                            id?: string | null;
                            attributes?: {
                              __typename?: 'UploadFile';
                              url: string;
                              alternativeText?: string | null;
                              blurhash?: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'ComponentArticleTextContent';
                      title?: string | null;
                      text?: string | null;
                    }
                  | { __typename?: 'Error' }
                  | null
                > | null;
              } | null;
            }>;
          } | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type PowertrainSeoQueryVariables = Exact<{ [key: string]: never }>;

export type PowertrainSeoQuery = {
  __typename?: 'Query';
  powertrain?: {
    __typename?: 'PowertrainEntityResponse';
    data?: {
      __typename?: 'PowertrainEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Powertrain';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ValuesFragment = {
  __typename?: 'ComponentSharedValues';
  title?: string | null;
  anchor?: string | null;
  cards?: Array<{
    __typename?: 'ComponentUiIconCard';
    id: string;
    title: string;
    description: string;
    icon: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
  } | null> | null;
};

export type TwoImagesGridFragment = {
  __typename?: 'ComponentSharedTwoImagesGrid';
  title?: string | null;
  anchor?: string | null;
  imagesGridItem?: Array<{
    __typename?: 'ComponentUiCertification';
    title: string;
    description: string;
    tag?: string | null;
    tagColor?: Enum_Componentuicertification_Tagcolor | null;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type LargeImageSectionFragment = {
  __typename?: 'ComponentSharedLargeImageSection';
  anchor?: string | null;
  title?: string | null;
  description?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    label: string;
    path?: string | null;
  } | null;
  image?: {
    __typename?: 'ComponentUiImage';
    id: string;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CardsSectionFragment = {
  __typename?: 'ComponentSharedSectionCards';
  title: string;
  anchor?: string | null;
  subtitle?: string | null;
  iconCard?: Array<{
    __typename?: 'ComponentUiIconCard';
    id: string;
    title: string;
    description: string;
    icon: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
  } | null> | null;
};

export type ProductSpecsBlockFragment = {
  __typename?: 'ComponentSharedProductSpecsBlocks';
  title?: string | null;
  subtitle?: string | null;
  anchor?: string | null;
  specs_options?: {
    __typename?: 'SpecsOptionRelationResponseCollection';
    data: Array<{
      __typename?: 'SpecsOptionEntity';
      attributes?: {
        __typename?: 'SpecsOption';
        option?: string | null;
        gallery?: Array<{
          __typename?: 'ComponentUiImage';
          id: string;
          alt: string;
          img_desktop: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          };
          img_mobile?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        largeSpec?: Array<{
          __typename?: 'ComponentUiSpec';
          label?: string | null;
          value?: string | null;
        } | null> | null;
        spec_tables?: {
          __typename?: 'SpecTableRelationResponseCollection';
          data: Array<{
            __typename?: 'SpecTableEntity';
            attributes?: {
              __typename?: 'SpecTable';
              feature: Array<{
                __typename?: 'ComponentUiSpecTable';
                label?: string | null;
                spec: Array<{
                  __typename?: 'ComponentUiSpec';
                  id: string;
                  label?: string | null;
                  value?: string | null;
                } | null>;
              } | null>;
            } | null;
          }>;
        } | null;
        graph?: Array<{
          __typename?: 'ComponentUiGraph';
          label?: string | null;
          image: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          };
        } | null> | null;
        button?: {
          __typename?: 'ComponentUiButton';
          label: string;
          path?: string | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type SliderImageSectionFragment = {
  __typename?: 'ComponentSharedSliderImageSection';
  id: string;
  anchor?: string | null;
  title?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    label: string;
    path?: string | null;
  } | null;
  imageCardLink?: Array<{
    __typename?: 'ComponentUiImageCardLink';
    title?: string | null;
    description?: string | null;
    link_label?: string | null;
    link_path?: string | null;
    alt?: string | null;
    tag?: string | null;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobil?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type ProductImageBlockFragment = {
  __typename?: 'ComponentSharedProductImageBlock';
  title?: string | null;
  subtitle?: string | null;
  anchor?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    label: string;
    path?: string | null;
  } | null;
  largeSpec?: Array<{
    __typename?: 'ComponentUiSpec';
    label?: string | null;
    value?: string | null;
  } | null> | null;
  gallery?: Array<{
    __typename?: 'ComponentUiImage';
    id: string;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
  spec_tables?: {
    __typename?: 'SpecTableRelationResponseCollection';
    data: Array<{
      __typename?: 'SpecTableEntity';
      attributes?: {
        __typename?: 'SpecTable';
        heading?: {
          __typename?: 'ComponentUiHeading';
          title?: string | null;
          subtitle?: string | null;
        } | null;
        feature: Array<{
          __typename?: 'ComponentUiSpecTable';
          label?: string | null;
          spec: Array<{
            __typename?: 'ComponentUiSpec';
            id: string;
            label?: string | null;
            value?: string | null;
          } | null>;
        } | null>;
      } | null;
    }>;
  } | null;
};

export type MetaSocialFragment = {
  __typename?: 'ComponentSharedMetaSocial';
  id: string;
  title: string;
  description: string;
  socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
  image?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type NewsroomFragment = {
  __typename?: 'ComponentSharedNewsroom';
  title?: string | null;
  anchor?: string | null;
  logo?: Array<{
    __typename?: 'ComponentUiIcon';
    id: string;
    alt: string;
    svg: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
  } | null> | null;
  articles?: {
    __typename?: 'ArticleRelationResponseCollection';
    data: Array<{
      __typename?: 'ArticleEntity';
      attributes?: {
        __typename?: 'Article';
        slug?: string | null;
        preview?: {
          __typename?: 'ComponentArticleArticlePreview';
          title?: string | null;
          date?: string | null;
          alt?: string | null;
          link_path?: string | null;
          tag?: string | null;
          tagColor?: Enum_Componentarticlearticlepreview_Tagcolor | null;
          img_desktop?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          img_mobile?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        hero?: {
          __typename?: 'ComponentArticleArticleHero';
          title?: string | null;
          image?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        content?: Array<
          | {
              __typename?: 'ComponentArticleGallery';
              images?: Array<{
                __typename?: 'ComponentUiImage';
                id: string;
                alt: string;
                img_desktop: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    id?: string | null;
                    attributes?: {
                      __typename?: 'UploadFile';
                      url: string;
                      alternativeText?: string | null;
                      blurhash?: string | null;
                    } | null;
                  } | null;
                };
                img_mobile?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    id?: string | null;
                    attributes?: {
                      __typename?: 'UploadFile';
                      url: string;
                      alternativeText?: string | null;
                      blurhash?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
            }
          | {
              __typename?: 'ComponentArticleTextContent';
              title?: string | null;
              text?: string | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
      } | null;
    }>;
  } | null;
  button?: {
    __typename?: 'ComponentUiButton';
    label: string;
    path?: string | null;
  } | null;
};

export type SeoFragment = {
  __typename?: 'ComponentSharedSeo';
  id: string;
  canonicalURL?: string | null;
  keywords?: string | null;
  metaDescription: string;
  metaRobots?: string | null;
  metaTitle: string;
  metaViewport?: string | null;
  structuredData?: any | null;
  metaImage?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
  metaSocial?: Array<{
    __typename?: 'ComponentSharedMetaSocial';
    id: string;
    title: string;
    description: string;
    socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
    image?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type SoftwareHeroQueryVariables = Exact<{ [key: string]: never }>;

export type SoftwareHeroQuery = {
  __typename?: 'Query';
  software?: {
    __typename?: 'SoftwareEntityResponse';
    data?: {
      __typename?: 'SoftwareEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Software';
        heroVideoSection: {
          __typename?: 'ComponentUiHeroVideoSection';
          id: string;
          title: string;
          subtitle?: string | null;
          previewVideoLength?: string | null;
          showPreviewVideo?: boolean | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            autoplay?: boolean | null;
            controls?: boolean | null;
            fullscreen?: boolean | null;
            id: string;
            loop?: boolean | null;
            muted?: boolean | null;
            pauseVideo?: boolean | null;
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          previewVideo?: {
            __typename?: 'ComponentUiVideo';
            autoplay?: boolean | null;
            controls?: boolean | null;
            fullscreen?: boolean | null;
            id: string;
            loop?: boolean | null;
            muted?: boolean | null;
            pauseVideo?: boolean | null;
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        };
      } | null;
    } | null;
  } | null;
};

export type SoftwareProductImageBlockQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SoftwareProductImageBlockQuery = {
  __typename?: 'Query';
  software?: {
    __typename?: 'SoftwareEntityResponse';
    data?: {
      __typename?: 'SoftwareEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Software';
        productImageBlock?: Array<{
          __typename?: 'ComponentSharedProductImageBlock';
          title?: string | null;
          subtitle?: string | null;
          anchor?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
          } | null;
          largeSpec?: Array<{
            __typename?: 'ComponentUiSpec';
            label?: string | null;
            value?: string | null;
          } | null> | null;
          gallery?: Array<{
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          spec_tables?: {
            __typename?: 'SpecTableRelationResponseCollection';
            data: Array<{
              __typename?: 'SpecTableEntity';
              attributes?: {
                __typename?: 'SpecTable';
                heading?: {
                  __typename?: 'ComponentUiHeading';
                  title?: string | null;
                  subtitle?: string | null;
                } | null;
                feature: Array<{
                  __typename?: 'ComponentUiSpecTable';
                  label?: string | null;
                  spec: Array<{
                    __typename?: 'ComponentUiSpec';
                    id: string;
                    label?: string | null;
                    value?: string | null;
                  } | null>;
                } | null>;
              } | null;
            }>;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type SoftwareNewsroomQueryVariables = Exact<{ [key: string]: never }>;

export type SoftwareNewsroomQuery = {
  __typename?: 'Query';
  software?: {
    __typename?: 'SoftwareEntityResponse';
    data?: {
      __typename?: 'SoftwareEntity';
      attributes?: {
        __typename?: 'Software';
        newsroom?: {
          __typename?: 'ComponentSharedNewsroom';
          title?: string | null;
          anchor?: string | null;
          logo?: Array<{
            __typename?: 'ComponentUiIcon';
            id: string;
            alt: string;
            svg: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
          } | null> | null;
          articles?: {
            __typename?: 'ArticleRelationResponseCollection';
            data: Array<{
              __typename?: 'ArticleEntity';
              attributes?: {
                __typename?: 'Article';
                slug?: string | null;
                preview?: {
                  __typename?: 'ComponentArticleArticlePreview';
                  title?: string | null;
                  date?: string | null;
                  alt?: string | null;
                  link_path?: string | null;
                  tag?: string | null;
                  tagColor?: Enum_Componentarticlearticlepreview_Tagcolor | null;
                  img_desktop?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                hero?: {
                  __typename?: 'ComponentArticleArticleHero';
                  title?: string | null;
                  image?: {
                    __typename?: 'ComponentUiImage';
                    id: string;
                    alt: string;
                    img_desktop: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    };
                    img_mobile?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                content?: Array<
                  | {
                      __typename?: 'ComponentArticleGallery';
                      images?: Array<{
                        __typename?: 'ComponentUiImage';
                        id: string;
                        alt: string;
                        img_desktop: {
                          __typename?: 'UploadFileEntityResponse';
                          data?: {
                            __typename?: 'UploadFileEntity';
                            id?: string | null;
                            attributes?: {
                              __typename?: 'UploadFile';
                              url: string;
                              alternativeText?: string | null;
                              blurhash?: string | null;
                            } | null;
                          } | null;
                        };
                        img_mobile?: {
                          __typename?: 'UploadFileEntityResponse';
                          data?: {
                            __typename?: 'UploadFileEntity';
                            id?: string | null;
                            attributes?: {
                              __typename?: 'UploadFile';
                              url: string;
                              alternativeText?: string | null;
                              blurhash?: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'ComponentArticleTextContent';
                      title?: string | null;
                      text?: string | null;
                    }
                  | { __typename?: 'Error' }
                  | null
                > | null;
              } | null;
            }>;
          } | null;
          button?: {
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type SoftwareSeoQueryVariables = Exact<{ [key: string]: never }>;

export type SoftwareSeoQuery = {
  __typename?: 'Query';
  software?: {
    __typename?: 'SoftwareEntityResponse';
    data?: {
      __typename?: 'SoftwareEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Software';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const HeadlineFragmentDoc = gql`
  fragment headline on ComponentUiHeadline {
    id
    title
    description
    link_path
    link_text
  }
`;
export const OptionFragmentDoc = gql`
  fragment option on ComponentUiOptions {
    id
    option
  }
`;
export const UploadFileFragmentDoc = gql`
  fragment uploadFile on UploadFileEntityResponse {
    data {
      id
      attributes {
        url
        alternativeText
        blurhash
      }
    }
  }
`;
export const GraphFragmentDoc = gql`
  fragment graph on ComponentUiGraph {
    id
    label
    image {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const ButtonFragmentDoc = gql`
  fragment button on ComponentUiButton {
    id
    label
    path
  }
`;
export const VideoFragmentDoc = gql`
  fragment video on ComponentUiVideo {
    autoplay
    controls
    fullscreen
    id
    loop
    muted
    pauseVideo
    poster {
      ...uploadFile
    }
    mobilePoster {
      ...uploadFile
    }
    poster_alt
    video {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const HeroVideoSectionFragmentDoc = gql`
  fragment heroVideoSection on ComponentUiHeroVideoSection {
    id
    title
    subtitle
    button {
      ...button
    }
    heroVideo {
      ...video
    }
    previewVideo {
      ...video
    }
    previewVideoLength
    showPreviewVideo
  }
  ${ButtonFragmentDoc}
  ${VideoFragmentDoc}
`;
export const ImageFragmentDoc = gql`
  fragment image on ComponentUiImage {
    id
    alt
    img_desktop {
      ...uploadFile
    }
    img_mobile {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const HeroImageSectionFragmentDoc = gql`
  fragment heroImageSection on ComponentUiHeroImageSection {
    id
    title
    subtitle
    button {
      ...button
    }
    heroImage {
      ...image
    }
    previewVideo {
      ...video
    }
    previewVideoLength
  }
  ${ButtonFragmentDoc}
  ${ImageFragmentDoc}
  ${VideoFragmentDoc}
`;
export const UploadDocumentFragmentDoc = gql`
  fragment uploadDocument on UploadFileRelationResponseCollection {
    data {
      id
      attributes {
        url
        size
        blurhash
      }
    }
  }
`;
export const CardFragmentDoc = gql`
  fragment card on ComponentUiCard {
    id
    Title
    description
    button
    file {
      ...uploadDocument
    }
  }
  ${UploadDocumentFragmentDoc}
`;
export const GuidelinesFragmentDoc = gql`
  fragment guidelines on ComponentUiGuidelines {
    anchor
    title
    card {
      ...card
    }
  }
  ${CardFragmentDoc}
`;
export const TeamFragmentDoc = gql`
  fragment team on ComponentUiTeam {
    anchor
    title
    team_mate {
      alt
      img_desktop {
        ...uploadFile
      }
      img_mobile {
        ...uploadFile
      }
      last_name
      name
      positions
      miniature {
        ...uploadFile
      }
    }
  }
  ${UploadFileFragmentDoc}
`;
export const FacilitiesFragmentDoc = gql`
  fragment facilities on ComponentCompanyFacilities {
    anchor
    title
    map {
      alt
      name
      img_desktop {
        ...uploadFile
      }
      img_mobile {
        ...uploadFile
      }
    }
  }
  ${UploadFileFragmentDoc}
`;
export const ImageCardLinkFragmentDoc = gql`
  fragment imageCardLink on ComponentUiImageCardLink {
    title
    description
    link_label
    link_path
    alt
    tag
    img_desktop {
      ...uploadFile
    }
    img_mobil {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const ImageCardsWithLinkFragmentDoc = gql`
  fragment imageCardsWithLink on ComponentHomepageImageCardsWIthLink {
    id
    imageCardLink {
      ...imageCardLink
    }
  }
  ${ImageCardLinkFragmentDoc}
`;
export const PolicyFieldsFragmentDoc = gql`
  fragment PolicyFields on PrivacyPolicyPoliciesDynamicZone {
    ... on ComponentPoliciesDownloadLink {
      label
      link
    }
    ... on ComponentPoliciesMainHeading {
      text
    }
    ... on ComponentPoliciesTitleTextContent {
      text
    }
    ... on ComponentPoliciesTableTextContent {
      richText
      tableHead {
        headingItem
      }
      tableRow {
        table_rows {
          data {
            attributes {
              rows {
                item
              }
            }
          }
        }
      }
    }
    ... on ComponentPoliciesDevider {
      Devider
    }
  }
`;
export const SpecFragmentDoc = gql`
  fragment spec on ComponentUiSpec {
    id
    label
    value
  }
`;
export const TableFragmentDoc = gql`
  fragment table on ComponentUiSpecTable {
    label
    spec(pagination: { limit: 100 }) {
      ...spec
    }
  }
  ${SpecFragmentDoc}
`;
export const PowertrainSpecsFragmentDoc = gql`
  fragment powertrainSpecs on ComponentPowertrainTechnologies {
    id
    anchor
    title
    description
    image {
      ...image
    }
    paragraphs {
      paragraphs
    }
    spec_tables {
      data {
        attributes {
          feature {
            ...table
          }
        }
      }
    }
  }
  ${ImageFragmentDoc}
  ${TableFragmentDoc}
`;
export const IconCardFragmentDoc = gql`
  fragment iconCard on ComponentUiIconCard {
    id
    title
    description
    icon {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const ValuesFragmentDoc = gql`
  fragment values on ComponentSharedValues {
    title
    anchor
    cards {
      ...iconCard
    }
  }
  ${IconCardFragmentDoc}
`;
export const TwoImagesGridFragmentDoc = gql`
  fragment twoImagesGrid on ComponentSharedTwoImagesGrid {
    title
    anchor
    imagesGridItem {
      title
      description
      tag
      tagColor
      alt
      img_desktop {
        ...uploadFile
      }
      img_mobile {
        ...uploadFile
      }
    }
  }
  ${UploadFileFragmentDoc}
`;
export const LargeImageSectionFragmentDoc = gql`
  fragment largeImageSection on ComponentSharedLargeImageSection {
    anchor
    title
    description
    button {
      label
      path
    }
    image {
      ...image
    }
  }
  ${ImageFragmentDoc}
`;
export const CardsSectionFragmentDoc = gql`
  fragment cardsSection on ComponentSharedSectionCards {
    title
    anchor
    subtitle
    iconCard {
      ...iconCard
    }
  }
  ${IconCardFragmentDoc}
`;
export const ProductSpecsBlockFragmentDoc = gql`
  fragment productSpecsBlock on ComponentSharedProductSpecsBlocks {
    title
    subtitle
    anchor
    specs_options {
      data {
        attributes {
          option
          gallery {
            ...image
          }
          largeSpec {
            label
            value
          }
          spec_tables {
            data {
              attributes {
                feature {
                  ...table
                }
              }
            }
          }
          graph {
            label
            image {
              ...uploadFile
            }
          }
          button {
            label
            path
          }
        }
      }
    }
  }
  ${ImageFragmentDoc}
  ${TableFragmentDoc}
  ${UploadFileFragmentDoc}
`;
export const SliderImageSectionFragmentDoc = gql`
  fragment sliderImageSection on ComponentSharedSliderImageSection {
    id
    anchor
    title
    button {
      label
      path
    }
    imageCardLink {
      ...imageCardLink
    }
  }
  ${ImageCardLinkFragmentDoc}
`;
export const ProductImageBlockFragmentDoc = gql`
  fragment productImageBlock on ComponentSharedProductImageBlock {
    title
    subtitle
    anchor
    button {
      label
      path
    }
    largeSpec {
      label
      value
    }
    gallery {
      ...image
    }
    spec_tables {
      data {
        attributes {
          heading {
            title
            subtitle
          }
          feature {
            ...table
          }
        }
      }
    }
  }
  ${ImageFragmentDoc}
  ${TableFragmentDoc}
`;
export const IconFragmentDoc = gql`
  fragment icon on ComponentUiIcon {
    id
    alt
    svg {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const ArticlePreviewFragmentDoc = gql`
  fragment articlePreview on ComponentArticleArticlePreview {
    title
    date
    alt
    link_path
    tag
    tagColor
    img_desktop {
      ...uploadFile
    }
    img_mobile {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const ArticleFragmentDoc = gql`
  fragment article on Article {
    slug
    preview {
      ...articlePreview
    }
    hero {
      title
      image {
        ...image
      }
    }
    content {
      ... on ComponentArticleTextContent {
        title
        text
      }
      ... on ComponentArticleGallery {
        images {
          ...image
        }
      }
    }
  }
  ${ArticlePreviewFragmentDoc}
  ${ImageFragmentDoc}
`;
export const NewsroomFragmentDoc = gql`
  fragment newsroom on ComponentSharedNewsroom {
    title
    anchor
    logo {
      ...icon
    }
    articles {
      data {
        attributes {
          ...article
        }
      }
    }
    button {
      label
      path
    }
  }
  ${IconFragmentDoc}
  ${ArticleFragmentDoc}
`;
export const MetaSocialFragmentDoc = gql`
  fragment metaSocial on ComponentSharedMetaSocial {
    id
    title
    description
    socialNetwork
    image {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const SeoFragmentDoc = gql`
  fragment seo on ComponentSharedSeo {
    id
    canonicalURL
    keywords
    metaDescription
    metaImage {
      ...uploadFile
    }
    metaRobots
    metaSocial {
      ...metaSocial
    }
    metaTitle
    metaViewport
    structuredData
  }
  ${UploadFileFragmentDoc}
  ${MetaSocialFragmentDoc}
`;
export const BatteryHeroDocument = gql`
  query BatteryHero {
    battery {
      data {
        id
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __useBatteryHeroQuery__
 *
 * To run a query within a React component, call `useBatteryHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatteryHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatteryHeroQuery({
 *   variables: {
 *   },
 * });
 */
export function useBatteryHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BatteryHeroQuery,
    BatteryHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BatteryHeroQuery, BatteryHeroQueryVariables>(
    BatteryHeroDocument,
    options,
  );
}
export function useBatteryHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BatteryHeroQuery,
    BatteryHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BatteryHeroQuery, BatteryHeroQueryVariables>(
    BatteryHeroDocument,
    options,
  );
}
export function useBatteryHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BatteryHeroQuery,
        BatteryHeroQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BatteryHeroQuery, BatteryHeroQueryVariables>(
    BatteryHeroDocument,
    options,
  );
}
export type BatteryHeroQueryHookResult = ReturnType<typeof useBatteryHeroQuery>;
export type BatteryHeroLazyQueryHookResult = ReturnType<
  typeof useBatteryHeroLazyQuery
>;
export type BatteryHeroSuspenseQueryHookResult = ReturnType<
  typeof useBatteryHeroSuspenseQuery
>;
export type BatteryHeroQueryResult = Apollo.QueryResult<
  BatteryHeroQuery,
  BatteryHeroQueryVariables
>;
export const BatteryCardsSectionDocument = gql`
  query BatteryCardsSection {
    battery {
      data {
        id
        attributes {
          cardsSection {
            ...cardsSection
          }
        }
      }
    }
  }
  ${CardsSectionFragmentDoc}
`;

/**
 * __useBatteryCardsSectionQuery__
 *
 * To run a query within a React component, call `useBatteryCardsSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatteryCardsSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatteryCardsSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useBatteryCardsSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BatteryCardsSectionQuery,
    BatteryCardsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BatteryCardsSectionQuery,
    BatteryCardsSectionQueryVariables
  >(BatteryCardsSectionDocument, options);
}
export function useBatteryCardsSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BatteryCardsSectionQuery,
    BatteryCardsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BatteryCardsSectionQuery,
    BatteryCardsSectionQueryVariables
  >(BatteryCardsSectionDocument, options);
}
export function useBatteryCardsSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BatteryCardsSectionQuery,
        BatteryCardsSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BatteryCardsSectionQuery,
    BatteryCardsSectionQueryVariables
  >(BatteryCardsSectionDocument, options);
}
export type BatteryCardsSectionQueryHookResult = ReturnType<
  typeof useBatteryCardsSectionQuery
>;
export type BatteryCardsSectionLazyQueryHookResult = ReturnType<
  typeof useBatteryCardsSectionLazyQuery
>;
export type BatteryCardsSectionSuspenseQueryHookResult = ReturnType<
  typeof useBatteryCardsSectionSuspenseQuery
>;
export type BatteryCardsSectionQueryResult = Apollo.QueryResult<
  BatteryCardsSectionQuery,
  BatteryCardsSectionQueryVariables
>;
export const BatteryProductImageBlockDocument = gql`
  query BatteryProductImageBlock {
    battery {
      data {
        attributes {
          productImageBlock {
            ...productImageBlock
          }
        }
      }
    }
  }
  ${ProductImageBlockFragmentDoc}
`;

/**
 * __useBatteryProductImageBlockQuery__
 *
 * To run a query within a React component, call `useBatteryProductImageBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatteryProductImageBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatteryProductImageBlockQuery({
 *   variables: {
 *   },
 * });
 */
export function useBatteryProductImageBlockQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BatteryProductImageBlockQuery,
    BatteryProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BatteryProductImageBlockQuery,
    BatteryProductImageBlockQueryVariables
  >(BatteryProductImageBlockDocument, options);
}
export function useBatteryProductImageBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BatteryProductImageBlockQuery,
    BatteryProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BatteryProductImageBlockQuery,
    BatteryProductImageBlockQueryVariables
  >(BatteryProductImageBlockDocument, options);
}
export function useBatteryProductImageBlockSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BatteryProductImageBlockQuery,
        BatteryProductImageBlockQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BatteryProductImageBlockQuery,
    BatteryProductImageBlockQueryVariables
  >(BatteryProductImageBlockDocument, options);
}
export type BatteryProductImageBlockQueryHookResult = ReturnType<
  typeof useBatteryProductImageBlockQuery
>;
export type BatteryProductImageBlockLazyQueryHookResult = ReturnType<
  typeof useBatteryProductImageBlockLazyQuery
>;
export type BatteryProductImageBlockSuspenseQueryHookResult = ReturnType<
  typeof useBatteryProductImageBlockSuspenseQuery
>;
export type BatteryProductImageBlockQueryResult = Apollo.QueryResult<
  BatteryProductImageBlockQuery,
  BatteryProductImageBlockQueryVariables
>;
export const BatteryTwoImagesGridDocument = gql`
  query BatteryTwoImagesGrid {
    battery {
      data {
        attributes {
          twoImagesGrid {
            ...twoImagesGrid
          }
        }
      }
    }
  }
  ${TwoImagesGridFragmentDoc}
`;

/**
 * __useBatteryTwoImagesGridQuery__
 *
 * To run a query within a React component, call `useBatteryTwoImagesGridQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatteryTwoImagesGridQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatteryTwoImagesGridQuery({
 *   variables: {
 *   },
 * });
 */
export function useBatteryTwoImagesGridQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BatteryTwoImagesGridQuery,
    BatteryTwoImagesGridQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BatteryTwoImagesGridQuery,
    BatteryTwoImagesGridQueryVariables
  >(BatteryTwoImagesGridDocument, options);
}
export function useBatteryTwoImagesGridLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BatteryTwoImagesGridQuery,
    BatteryTwoImagesGridQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BatteryTwoImagesGridQuery,
    BatteryTwoImagesGridQueryVariables
  >(BatteryTwoImagesGridDocument, options);
}
export function useBatteryTwoImagesGridSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BatteryTwoImagesGridQuery,
        BatteryTwoImagesGridQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BatteryTwoImagesGridQuery,
    BatteryTwoImagesGridQueryVariables
  >(BatteryTwoImagesGridDocument, options);
}
export type BatteryTwoImagesGridQueryHookResult = ReturnType<
  typeof useBatteryTwoImagesGridQuery
>;
export type BatteryTwoImagesGridLazyQueryHookResult = ReturnType<
  typeof useBatteryTwoImagesGridLazyQuery
>;
export type BatteryTwoImagesGridSuspenseQueryHookResult = ReturnType<
  typeof useBatteryTwoImagesGridSuspenseQuery
>;
export type BatteryTwoImagesGridQueryResult = Apollo.QueryResult<
  BatteryTwoImagesGridQuery,
  BatteryTwoImagesGridQueryVariables
>;
export const BatteryLargeImageDocument = gql`
  query BatteryLargeImage {
    battery {
      data {
        attributes {
          largeImageSection {
            ...largeImageSection
          }
        }
      }
    }
  }
  ${LargeImageSectionFragmentDoc}
`;

/**
 * __useBatteryLargeImageQuery__
 *
 * To run a query within a React component, call `useBatteryLargeImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatteryLargeImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatteryLargeImageQuery({
 *   variables: {
 *   },
 * });
 */
export function useBatteryLargeImageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BatteryLargeImageQuery,
    BatteryLargeImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BatteryLargeImageQuery,
    BatteryLargeImageQueryVariables
  >(BatteryLargeImageDocument, options);
}
export function useBatteryLargeImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BatteryLargeImageQuery,
    BatteryLargeImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BatteryLargeImageQuery,
    BatteryLargeImageQueryVariables
  >(BatteryLargeImageDocument, options);
}
export function useBatteryLargeImageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BatteryLargeImageQuery,
        BatteryLargeImageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BatteryLargeImageQuery,
    BatteryLargeImageQueryVariables
  >(BatteryLargeImageDocument, options);
}
export type BatteryLargeImageQueryHookResult = ReturnType<
  typeof useBatteryLargeImageQuery
>;
export type BatteryLargeImageLazyQueryHookResult = ReturnType<
  typeof useBatteryLargeImageLazyQuery
>;
export type BatteryLargeImageSuspenseQueryHookResult = ReturnType<
  typeof useBatteryLargeImageSuspenseQuery
>;
export type BatteryLargeImageQueryResult = Apollo.QueryResult<
  BatteryLargeImageQuery,
  BatteryLargeImageQueryVariables
>;
export const BatteryNewsroomDocument = gql`
  query BatteryNewsroom {
    battery {
      data {
        attributes {
          newsroom {
            ...newsroom
          }
        }
      }
    }
  }
  ${NewsroomFragmentDoc}
`;

/**
 * __useBatteryNewsroomQuery__
 *
 * To run a query within a React component, call `useBatteryNewsroomQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatteryNewsroomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatteryNewsroomQuery({
 *   variables: {
 *   },
 * });
 */
export function useBatteryNewsroomQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BatteryNewsroomQuery,
    BatteryNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BatteryNewsroomQuery, BatteryNewsroomQueryVariables>(
    BatteryNewsroomDocument,
    options,
  );
}
export function useBatteryNewsroomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BatteryNewsroomQuery,
    BatteryNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BatteryNewsroomQuery,
    BatteryNewsroomQueryVariables
  >(BatteryNewsroomDocument, options);
}
export function useBatteryNewsroomSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BatteryNewsroomQuery,
        BatteryNewsroomQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BatteryNewsroomQuery,
    BatteryNewsroomQueryVariables
  >(BatteryNewsroomDocument, options);
}
export type BatteryNewsroomQueryHookResult = ReturnType<
  typeof useBatteryNewsroomQuery
>;
export type BatteryNewsroomLazyQueryHookResult = ReturnType<
  typeof useBatteryNewsroomLazyQuery
>;
export type BatteryNewsroomSuspenseQueryHookResult = ReturnType<
  typeof useBatteryNewsroomSuspenseQuery
>;
export type BatteryNewsroomQueryResult = Apollo.QueryResult<
  BatteryNewsroomQuery,
  BatteryNewsroomQueryVariables
>;
export const BatterySeoDocument = gql`
  query BatterySeo {
    battery {
      data {
        id
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useBatterySeoQuery__
 *
 * To run a query within a React component, call `useBatterySeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatterySeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatterySeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useBatterySeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BatterySeoQuery,
    BatterySeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BatterySeoQuery, BatterySeoQueryVariables>(
    BatterySeoDocument,
    options,
  );
}
export function useBatterySeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BatterySeoQuery,
    BatterySeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BatterySeoQuery, BatterySeoQueryVariables>(
    BatterySeoDocument,
    options,
  );
}
export function useBatterySeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BatterySeoQuery,
        BatterySeoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BatterySeoQuery, BatterySeoQueryVariables>(
    BatterySeoDocument,
    options,
  );
}
export type BatterySeoQueryHookResult = ReturnType<typeof useBatterySeoQuery>;
export type BatterySeoLazyQueryHookResult = ReturnType<
  typeof useBatterySeoLazyQuery
>;
export type BatterySeoSuspenseQueryHookResult = ReturnType<
  typeof useBatterySeoSuspenseQuery
>;
export type BatterySeoQueryResult = Apollo.QueryResult<
  BatterySeoQuery,
  BatterySeoQueryVariables
>;
export const BmsHeroDocument = gql`
  query BmsHero {
    bms {
      data {
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __useBmsHeroQuery__
 *
 * To run a query within a React component, call `useBmsHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useBmsHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBmsHeroQuery({
 *   variables: {
 *   },
 * });
 */
export function useBmsHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<BmsHeroQuery, BmsHeroQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BmsHeroQuery, BmsHeroQueryVariables>(
    BmsHeroDocument,
    options,
  );
}
export function useBmsHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BmsHeroQuery,
    BmsHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BmsHeroQuery, BmsHeroQueryVariables>(
    BmsHeroDocument,
    options,
  );
}
export function useBmsHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BmsHeroQuery, BmsHeroQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BmsHeroQuery, BmsHeroQueryVariables>(
    BmsHeroDocument,
    options,
  );
}
export type BmsHeroQueryHookResult = ReturnType<typeof useBmsHeroQuery>;
export type BmsHeroLazyQueryHookResult = ReturnType<typeof useBmsHeroLazyQuery>;
export type BmsHeroSuspenseQueryHookResult = ReturnType<
  typeof useBmsHeroSuspenseQuery
>;
export type BmsHeroQueryResult = Apollo.QueryResult<
  BmsHeroQuery,
  BmsHeroQueryVariables
>;
export const BmsIconCardsDocument = gql`
  query BmsIconCards {
    bms {
      data {
        attributes {
          cardsSection {
            ...cardsSection
          }
        }
      }
    }
  }
  ${CardsSectionFragmentDoc}
`;

/**
 * __useBmsIconCardsQuery__
 *
 * To run a query within a React component, call `useBmsIconCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBmsIconCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBmsIconCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBmsIconCardsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BmsIconCardsQuery,
    BmsIconCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BmsIconCardsQuery, BmsIconCardsQueryVariables>(
    BmsIconCardsDocument,
    options,
  );
}
export function useBmsIconCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BmsIconCardsQuery,
    BmsIconCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BmsIconCardsQuery, BmsIconCardsQueryVariables>(
    BmsIconCardsDocument,
    options,
  );
}
export function useBmsIconCardsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BmsIconCardsQuery,
        BmsIconCardsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BmsIconCardsQuery, BmsIconCardsQueryVariables>(
    BmsIconCardsDocument,
    options,
  );
}
export type BmsIconCardsQueryHookResult = ReturnType<
  typeof useBmsIconCardsQuery
>;
export type BmsIconCardsLazyQueryHookResult = ReturnType<
  typeof useBmsIconCardsLazyQuery
>;
export type BmsIconCardsSuspenseQueryHookResult = ReturnType<
  typeof useBmsIconCardsSuspenseQuery
>;
export type BmsIconCardsQueryResult = Apollo.QueryResult<
  BmsIconCardsQuery,
  BmsIconCardsQueryVariables
>;
export const BmsProductImageBlockDocument = gql`
  query BmsProductImageBlock {
    bms {
      data {
        attributes {
          productImageBlock {
            ...productImageBlock
          }
        }
      }
    }
  }
  ${ProductImageBlockFragmentDoc}
`;

/**
 * __useBmsProductImageBlockQuery__
 *
 * To run a query within a React component, call `useBmsProductImageBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useBmsProductImageBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBmsProductImageBlockQuery({
 *   variables: {
 *   },
 * });
 */
export function useBmsProductImageBlockQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BmsProductImageBlockQuery,
    BmsProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BmsProductImageBlockQuery,
    BmsProductImageBlockQueryVariables
  >(BmsProductImageBlockDocument, options);
}
export function useBmsProductImageBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BmsProductImageBlockQuery,
    BmsProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BmsProductImageBlockQuery,
    BmsProductImageBlockQueryVariables
  >(BmsProductImageBlockDocument, options);
}
export function useBmsProductImageBlockSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BmsProductImageBlockQuery,
        BmsProductImageBlockQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BmsProductImageBlockQuery,
    BmsProductImageBlockQueryVariables
  >(BmsProductImageBlockDocument, options);
}
export type BmsProductImageBlockQueryHookResult = ReturnType<
  typeof useBmsProductImageBlockQuery
>;
export type BmsProductImageBlockLazyQueryHookResult = ReturnType<
  typeof useBmsProductImageBlockLazyQuery
>;
export type BmsProductImageBlockSuspenseQueryHookResult = ReturnType<
  typeof useBmsProductImageBlockSuspenseQuery
>;
export type BmsProductImageBlockQueryResult = Apollo.QueryResult<
  BmsProductImageBlockQuery,
  BmsProductImageBlockQueryVariables
>;
export const BmsProductSpecsBlockDocument = gql`
  query BmsProductSpecsBlock {
    bms {
      data {
        attributes {
          productSpecsBlock {
            ...productSpecsBlock
          }
        }
      }
    }
  }
  ${ProductSpecsBlockFragmentDoc}
`;

/**
 * __useBmsProductSpecsBlockQuery__
 *
 * To run a query within a React component, call `useBmsProductSpecsBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useBmsProductSpecsBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBmsProductSpecsBlockQuery({
 *   variables: {
 *   },
 * });
 */
export function useBmsProductSpecsBlockQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BmsProductSpecsBlockQuery,
    BmsProductSpecsBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BmsProductSpecsBlockQuery,
    BmsProductSpecsBlockQueryVariables
  >(BmsProductSpecsBlockDocument, options);
}
export function useBmsProductSpecsBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BmsProductSpecsBlockQuery,
    BmsProductSpecsBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BmsProductSpecsBlockQuery,
    BmsProductSpecsBlockQueryVariables
  >(BmsProductSpecsBlockDocument, options);
}
export function useBmsProductSpecsBlockSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BmsProductSpecsBlockQuery,
        BmsProductSpecsBlockQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BmsProductSpecsBlockQuery,
    BmsProductSpecsBlockQueryVariables
  >(BmsProductSpecsBlockDocument, options);
}
export type BmsProductSpecsBlockQueryHookResult = ReturnType<
  typeof useBmsProductSpecsBlockQuery
>;
export type BmsProductSpecsBlockLazyQueryHookResult = ReturnType<
  typeof useBmsProductSpecsBlockLazyQuery
>;
export type BmsProductSpecsBlockSuspenseQueryHookResult = ReturnType<
  typeof useBmsProductSpecsBlockSuspenseQuery
>;
export type BmsProductSpecsBlockQueryResult = Apollo.QueryResult<
  BmsProductSpecsBlockQuery,
  BmsProductSpecsBlockQueryVariables
>;
export const BmsTwoImagesGridDocument = gql`
  query BmsTwoImagesGrid {
    bms {
      data {
        attributes {
          twoImagesGrid {
            ...twoImagesGrid
          }
        }
      }
    }
  }
  ${TwoImagesGridFragmentDoc}
`;

/**
 * __useBmsTwoImagesGridQuery__
 *
 * To run a query within a React component, call `useBmsTwoImagesGridQuery` and pass it any options that fit your needs.
 * When your component renders, `useBmsTwoImagesGridQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBmsTwoImagesGridQuery({
 *   variables: {
 *   },
 * });
 */
export function useBmsTwoImagesGridQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BmsTwoImagesGridQuery,
    BmsTwoImagesGridQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BmsTwoImagesGridQuery, BmsTwoImagesGridQueryVariables>(
    BmsTwoImagesGridDocument,
    options,
  );
}
export function useBmsTwoImagesGridLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BmsTwoImagesGridQuery,
    BmsTwoImagesGridQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BmsTwoImagesGridQuery,
    BmsTwoImagesGridQueryVariables
  >(BmsTwoImagesGridDocument, options);
}
export function useBmsTwoImagesGridSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BmsTwoImagesGridQuery,
        BmsTwoImagesGridQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BmsTwoImagesGridQuery,
    BmsTwoImagesGridQueryVariables
  >(BmsTwoImagesGridDocument, options);
}
export type BmsTwoImagesGridQueryHookResult = ReturnType<
  typeof useBmsTwoImagesGridQuery
>;
export type BmsTwoImagesGridLazyQueryHookResult = ReturnType<
  typeof useBmsTwoImagesGridLazyQuery
>;
export type BmsTwoImagesGridSuspenseQueryHookResult = ReturnType<
  typeof useBmsTwoImagesGridSuspenseQuery
>;
export type BmsTwoImagesGridQueryResult = Apollo.QueryResult<
  BmsTwoImagesGridQuery,
  BmsTwoImagesGridQueryVariables
>;
export const BmsNewsroomDocument = gql`
  query BmsNewsroom {
    bms {
      data {
        attributes {
          newsroom {
            ...newsroom
          }
        }
      }
    }
  }
  ${NewsroomFragmentDoc}
`;

/**
 * __useBmsNewsroomQuery__
 *
 * To run a query within a React component, call `useBmsNewsroomQuery` and pass it any options that fit your needs.
 * When your component renders, `useBmsNewsroomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBmsNewsroomQuery({
 *   variables: {
 *   },
 * });
 */
export function useBmsNewsroomQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BmsNewsroomQuery,
    BmsNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BmsNewsroomQuery, BmsNewsroomQueryVariables>(
    BmsNewsroomDocument,
    options,
  );
}
export function useBmsNewsroomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BmsNewsroomQuery,
    BmsNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BmsNewsroomQuery, BmsNewsroomQueryVariables>(
    BmsNewsroomDocument,
    options,
  );
}
export function useBmsNewsroomSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BmsNewsroomQuery,
        BmsNewsroomQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BmsNewsroomQuery, BmsNewsroomQueryVariables>(
    BmsNewsroomDocument,
    options,
  );
}
export type BmsNewsroomQueryHookResult = ReturnType<typeof useBmsNewsroomQuery>;
export type BmsNewsroomLazyQueryHookResult = ReturnType<
  typeof useBmsNewsroomLazyQuery
>;
export type BmsNewsroomSuspenseQueryHookResult = ReturnType<
  typeof useBmsNewsroomSuspenseQuery
>;
export type BmsNewsroomQueryResult = Apollo.QueryResult<
  BmsNewsroomQuery,
  BmsNewsroomQueryVariables
>;
export const BmsSeoDocument = gql`
  query BmsSeo {
    bms {
      data {
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useBmsSeoQuery__
 *
 * To run a query within a React component, call `useBmsSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBmsSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBmsSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useBmsSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<BmsSeoQuery, BmsSeoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BmsSeoQuery, BmsSeoQueryVariables>(
    BmsSeoDocument,
    options,
  );
}
export function useBmsSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BmsSeoQuery, BmsSeoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BmsSeoQuery, BmsSeoQueryVariables>(
    BmsSeoDocument,
    options,
  );
}
export function useBmsSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BmsSeoQuery, BmsSeoQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BmsSeoQuery, BmsSeoQueryVariables>(
    BmsSeoDocument,
    options,
  );
}
export type BmsSeoQueryHookResult = ReturnType<typeof useBmsSeoQuery>;
export type BmsSeoLazyQueryHookResult = ReturnType<typeof useBmsSeoLazyQuery>;
export type BmsSeoSuspenseQueryHookResult = ReturnType<
  typeof useBmsSeoSuspenseQuery
>;
export type BmsSeoQueryResult = Apollo.QueryResult<
  BmsSeoQuery,
  BmsSeoQueryVariables
>;
export const CompanyHeroDocument = gql`
  query CompanyHero {
    company {
      data {
        id
        attributes {
          heroImageSection {
            ...heroImageSection
          }
        }
      }
    }
  }
  ${HeroImageSectionFragmentDoc}
`;

/**
 * __useCompanyHeroQuery__
 *
 * To run a query within a React component, call `useCompanyHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyHeroQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyHeroQuery,
    CompanyHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyHeroQuery, CompanyHeroQueryVariables>(
    CompanyHeroDocument,
    options,
  );
}
export function useCompanyHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyHeroQuery,
    CompanyHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyHeroQuery, CompanyHeroQueryVariables>(
    CompanyHeroDocument,
    options,
  );
}
export function useCompanyHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyHeroQuery,
        CompanyHeroQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanyHeroQuery, CompanyHeroQueryVariables>(
    CompanyHeroDocument,
    options,
  );
}
export type CompanyHeroQueryHookResult = ReturnType<typeof useCompanyHeroQuery>;
export type CompanyHeroLazyQueryHookResult = ReturnType<
  typeof useCompanyHeroLazyQuery
>;
export type CompanyHeroSuspenseQueryHookResult = ReturnType<
  typeof useCompanyHeroSuspenseQuery
>;
export type CompanyHeroQueryResult = Apollo.QueryResult<
  CompanyHeroQuery,
  CompanyHeroQueryVariables
>;
export const CompanySliderImageSectionDocument = gql`
  query CompanySliderImageSection {
    company {
      data {
        attributes {
          sliderImageSection {
            ...sliderImageSection
          }
        }
      }
    }
  }
  ${SliderImageSectionFragmentDoc}
`;

/**
 * __useCompanySliderImageSectionQuery__
 *
 * To run a query within a React component, call `useCompanySliderImageSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySliderImageSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySliderImageSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanySliderImageSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanySliderImageSectionQuery,
    CompanySliderImageSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanySliderImageSectionQuery,
    CompanySliderImageSectionQueryVariables
  >(CompanySliderImageSectionDocument, options);
}
export function useCompanySliderImageSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanySliderImageSectionQuery,
    CompanySliderImageSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanySliderImageSectionQuery,
    CompanySliderImageSectionQueryVariables
  >(CompanySliderImageSectionDocument, options);
}
export function useCompanySliderImageSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanySliderImageSectionQuery,
        CompanySliderImageSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompanySliderImageSectionQuery,
    CompanySliderImageSectionQueryVariables
  >(CompanySliderImageSectionDocument, options);
}
export type CompanySliderImageSectionQueryHookResult = ReturnType<
  typeof useCompanySliderImageSectionQuery
>;
export type CompanySliderImageSectionLazyQueryHookResult = ReturnType<
  typeof useCompanySliderImageSectionLazyQuery
>;
export type CompanySliderImageSectionSuspenseQueryHookResult = ReturnType<
  typeof useCompanySliderImageSectionSuspenseQuery
>;
export type CompanySliderImageSectionQueryResult = Apollo.QueryResult<
  CompanySliderImageSectionQuery,
  CompanySliderImageSectionQueryVariables
>;
export const CompanyValuesDocument = gql`
  query CompanyValues {
    company {
      data {
        attributes {
          values {
            ...values
          }
        }
      }
    }
  }
  ${ValuesFragmentDoc}
`;

/**
 * __useCompanyValuesQuery__
 *
 * To run a query within a React component, call `useCompanyValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyValuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyValuesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyValuesQuery,
    CompanyValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyValuesQuery, CompanyValuesQueryVariables>(
    CompanyValuesDocument,
    options,
  );
}
export function useCompanyValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyValuesQuery,
    CompanyValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyValuesQuery, CompanyValuesQueryVariables>(
    CompanyValuesDocument,
    options,
  );
}
export function useCompanyValuesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyValuesQuery,
        CompanyValuesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompanyValuesQuery,
    CompanyValuesQueryVariables
  >(CompanyValuesDocument, options);
}
export type CompanyValuesQueryHookResult = ReturnType<
  typeof useCompanyValuesQuery
>;
export type CompanyValuesLazyQueryHookResult = ReturnType<
  typeof useCompanyValuesLazyQuery
>;
export type CompanyValuesSuspenseQueryHookResult = ReturnType<
  typeof useCompanyValuesSuspenseQuery
>;
export type CompanyValuesQueryResult = Apollo.QueryResult<
  CompanyValuesQuery,
  CompanyValuesQueryVariables
>;
export const CompanyCampusDocument = gql`
  query CompanyCampus {
    company {
      data {
        attributes {
          Campus {
            id
            Text
            Title
            image {
              ...image
            }
            spec {
              ...spec
            }
          }
        }
      }
    }
    company {
      data {
        attributes {
          CampusAnchor
        }
      }
    }
  }
  ${ImageFragmentDoc}
  ${SpecFragmentDoc}
`;

/**
 * __useCompanyCampusQuery__
 *
 * To run a query within a React component, call `useCompanyCampusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyCampusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyCampusQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyCampusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyCampusQuery,
    CompanyCampusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyCampusQuery, CompanyCampusQueryVariables>(
    CompanyCampusDocument,
    options,
  );
}
export function useCompanyCampusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyCampusQuery,
    CompanyCampusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyCampusQuery, CompanyCampusQueryVariables>(
    CompanyCampusDocument,
    options,
  );
}
export function useCompanyCampusSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyCampusQuery,
        CompanyCampusQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompanyCampusQuery,
    CompanyCampusQueryVariables
  >(CompanyCampusDocument, options);
}
export type CompanyCampusQueryHookResult = ReturnType<
  typeof useCompanyCampusQuery
>;
export type CompanyCampusLazyQueryHookResult = ReturnType<
  typeof useCompanyCampusLazyQuery
>;
export type CompanyCampusSuspenseQueryHookResult = ReturnType<
  typeof useCompanyCampusSuspenseQuery
>;
export type CompanyCampusQueryResult = Apollo.QueryResult<
  CompanyCampusQuery,
  CompanyCampusQueryVariables
>;
export const CompanyFacilitiesDocument = gql`
  query CompanyFacilities {
    company {
      data {
        attributes {
          facilities {
            ...facilities
          }
        }
      }
    }
  }
  ${FacilitiesFragmentDoc}
`;

/**
 * __useCompanyFacilitiesQuery__
 *
 * To run a query within a React component, call `useCompanyFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyFacilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyFacilitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyFacilitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyFacilitiesQuery,
    CompanyFacilitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyFacilitiesQuery,
    CompanyFacilitiesQueryVariables
  >(CompanyFacilitiesDocument, options);
}
export function useCompanyFacilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyFacilitiesQuery,
    CompanyFacilitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyFacilitiesQuery,
    CompanyFacilitiesQueryVariables
  >(CompanyFacilitiesDocument, options);
}
export function useCompanyFacilitiesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyFacilitiesQuery,
        CompanyFacilitiesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompanyFacilitiesQuery,
    CompanyFacilitiesQueryVariables
  >(CompanyFacilitiesDocument, options);
}
export type CompanyFacilitiesQueryHookResult = ReturnType<
  typeof useCompanyFacilitiesQuery
>;
export type CompanyFacilitiesLazyQueryHookResult = ReturnType<
  typeof useCompanyFacilitiesLazyQuery
>;
export type CompanyFacilitiesSuspenseQueryHookResult = ReturnType<
  typeof useCompanyFacilitiesSuspenseQuery
>;
export type CompanyFacilitiesQueryResult = Apollo.QueryResult<
  CompanyFacilitiesQuery,
  CompanyFacilitiesQueryVariables
>;
export const CompanyTeamDocument = gql`
  query CompanyTeam {
    company {
      data {
        attributes {
          team {
            ...team
          }
        }
      }
    }
  }
  ${TeamFragmentDoc}
`;

/**
 * __useCompanyTeamQuery__
 *
 * To run a query within a React component, call `useCompanyTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyTeamQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyTeamQuery,
    CompanyTeamQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyTeamQuery, CompanyTeamQueryVariables>(
    CompanyTeamDocument,
    options,
  );
}
export function useCompanyTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyTeamQuery,
    CompanyTeamQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyTeamQuery, CompanyTeamQueryVariables>(
    CompanyTeamDocument,
    options,
  );
}
export function useCompanyTeamSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyTeamQuery,
        CompanyTeamQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanyTeamQuery, CompanyTeamQueryVariables>(
    CompanyTeamDocument,
    options,
  );
}
export type CompanyTeamQueryHookResult = ReturnType<typeof useCompanyTeamQuery>;
export type CompanyTeamLazyQueryHookResult = ReturnType<
  typeof useCompanyTeamLazyQuery
>;
export type CompanyTeamSuspenseQueryHookResult = ReturnType<
  typeof useCompanyTeamSuspenseQuery
>;
export type CompanyTeamQueryResult = Apollo.QueryResult<
  CompanyTeamQuery,
  CompanyTeamQueryVariables
>;
export const CompanyGuidelinesDocument = gql`
  query CompanyGuidelines {
    company {
      data {
        attributes {
          guidelines {
            ...guidelines
          }
        }
      }
    }
  }
  ${GuidelinesFragmentDoc}
`;

/**
 * __useCompanyGuidelinesQuery__
 *
 * To run a query within a React component, call `useCompanyGuidelinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyGuidelinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyGuidelinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyGuidelinesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyGuidelinesQuery,
    CompanyGuidelinesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyGuidelinesQuery,
    CompanyGuidelinesQueryVariables
  >(CompanyGuidelinesDocument, options);
}
export function useCompanyGuidelinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyGuidelinesQuery,
    CompanyGuidelinesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyGuidelinesQuery,
    CompanyGuidelinesQueryVariables
  >(CompanyGuidelinesDocument, options);
}
export function useCompanyGuidelinesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyGuidelinesQuery,
        CompanyGuidelinesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompanyGuidelinesQuery,
    CompanyGuidelinesQueryVariables
  >(CompanyGuidelinesDocument, options);
}
export type CompanyGuidelinesQueryHookResult = ReturnType<
  typeof useCompanyGuidelinesQuery
>;
export type CompanyGuidelinesLazyQueryHookResult = ReturnType<
  typeof useCompanyGuidelinesLazyQuery
>;
export type CompanyGuidelinesSuspenseQueryHookResult = ReturnType<
  typeof useCompanyGuidelinesSuspenseQuery
>;
export type CompanyGuidelinesQueryResult = Apollo.QueryResult<
  CompanyGuidelinesQuery,
  CompanyGuidelinesQueryVariables
>;
export const CompanyLargeImageDocument = gql`
  query CompanyLargeImage {
    company {
      data {
        attributes {
          largeImageSection {
            ...largeImageSection
          }
        }
      }
    }
  }
  ${LargeImageSectionFragmentDoc}
`;

/**
 * __useCompanyLargeImageQuery__
 *
 * To run a query within a React component, call `useCompanyLargeImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyLargeImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyLargeImageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyLargeImageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyLargeImageQuery,
    CompanyLargeImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyLargeImageQuery,
    CompanyLargeImageQueryVariables
  >(CompanyLargeImageDocument, options);
}
export function useCompanyLargeImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyLargeImageQuery,
    CompanyLargeImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyLargeImageQuery,
    CompanyLargeImageQueryVariables
  >(CompanyLargeImageDocument, options);
}
export function useCompanyLargeImageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyLargeImageQuery,
        CompanyLargeImageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompanyLargeImageQuery,
    CompanyLargeImageQueryVariables
  >(CompanyLargeImageDocument, options);
}
export type CompanyLargeImageQueryHookResult = ReturnType<
  typeof useCompanyLargeImageQuery
>;
export type CompanyLargeImageLazyQueryHookResult = ReturnType<
  typeof useCompanyLargeImageLazyQuery
>;
export type CompanyLargeImageSuspenseQueryHookResult = ReturnType<
  typeof useCompanyLargeImageSuspenseQuery
>;
export type CompanyLargeImageQueryResult = Apollo.QueryResult<
  CompanyLargeImageQuery,
  CompanyLargeImageQueryVariables
>;
export const CompanyNewsroomDocument = gql`
  query CompanyNewsroom {
    company {
      data {
        attributes {
          newsroom {
            ...newsroom
          }
        }
      }
    }
  }
  ${NewsroomFragmentDoc}
`;

/**
 * __useCompanyNewsroomQuery__
 *
 * To run a query within a React component, call `useCompanyNewsroomQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyNewsroomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyNewsroomQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyNewsroomQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyNewsroomQuery,
    CompanyNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyNewsroomQuery, CompanyNewsroomQueryVariables>(
    CompanyNewsroomDocument,
    options,
  );
}
export function useCompanyNewsroomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyNewsroomQuery,
    CompanyNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyNewsroomQuery,
    CompanyNewsroomQueryVariables
  >(CompanyNewsroomDocument, options);
}
export function useCompanyNewsroomSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyNewsroomQuery,
        CompanyNewsroomQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompanyNewsroomQuery,
    CompanyNewsroomQueryVariables
  >(CompanyNewsroomDocument, options);
}
export type CompanyNewsroomQueryHookResult = ReturnType<
  typeof useCompanyNewsroomQuery
>;
export type CompanyNewsroomLazyQueryHookResult = ReturnType<
  typeof useCompanyNewsroomLazyQuery
>;
export type CompanyNewsroomSuspenseQueryHookResult = ReturnType<
  typeof useCompanyNewsroomSuspenseQuery
>;
export type CompanyNewsroomQueryResult = Apollo.QueryResult<
  CompanyNewsroomQuery,
  CompanyNewsroomQueryVariables
>;
export const CompanySeoDocument = gql`
  query CompanySeo {
    company {
      data {
        id
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useCompanySeoQuery__
 *
 * To run a query within a React component, call `useCompanySeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanySeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanySeoQuery,
    CompanySeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanySeoQuery, CompanySeoQueryVariables>(
    CompanySeoDocument,
    options,
  );
}
export function useCompanySeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanySeoQuery,
    CompanySeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanySeoQuery, CompanySeoQueryVariables>(
    CompanySeoDocument,
    options,
  );
}
export function useCompanySeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanySeoQuery,
        CompanySeoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanySeoQuery, CompanySeoQueryVariables>(
    CompanySeoDocument,
    options,
  );
}
export type CompanySeoQueryHookResult = ReturnType<typeof useCompanySeoQuery>;
export type CompanySeoLazyQueryHookResult = ReturnType<
  typeof useCompanySeoLazyQuery
>;
export type CompanySeoSuspenseQueryHookResult = ReturnType<
  typeof useCompanySeoSuspenseQuery
>;
export type CompanySeoQueryResult = Apollo.QueryResult<
  CompanySeoQuery,
  CompanySeoQueryVariables
>;
export const ElectronicsHeroDocument = gql`
  query ElectronicsHero {
    electronic {
      data {
        id
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __useElectronicsHeroQuery__
 *
 * To run a query within a React component, call `useElectronicsHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useElectronicsHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useElectronicsHeroQuery({
 *   variables: {
 *   },
 * });
 */
export function useElectronicsHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ElectronicsHeroQuery,
    ElectronicsHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ElectronicsHeroQuery, ElectronicsHeroQueryVariables>(
    ElectronicsHeroDocument,
    options,
  );
}
export function useElectronicsHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ElectronicsHeroQuery,
    ElectronicsHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ElectronicsHeroQuery,
    ElectronicsHeroQueryVariables
  >(ElectronicsHeroDocument, options);
}
export function useElectronicsHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ElectronicsHeroQuery,
        ElectronicsHeroQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ElectronicsHeroQuery,
    ElectronicsHeroQueryVariables
  >(ElectronicsHeroDocument, options);
}
export type ElectronicsHeroQueryHookResult = ReturnType<
  typeof useElectronicsHeroQuery
>;
export type ElectronicsHeroLazyQueryHookResult = ReturnType<
  typeof useElectronicsHeroLazyQuery
>;
export type ElectronicsHeroSuspenseQueryHookResult = ReturnType<
  typeof useElectronicsHeroSuspenseQuery
>;
export type ElectronicsHeroQueryResult = Apollo.QueryResult<
  ElectronicsHeroQuery,
  ElectronicsHeroQueryVariables
>;
export const ElectronicsCardsSectionDocument = gql`
  query ElectronicsCardsSection {
    electronic {
      data {
        id
        attributes {
          cardsSection {
            ...cardsSection
          }
        }
      }
    }
  }
  ${CardsSectionFragmentDoc}
`;

/**
 * __useElectronicsCardsSectionQuery__
 *
 * To run a query within a React component, call `useElectronicsCardsSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useElectronicsCardsSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useElectronicsCardsSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useElectronicsCardsSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ElectronicsCardsSectionQuery,
    ElectronicsCardsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ElectronicsCardsSectionQuery,
    ElectronicsCardsSectionQueryVariables
  >(ElectronicsCardsSectionDocument, options);
}
export function useElectronicsCardsSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ElectronicsCardsSectionQuery,
    ElectronicsCardsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ElectronicsCardsSectionQuery,
    ElectronicsCardsSectionQueryVariables
  >(ElectronicsCardsSectionDocument, options);
}
export function useElectronicsCardsSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ElectronicsCardsSectionQuery,
        ElectronicsCardsSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ElectronicsCardsSectionQuery,
    ElectronicsCardsSectionQueryVariables
  >(ElectronicsCardsSectionDocument, options);
}
export type ElectronicsCardsSectionQueryHookResult = ReturnType<
  typeof useElectronicsCardsSectionQuery
>;
export type ElectronicsCardsSectionLazyQueryHookResult = ReturnType<
  typeof useElectronicsCardsSectionLazyQuery
>;
export type ElectronicsCardsSectionSuspenseQueryHookResult = ReturnType<
  typeof useElectronicsCardsSectionSuspenseQuery
>;
export type ElectronicsCardsSectionQueryResult = Apollo.QueryResult<
  ElectronicsCardsSectionQuery,
  ElectronicsCardsSectionQueryVariables
>;
export const ElectronicsProductImageBlockDocument = gql`
  query ElectronicsProductImageBlock {
    electronic {
      data {
        attributes {
          productImageBlock {
            ...productImageBlock
          }
        }
      }
    }
  }
  ${ProductImageBlockFragmentDoc}
`;

/**
 * __useElectronicsProductImageBlockQuery__
 *
 * To run a query within a React component, call `useElectronicsProductImageBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useElectronicsProductImageBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useElectronicsProductImageBlockQuery({
 *   variables: {
 *   },
 * });
 */
export function useElectronicsProductImageBlockQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ElectronicsProductImageBlockQuery,
    ElectronicsProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ElectronicsProductImageBlockQuery,
    ElectronicsProductImageBlockQueryVariables
  >(ElectronicsProductImageBlockDocument, options);
}
export function useElectronicsProductImageBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ElectronicsProductImageBlockQuery,
    ElectronicsProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ElectronicsProductImageBlockQuery,
    ElectronicsProductImageBlockQueryVariables
  >(ElectronicsProductImageBlockDocument, options);
}
export function useElectronicsProductImageBlockSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ElectronicsProductImageBlockQuery,
        ElectronicsProductImageBlockQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ElectronicsProductImageBlockQuery,
    ElectronicsProductImageBlockQueryVariables
  >(ElectronicsProductImageBlockDocument, options);
}
export type ElectronicsProductImageBlockQueryHookResult = ReturnType<
  typeof useElectronicsProductImageBlockQuery
>;
export type ElectronicsProductImageBlockLazyQueryHookResult = ReturnType<
  typeof useElectronicsProductImageBlockLazyQuery
>;
export type ElectronicsProductImageBlockSuspenseQueryHookResult = ReturnType<
  typeof useElectronicsProductImageBlockSuspenseQuery
>;
export type ElectronicsProductImageBlockQueryResult = Apollo.QueryResult<
  ElectronicsProductImageBlockQuery,
  ElectronicsProductImageBlockQueryVariables
>;
export const ElectronicsTwoImagesGridDocument = gql`
  query ElectronicsTwoImagesGrid {
    electronic {
      data {
        attributes {
          twoImagesGrid {
            ...twoImagesGrid
          }
        }
      }
    }
  }
  ${TwoImagesGridFragmentDoc}
`;

/**
 * __useElectronicsTwoImagesGridQuery__
 *
 * To run a query within a React component, call `useElectronicsTwoImagesGridQuery` and pass it any options that fit your needs.
 * When your component renders, `useElectronicsTwoImagesGridQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useElectronicsTwoImagesGridQuery({
 *   variables: {
 *   },
 * });
 */
export function useElectronicsTwoImagesGridQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ElectronicsTwoImagesGridQuery,
    ElectronicsTwoImagesGridQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ElectronicsTwoImagesGridQuery,
    ElectronicsTwoImagesGridQueryVariables
  >(ElectronicsTwoImagesGridDocument, options);
}
export function useElectronicsTwoImagesGridLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ElectronicsTwoImagesGridQuery,
    ElectronicsTwoImagesGridQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ElectronicsTwoImagesGridQuery,
    ElectronicsTwoImagesGridQueryVariables
  >(ElectronicsTwoImagesGridDocument, options);
}
export function useElectronicsTwoImagesGridSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ElectronicsTwoImagesGridQuery,
        ElectronicsTwoImagesGridQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ElectronicsTwoImagesGridQuery,
    ElectronicsTwoImagesGridQueryVariables
  >(ElectronicsTwoImagesGridDocument, options);
}
export type ElectronicsTwoImagesGridQueryHookResult = ReturnType<
  typeof useElectronicsTwoImagesGridQuery
>;
export type ElectronicsTwoImagesGridLazyQueryHookResult = ReturnType<
  typeof useElectronicsTwoImagesGridLazyQuery
>;
export type ElectronicsTwoImagesGridSuspenseQueryHookResult = ReturnType<
  typeof useElectronicsTwoImagesGridSuspenseQuery
>;
export type ElectronicsTwoImagesGridQueryResult = Apollo.QueryResult<
  ElectronicsTwoImagesGridQuery,
  ElectronicsTwoImagesGridQueryVariables
>;
export const ElectronicsSliderImageSectionDocument = gql`
  query ElectronicsSliderImageSection {
    electronic {
      data {
        id
        attributes {
          sliderImageSection {
            ...sliderImageSection
          }
        }
      }
    }
  }
  ${SliderImageSectionFragmentDoc}
`;

/**
 * __useElectronicsSliderImageSectionQuery__
 *
 * To run a query within a React component, call `useElectronicsSliderImageSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useElectronicsSliderImageSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useElectronicsSliderImageSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useElectronicsSliderImageSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ElectronicsSliderImageSectionQuery,
    ElectronicsSliderImageSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ElectronicsSliderImageSectionQuery,
    ElectronicsSliderImageSectionQueryVariables
  >(ElectronicsSliderImageSectionDocument, options);
}
export function useElectronicsSliderImageSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ElectronicsSliderImageSectionQuery,
    ElectronicsSliderImageSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ElectronicsSliderImageSectionQuery,
    ElectronicsSliderImageSectionQueryVariables
  >(ElectronicsSliderImageSectionDocument, options);
}
export function useElectronicsSliderImageSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ElectronicsSliderImageSectionQuery,
        ElectronicsSliderImageSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ElectronicsSliderImageSectionQuery,
    ElectronicsSliderImageSectionQueryVariables
  >(ElectronicsSliderImageSectionDocument, options);
}
export type ElectronicsSliderImageSectionQueryHookResult = ReturnType<
  typeof useElectronicsSliderImageSectionQuery
>;
export type ElectronicsSliderImageSectionLazyQueryHookResult = ReturnType<
  typeof useElectronicsSliderImageSectionLazyQuery
>;
export type ElectronicsSliderImageSectionSuspenseQueryHookResult = ReturnType<
  typeof useElectronicsSliderImageSectionSuspenseQuery
>;
export type ElectronicsSliderImageSectionQueryResult = Apollo.QueryResult<
  ElectronicsSliderImageSectionQuery,
  ElectronicsSliderImageSectionQueryVariables
>;
export const ElectronicsNewsroomDocument = gql`
  query ElectronicsNewsroom {
    electronic {
      data {
        attributes {
          newsroom {
            ...newsroom
          }
        }
      }
    }
  }
  ${NewsroomFragmentDoc}
`;

/**
 * __useElectronicsNewsroomQuery__
 *
 * To run a query within a React component, call `useElectronicsNewsroomQuery` and pass it any options that fit your needs.
 * When your component renders, `useElectronicsNewsroomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useElectronicsNewsroomQuery({
 *   variables: {
 *   },
 * });
 */
export function useElectronicsNewsroomQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ElectronicsNewsroomQuery,
    ElectronicsNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ElectronicsNewsroomQuery,
    ElectronicsNewsroomQueryVariables
  >(ElectronicsNewsroomDocument, options);
}
export function useElectronicsNewsroomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ElectronicsNewsroomQuery,
    ElectronicsNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ElectronicsNewsroomQuery,
    ElectronicsNewsroomQueryVariables
  >(ElectronicsNewsroomDocument, options);
}
export function useElectronicsNewsroomSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ElectronicsNewsroomQuery,
        ElectronicsNewsroomQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ElectronicsNewsroomQuery,
    ElectronicsNewsroomQueryVariables
  >(ElectronicsNewsroomDocument, options);
}
export type ElectronicsNewsroomQueryHookResult = ReturnType<
  typeof useElectronicsNewsroomQuery
>;
export type ElectronicsNewsroomLazyQueryHookResult = ReturnType<
  typeof useElectronicsNewsroomLazyQuery
>;
export type ElectronicsNewsroomSuspenseQueryHookResult = ReturnType<
  typeof useElectronicsNewsroomSuspenseQuery
>;
export type ElectronicsNewsroomQueryResult = Apollo.QueryResult<
  ElectronicsNewsroomQuery,
  ElectronicsNewsroomQueryVariables
>;
export const ElectronicsSeoDocument = gql`
  query ElectronicsSeo {
    electronic {
      data {
        id
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useElectronicsSeoQuery__
 *
 * To run a query within a React component, call `useElectronicsSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useElectronicsSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useElectronicsSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useElectronicsSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ElectronicsSeoQuery,
    ElectronicsSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ElectronicsSeoQuery, ElectronicsSeoQueryVariables>(
    ElectronicsSeoDocument,
    options,
  );
}
export function useElectronicsSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ElectronicsSeoQuery,
    ElectronicsSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ElectronicsSeoQuery, ElectronicsSeoQueryVariables>(
    ElectronicsSeoDocument,
    options,
  );
}
export function useElectronicsSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ElectronicsSeoQuery,
        ElectronicsSeoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ElectronicsSeoQuery,
    ElectronicsSeoQueryVariables
  >(ElectronicsSeoDocument, options);
}
export type ElectronicsSeoQueryHookResult = ReturnType<
  typeof useElectronicsSeoQuery
>;
export type ElectronicsSeoLazyQueryHookResult = ReturnType<
  typeof useElectronicsSeoLazyQuery
>;
export type ElectronicsSeoSuspenseQueryHookResult = ReturnType<
  typeof useElectronicsSeoSuspenseQuery
>;
export type ElectronicsSeoQueryResult = Apollo.QueryResult<
  ElectronicsSeoQuery,
  ElectronicsSeoQueryVariables
>;
export const SendContactFormDocument = gql`
  mutation SendContactForm($data: ContactFormDataInput!) {
    sendContactForm(data: $data) {
      success
    }
  }
`;
export type SendContactFormMutationFn = Apollo.MutationFunction<
  SendContactFormMutation,
  SendContactFormMutationVariables
>;

/**
 * __useSendContactFormMutation__
 *
 * To run a mutation, you first call `useSendContactFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContactFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContactFormMutation, { data, loading, error }] = useSendContactFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendContactFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendContactFormMutation,
    SendContactFormMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendContactFormMutation,
    SendContactFormMutationVariables
  >(SendContactFormDocument, options);
}
export type SendContactFormMutationHookResult = ReturnType<
  typeof useSendContactFormMutation
>;
export type SendContactFormMutationResult =
  Apollo.MutationResult<SendContactFormMutation>;
export type SendContactFormMutationOptions = Apollo.BaseMutationOptions<
  SendContactFormMutation,
  SendContactFormMutationVariables
>;
export const HomeHeroDocument = gql`
  query HomeHero {
    home {
      data {
        id
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __useHomeHeroQuery__
 *
 * To run a query within a React component, call `useHomeHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeHeroQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<HomeHeroQuery, HomeHeroQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeHeroQuery, HomeHeroQueryVariables>(
    HomeHeroDocument,
    options,
  );
}
export function useHomeHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeHeroQuery,
    HomeHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeHeroQuery, HomeHeroQueryVariables>(
    HomeHeroDocument,
    options,
  );
}
export function useHomeHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<HomeHeroQuery, HomeHeroQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HomeHeroQuery, HomeHeroQueryVariables>(
    HomeHeroDocument,
    options,
  );
}
export type HomeHeroQueryHookResult = ReturnType<typeof useHomeHeroQuery>;
export type HomeHeroLazyQueryHookResult = ReturnType<
  typeof useHomeHeroLazyQuery
>;
export type HomeHeroSuspenseQueryHookResult = ReturnType<
  typeof useHomeHeroSuspenseQuery
>;
export type HomeHeroQueryResult = Apollo.QueryResult<
  HomeHeroQuery,
  HomeHeroQueryVariables
>;
export const HomeCardsWithLinkDocument = gql`
  query HomeCardsWithLink {
    home {
      data {
        id
        attributes {
          imageCardsWithLink {
            ...imageCardsWithLink
          }
        }
      }
    }
  }
  ${ImageCardsWithLinkFragmentDoc}
`;

/**
 * __useHomeCardsWithLinkQuery__
 *
 * To run a query within a React component, call `useHomeCardsWithLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeCardsWithLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeCardsWithLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeCardsWithLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeCardsWithLinkQuery,
    HomeCardsWithLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HomeCardsWithLinkQuery,
    HomeCardsWithLinkQueryVariables
  >(HomeCardsWithLinkDocument, options);
}
export function useHomeCardsWithLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeCardsWithLinkQuery,
    HomeCardsWithLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HomeCardsWithLinkQuery,
    HomeCardsWithLinkQueryVariables
  >(HomeCardsWithLinkDocument, options);
}
export function useHomeCardsWithLinkSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HomeCardsWithLinkQuery,
        HomeCardsWithLinkQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HomeCardsWithLinkQuery,
    HomeCardsWithLinkQueryVariables
  >(HomeCardsWithLinkDocument, options);
}
export type HomeCardsWithLinkQueryHookResult = ReturnType<
  typeof useHomeCardsWithLinkQuery
>;
export type HomeCardsWithLinkLazyQueryHookResult = ReturnType<
  typeof useHomeCardsWithLinkLazyQuery
>;
export type HomeCardsWithLinkSuspenseQueryHookResult = ReturnType<
  typeof useHomeCardsWithLinkSuspenseQuery
>;
export type HomeCardsWithLinkQueryResult = Apollo.QueryResult<
  HomeCardsWithLinkQuery,
  HomeCardsWithLinkQueryVariables
>;
export const HomeLargeImageDocument = gql`
  query HomeLargeImage {
    home {
      data {
        attributes {
          largeImageSection {
            ...largeImageSection
          }
        }
      }
    }
  }
  ${LargeImageSectionFragmentDoc}
`;

/**
 * __useHomeLargeImageQuery__
 *
 * To run a query within a React component, call `useHomeLargeImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeLargeImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeLargeImageQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeLargeImageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeLargeImageQuery,
    HomeLargeImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeLargeImageQuery, HomeLargeImageQueryVariables>(
    HomeLargeImageDocument,
    options,
  );
}
export function useHomeLargeImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeLargeImageQuery,
    HomeLargeImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeLargeImageQuery, HomeLargeImageQueryVariables>(
    HomeLargeImageDocument,
    options,
  );
}
export function useHomeLargeImageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HomeLargeImageQuery,
        HomeLargeImageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HomeLargeImageQuery,
    HomeLargeImageQueryVariables
  >(HomeLargeImageDocument, options);
}
export type HomeLargeImageQueryHookResult = ReturnType<
  typeof useHomeLargeImageQuery
>;
export type HomeLargeImageLazyQueryHookResult = ReturnType<
  typeof useHomeLargeImageLazyQuery
>;
export type HomeLargeImageSuspenseQueryHookResult = ReturnType<
  typeof useHomeLargeImageSuspenseQuery
>;
export type HomeLargeImageQueryResult = Apollo.QueryResult<
  HomeLargeImageQuery,
  HomeLargeImageQueryVariables
>;
export const HomeNewsroomDocument = gql`
  query HomeNewsroom {
    home {
      data {
        attributes {
          newsroom {
            ...newsroom
          }
        }
      }
    }
  }
  ${NewsroomFragmentDoc}
`;

/**
 * __useHomeNewsroomQuery__
 *
 * To run a query within a React component, call `useHomeNewsroomQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeNewsroomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeNewsroomQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeNewsroomQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeNewsroomQuery,
    HomeNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeNewsroomQuery, HomeNewsroomQueryVariables>(
    HomeNewsroomDocument,
    options,
  );
}
export function useHomeNewsroomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeNewsroomQuery,
    HomeNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeNewsroomQuery, HomeNewsroomQueryVariables>(
    HomeNewsroomDocument,
    options,
  );
}
export function useHomeNewsroomSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HomeNewsroomQuery,
        HomeNewsroomQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HomeNewsroomQuery, HomeNewsroomQueryVariables>(
    HomeNewsroomDocument,
    options,
  );
}
export type HomeNewsroomQueryHookResult = ReturnType<
  typeof useHomeNewsroomQuery
>;
export type HomeNewsroomLazyQueryHookResult = ReturnType<
  typeof useHomeNewsroomLazyQuery
>;
export type HomeNewsroomSuspenseQueryHookResult = ReturnType<
  typeof useHomeNewsroomSuspenseQuery
>;
export type HomeNewsroomQueryResult = Apollo.QueryResult<
  HomeNewsroomQuery,
  HomeNewsroomQueryVariables
>;
export const HomeSeoDocument = gql`
  query homeSeo {
    home {
      data {
        id
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useHomeSeoQuery__
 *
 * To run a query within a React component, call `useHomeSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<HomeSeoQuery, HomeSeoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeSeoQuery, HomeSeoQueryVariables>(
    HomeSeoDocument,
    options,
  );
}
export function useHomeSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeSeoQuery,
    HomeSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeSeoQuery, HomeSeoQueryVariables>(
    HomeSeoDocument,
    options,
  );
}
export function useHomeSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<HomeSeoQuery, HomeSeoQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HomeSeoQuery, HomeSeoQueryVariables>(
    HomeSeoDocument,
    options,
  );
}
export type HomeSeoQueryHookResult = ReturnType<typeof useHomeSeoQuery>;
export type HomeSeoLazyQueryHookResult = ReturnType<typeof useHomeSeoLazyQuery>;
export type HomeSeoSuspenseQueryHookResult = ReturnType<
  typeof useHomeSeoSuspenseQuery
>;
export type HomeSeoQueryResult = Apollo.QueryResult<
  HomeSeoQuery,
  HomeSeoQueryVariables
>;
export const FooterQueryDocument = gql`
  query FooterQuery {
    footer {
      data {
        id
        attributes {
          column {
            label
            link {
              label
              path
            }
          }
          policies {
            link {
              label
              path
            }
            label
          }
          socials {
            label
            instagram
            linkedin
            tiktok
            youtube
          }
        }
      }
    }
  }
`;

/**
 * __useFooterQueryQuery__
 *
 * To run a query within a React component, call `useFooterQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFooterQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFooterQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFooterQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FooterQueryQuery,
    FooterQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FooterQueryQuery, FooterQueryQueryVariables>(
    FooterQueryDocument,
    options,
  );
}
export function useFooterQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FooterQueryQuery,
    FooterQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FooterQueryQuery, FooterQueryQueryVariables>(
    FooterQueryDocument,
    options,
  );
}
export function useFooterQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FooterQueryQuery,
        FooterQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FooterQueryQuery, FooterQueryQueryVariables>(
    FooterQueryDocument,
    options,
  );
}
export type FooterQueryQueryHookResult = ReturnType<typeof useFooterQueryQuery>;
export type FooterQueryLazyQueryHookResult = ReturnType<
  typeof useFooterQueryLazyQuery
>;
export type FooterQuerySuspenseQueryHookResult = ReturnType<
  typeof useFooterQuerySuspenseQuery
>;
export type FooterQueryQueryResult = Apollo.QueryResult<
  FooterQueryQuery,
  FooterQueryQueryVariables
>;
export const NavigationDocument = gql`
  query Navigation {
    navigation {
      data {
        id
        attributes {
          contact {
            label
            path
            instagram
            linkedin
            tiktok
            youtube
          }
          navigation {
            openMenuLinks {
              label
              path
              sub_link {
                data {
                  attributes {
                    link {
                      path
                      label
                    }
                  }
                }
              }
            }
            closedMenuLinks {
              label
              path
            }
          }
        }
      }
    }
  }
`;

/**
 * __useNavigationQuery__
 *
 * To run a query within a React component, call `useNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavigationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NavigationQuery,
    NavigationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    options,
  );
}
export function useNavigationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NavigationQuery,
    NavigationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    options,
  );
}
export function useNavigationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        NavigationQuery,
        NavigationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    options,
  );
}
export type NavigationQueryHookResult = ReturnType<typeof useNavigationQuery>;
export type NavigationLazyQueryHookResult = ReturnType<
  typeof useNavigationLazyQuery
>;
export type NavigationSuspenseQueryHookResult = ReturnType<
  typeof useNavigationSuspenseQuery
>;
export type NavigationQueryResult = Apollo.QueryResult<
  NavigationQuery,
  NavigationQueryVariables
>;
export const FormQueryDocument = gql`
  query FormQuery {
    getInTouch {
      data {
        id
        attributes {
          anchor
          title
          first_line
          second_line
          options {
            label
            value
          }
        }
      }
    }
  }
`;

/**
 * __useFormQueryQuery__
 *
 * To run a query within a React component, call `useFormQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FormQueryQuery,
    FormQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FormQueryQuery, FormQueryQueryVariables>(
    FormQueryDocument,
    options,
  );
}
export function useFormQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FormQueryQuery,
    FormQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FormQueryQuery, FormQueryQueryVariables>(
    FormQueryDocument,
    options,
  );
}
export function useFormQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FormQueryQuery, FormQueryQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FormQueryQuery, FormQueryQueryVariables>(
    FormQueryDocument,
    options,
  );
}
export type FormQueryQueryHookResult = ReturnType<typeof useFormQueryQuery>;
export type FormQueryLazyQueryHookResult = ReturnType<
  typeof useFormQueryLazyQuery
>;
export type FormQuerySuspenseQueryHookResult = ReturnType<
  typeof useFormQuerySuspenseQuery
>;
export type FormQueryQueryResult = Apollo.QueryResult<
  FormQueryQuery,
  FormQueryQueryVariables
>;
export const PrivacyPolicyDocument = gql`
  query PrivacyPolicy {
    privacyPolicy {
      data {
        attributes {
          policies {
            ...PolicyFields
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
`;

/**
 * __usePrivacyPolicyQuery__
 *
 * To run a query within a React component, call `usePrivacyPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivacyPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivacyPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrivacyPolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PrivacyPolicyQuery,
    PrivacyPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(
    PrivacyPolicyDocument,
    options,
  );
}
export function usePrivacyPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PrivacyPolicyQuery,
    PrivacyPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(
    PrivacyPolicyDocument,
    options,
  );
}
export function usePrivacyPolicySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PrivacyPolicyQuery,
        PrivacyPolicyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PrivacyPolicyQuery,
    PrivacyPolicyQueryVariables
  >(PrivacyPolicyDocument, options);
}
export type PrivacyPolicyQueryHookResult = ReturnType<
  typeof usePrivacyPolicyQuery
>;
export type PrivacyPolicyLazyQueryHookResult = ReturnType<
  typeof usePrivacyPolicyLazyQuery
>;
export type PrivacyPolicySuspenseQueryHookResult = ReturnType<
  typeof usePrivacyPolicySuspenseQuery
>;
export type PrivacyPolicyQueryResult = Apollo.QueryResult<
  PrivacyPolicyQuery,
  PrivacyPolicyQueryVariables
>;
export const EnvironmentalPolicyDocument = gql`
  query EnvironmentalPolicy {
    environmentalPolicy {
      data {
        attributes {
          policies {
            ... on EnvironmentalPolicyPoliciesDynamicZone {
              ...PolicyFields
            }
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
`;

/**
 * __useEnvironmentalPolicyQuery__
 *
 * To run a query within a React component, call `useEnvironmentalPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentalPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentalPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function useEnvironmentalPolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EnvironmentalPolicyQuery,
    EnvironmentalPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EnvironmentalPolicyQuery,
    EnvironmentalPolicyQueryVariables
  >(EnvironmentalPolicyDocument, options);
}
export function useEnvironmentalPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EnvironmentalPolicyQuery,
    EnvironmentalPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EnvironmentalPolicyQuery,
    EnvironmentalPolicyQueryVariables
  >(EnvironmentalPolicyDocument, options);
}
export function useEnvironmentalPolicySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        EnvironmentalPolicyQuery,
        EnvironmentalPolicyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EnvironmentalPolicyQuery,
    EnvironmentalPolicyQueryVariables
  >(EnvironmentalPolicyDocument, options);
}
export type EnvironmentalPolicyQueryHookResult = ReturnType<
  typeof useEnvironmentalPolicyQuery
>;
export type EnvironmentalPolicyLazyQueryHookResult = ReturnType<
  typeof useEnvironmentalPolicyLazyQuery
>;
export type EnvironmentalPolicySuspenseQueryHookResult = ReturnType<
  typeof useEnvironmentalPolicySuspenseQuery
>;
export type EnvironmentalPolicyQueryResult = Apollo.QueryResult<
  EnvironmentalPolicyQuery,
  EnvironmentalPolicyQueryVariables
>;
export const CookiePolicyDocument = gql`
  query CookiePolicy {
    cookiePolicy {
      data {
        attributes {
          cookiePolicy {
            ... on CookiePolicyCookiePolicyDynamicZone {
              ...PolicyFields
            }
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
`;

/**
 * __useCookiePolicyQuery__
 *
 * To run a query within a React component, call `useCookiePolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCookiePolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCookiePolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function useCookiePolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CookiePolicyQuery,
    CookiePolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CookiePolicyQuery, CookiePolicyQueryVariables>(
    CookiePolicyDocument,
    options,
  );
}
export function useCookiePolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CookiePolicyQuery,
    CookiePolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CookiePolicyQuery, CookiePolicyQueryVariables>(
    CookiePolicyDocument,
    options,
  );
}
export function useCookiePolicySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CookiePolicyQuery,
        CookiePolicyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CookiePolicyQuery, CookiePolicyQueryVariables>(
    CookiePolicyDocument,
    options,
  );
}
export type CookiePolicyQueryHookResult = ReturnType<
  typeof useCookiePolicyQuery
>;
export type CookiePolicyLazyQueryHookResult = ReturnType<
  typeof useCookiePolicyLazyQuery
>;
export type CookiePolicySuspenseQueryHookResult = ReturnType<
  typeof useCookiePolicySuspenseQuery
>;
export type CookiePolicyQueryResult = Apollo.QueryResult<
  CookiePolicyQuery,
  CookiePolicyQueryVariables
>;
export const TermsAndConditionsDocument = gql`
  query TermsAndConditions {
    termsAndCondition {
      data {
        attributes {
          policies {
            ... on TermsAndConditionPoliciesDynamicZone {
              ...PolicyFields
            }
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
`;

/**
 * __useTermsAndConditionsQuery__
 *
 * To run a query within a React component, call `useTermsAndConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermsAndConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermsAndConditionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTermsAndConditionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TermsAndConditionsQuery,
    TermsAndConditionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TermsAndConditionsQuery,
    TermsAndConditionsQueryVariables
  >(TermsAndConditionsDocument, options);
}
export function useTermsAndConditionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TermsAndConditionsQuery,
    TermsAndConditionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TermsAndConditionsQuery,
    TermsAndConditionsQueryVariables
  >(TermsAndConditionsDocument, options);
}
export function useTermsAndConditionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TermsAndConditionsQuery,
        TermsAndConditionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    TermsAndConditionsQuery,
    TermsAndConditionsQueryVariables
  >(TermsAndConditionsDocument, options);
}
export type TermsAndConditionsQueryHookResult = ReturnType<
  typeof useTermsAndConditionsQuery
>;
export type TermsAndConditionsLazyQueryHookResult = ReturnType<
  typeof useTermsAndConditionsLazyQuery
>;
export type TermsAndConditionsSuspenseQueryHookResult = ReturnType<
  typeof useTermsAndConditionsSuspenseQuery
>;
export type TermsAndConditionsQueryResult = Apollo.QueryResult<
  TermsAndConditionsQuery,
  TermsAndConditionsQueryVariables
>;
export const IriPageDocument = gql`
  query IriPage {
    iriPage {
      data {
        attributes {
          contents {
            ... on IriPageContentsDynamicZone {
              ...PolicyFields
            }
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
`;

/**
 * __useIriPageQuery__
 *
 * To run a query within a React component, call `useIriPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useIriPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIriPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useIriPageQuery(
  baseOptions?: Apollo.QueryHookOptions<IriPageQuery, IriPageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IriPageQuery, IriPageQueryVariables>(
    IriPageDocument,
    options,
  );
}
export function useIriPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IriPageQuery,
    IriPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IriPageQuery, IriPageQueryVariables>(
    IriPageDocument,
    options,
  );
}
export function useIriPageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IriPageQuery, IriPageQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IriPageQuery, IriPageQueryVariables>(
    IriPageDocument,
    options,
  );
}
export type IriPageQueryHookResult = ReturnType<typeof useIriPageQuery>;
export type IriPageLazyQueryHookResult = ReturnType<typeof useIriPageLazyQuery>;
export type IriPageSuspenseQueryHookResult = ReturnType<
  typeof useIriPageSuspenseQuery
>;
export type IriPageQueryResult = Apollo.QueryResult<
  IriPageQuery,
  IriPageQueryVariables
>;
export const WhistleblowingPolicyDocument = gql`
  query WhistleblowingPolicy {
    whistleblowingPolicy {
      data {
        attributes {
          policies {
            ... on WhistleblowingPolicyPoliciesDynamicZone {
              ...PolicyFields
            }
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
`;

/**
 * __useWhistleblowingPolicyQuery__
 *
 * To run a query within a React component, call `useWhistleblowingPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhistleblowingPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhistleblowingPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhistleblowingPolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >(WhistleblowingPolicyDocument, options);
}
export function useWhistleblowingPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >(WhistleblowingPolicyDocument, options);
}
export function useWhistleblowingPolicySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WhistleblowingPolicyQuery,
        WhistleblowingPolicyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >(WhistleblowingPolicyDocument, options);
}
export type WhistleblowingPolicyQueryHookResult = ReturnType<
  typeof useWhistleblowingPolicyQuery
>;
export type WhistleblowingPolicyLazyQueryHookResult = ReturnType<
  typeof useWhistleblowingPolicyLazyQuery
>;
export type WhistleblowingPolicySuspenseQueryHookResult = ReturnType<
  typeof useWhistleblowingPolicySuspenseQuery
>;
export type WhistleblowingPolicyQueryResult = Apollo.QueryResult<
  WhistleblowingPolicyQuery,
  WhistleblowingPolicyQueryVariables
>;
export const CookiesSettingsDocument = gql`
  query CookiesSettings {
    cookieSetting {
      data {
        id
        attributes {
          actionButtonLabel
          necessaryCookiesHeading
          necessaryCookiesTable {
            richText
            tableHead {
              headingItem
            }
            tableRow {
              table_rows {
                data {
                  attributes {
                    rows {
                      item
                    }
                  }
                }
              }
            }
          }
          optionalCookiesHeading
          optionalCookiesTale {
            richText
            tableHead {
              headingItem
            }
            tableRow {
              table_rows {
                data {
                  attributes {
                    rows {
                      item
                    }
                  }
                }
              }
            }
          }
          title
          redirectButton {
            label
            path
          }
        }
      }
    }
  }
`;

/**
 * __useCookiesSettingsQuery__
 *
 * To run a query within a React component, call `useCookiesSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCookiesSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCookiesSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCookiesSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CookiesSettingsQuery, CookiesSettingsQueryVariables>(
    CookiesSettingsDocument,
    options,
  );
}
export function useCookiesSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >(CookiesSettingsDocument, options);
}
export function useCookiesSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CookiesSettingsQuery,
        CookiesSettingsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >(CookiesSettingsDocument, options);
}
export type CookiesSettingsQueryHookResult = ReturnType<
  typeof useCookiesSettingsQuery
>;
export type CookiesSettingsLazyQueryHookResult = ReturnType<
  typeof useCookiesSettingsLazyQuery
>;
export type CookiesSettingsSuspenseQueryHookResult = ReturnType<
  typeof useCookiesSettingsSuspenseQuery
>;
export type CookiesSettingsQueryResult = Apollo.QueryResult<
  CookiesSettingsQuery,
  CookiesSettingsQueryVariables
>;
export const CookiesBarDocument = gql`
  query CookiesBar {
    cookiesBanner {
      data {
        id
        attributes {
          header
          text
          policyButton {
            label
            path
          }
          actionButtonLabel
        }
      }
    }
  }
`;

/**
 * __useCookiesBarQuery__
 *
 * To run a query within a React component, call `useCookiesBarQuery` and pass it any options that fit your needs.
 * When your component renders, `useCookiesBarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCookiesBarQuery({
 *   variables: {
 *   },
 * });
 */
export function useCookiesBarQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CookiesBarQuery,
    CookiesBarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CookiesBarQuery, CookiesBarQueryVariables>(
    CookiesBarDocument,
    options,
  );
}
export function useCookiesBarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CookiesBarQuery,
    CookiesBarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CookiesBarQuery, CookiesBarQueryVariables>(
    CookiesBarDocument,
    options,
  );
}
export function useCookiesBarSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CookiesBarQuery,
        CookiesBarQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CookiesBarQuery, CookiesBarQueryVariables>(
    CookiesBarDocument,
    options,
  );
}
export type CookiesBarQueryHookResult = ReturnType<typeof useCookiesBarQuery>;
export type CookiesBarLazyQueryHookResult = ReturnType<
  typeof useCookiesBarLazyQuery
>;
export type CookiesBarSuspenseQueryHookResult = ReturnType<
  typeof useCookiesBarSuspenseQuery
>;
export type CookiesBarQueryResult = Apollo.QueryResult<
  CookiesBarQuery,
  CookiesBarQueryVariables
>;
export const PowertrainHeroDocument = gql`
  query PowertrainHero {
    powertrain {
      data {
        id
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __usePowertrainHeroQuery__
 *
 * To run a query within a React component, call `usePowertrainHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowertrainHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowertrainHeroQuery({
 *   variables: {
 *   },
 * });
 */
export function usePowertrainHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PowertrainHeroQuery,
    PowertrainHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PowertrainHeroQuery, PowertrainHeroQueryVariables>(
    PowertrainHeroDocument,
    options,
  );
}
export function usePowertrainHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PowertrainHeroQuery,
    PowertrainHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PowertrainHeroQuery, PowertrainHeroQueryVariables>(
    PowertrainHeroDocument,
    options,
  );
}
export function usePowertrainHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PowertrainHeroQuery,
        PowertrainHeroQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PowertrainHeroQuery,
    PowertrainHeroQueryVariables
  >(PowertrainHeroDocument, options);
}
export type PowertrainHeroQueryHookResult = ReturnType<
  typeof usePowertrainHeroQuery
>;
export type PowertrainHeroLazyQueryHookResult = ReturnType<
  typeof usePowertrainHeroLazyQuery
>;
export type PowertrainHeroSuspenseQueryHookResult = ReturnType<
  typeof usePowertrainHeroSuspenseQuery
>;
export type PowertrainHeroQueryResult = Apollo.QueryResult<
  PowertrainHeroQuery,
  PowertrainHeroQueryVariables
>;
export const PowertrainCardsSectionDocument = gql`
  query PowertrainCardsSection {
    powertrain {
      data {
        id
        attributes {
          cardsSection {
            id
            title
            subtitle
            iconCard {
              ...iconCard
            }
          }
        }
      }
    }
  }
  ${IconCardFragmentDoc}
`;

/**
 * __usePowertrainCardsSectionQuery__
 *
 * To run a query within a React component, call `usePowertrainCardsSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowertrainCardsSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowertrainCardsSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function usePowertrainCardsSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PowertrainCardsSectionQuery,
    PowertrainCardsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PowertrainCardsSectionQuery,
    PowertrainCardsSectionQueryVariables
  >(PowertrainCardsSectionDocument, options);
}
export function usePowertrainCardsSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PowertrainCardsSectionQuery,
    PowertrainCardsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PowertrainCardsSectionQuery,
    PowertrainCardsSectionQueryVariables
  >(PowertrainCardsSectionDocument, options);
}
export function usePowertrainCardsSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PowertrainCardsSectionQuery,
        PowertrainCardsSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PowertrainCardsSectionQuery,
    PowertrainCardsSectionQueryVariables
  >(PowertrainCardsSectionDocument, options);
}
export type PowertrainCardsSectionQueryHookResult = ReturnType<
  typeof usePowertrainCardsSectionQuery
>;
export type PowertrainCardsSectionLazyQueryHookResult = ReturnType<
  typeof usePowertrainCardsSectionLazyQuery
>;
export type PowertrainCardsSectionSuspenseQueryHookResult = ReturnType<
  typeof usePowertrainCardsSectionSuspenseQuery
>;
export type PowertrainCardsSectionQueryResult = Apollo.QueryResult<
  PowertrainCardsSectionQuery,
  PowertrainCardsSectionQueryVariables
>;
export const PowertrainSpecsDocument = gql`
  query PowertrainSpecs {
    powertrain {
      data {
        id
        attributes {
          technologies {
            ...powertrainSpecs
          }
        }
      }
    }
  }
  ${PowertrainSpecsFragmentDoc}
`;

/**
 * __usePowertrainSpecsQuery__
 *
 * To run a query within a React component, call `usePowertrainSpecsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowertrainSpecsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowertrainSpecsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePowertrainSpecsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PowertrainSpecsQuery,
    PowertrainSpecsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PowertrainSpecsQuery, PowertrainSpecsQueryVariables>(
    PowertrainSpecsDocument,
    options,
  );
}
export function usePowertrainSpecsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PowertrainSpecsQuery,
    PowertrainSpecsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PowertrainSpecsQuery,
    PowertrainSpecsQueryVariables
  >(PowertrainSpecsDocument, options);
}
export function usePowertrainSpecsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PowertrainSpecsQuery,
        PowertrainSpecsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PowertrainSpecsQuery,
    PowertrainSpecsQueryVariables
  >(PowertrainSpecsDocument, options);
}
export type PowertrainSpecsQueryHookResult = ReturnType<
  typeof usePowertrainSpecsQuery
>;
export type PowertrainSpecsLazyQueryHookResult = ReturnType<
  typeof usePowertrainSpecsLazyQuery
>;
export type PowertrainSpecsSuspenseQueryHookResult = ReturnType<
  typeof usePowertrainSpecsSuspenseQuery
>;
export type PowertrainSpecsQueryResult = Apollo.QueryResult<
  PowertrainSpecsQuery,
  PowertrainSpecsQueryVariables
>;
export const PowertrainProductSpecsBlocksDocument = gql`
  query PowertrainProductSpecsBlocks {
    powertrain {
      data {
        attributes {
          productSpecsBlocks {
            ...productSpecsBlock
          }
        }
      }
    }
  }
  ${ProductSpecsBlockFragmentDoc}
`;

/**
 * __usePowertrainProductSpecsBlocksQuery__
 *
 * To run a query within a React component, call `usePowertrainProductSpecsBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowertrainProductSpecsBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowertrainProductSpecsBlocksQuery({
 *   variables: {
 *   },
 * });
 */
export function usePowertrainProductSpecsBlocksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PowertrainProductSpecsBlocksQuery,
    PowertrainProductSpecsBlocksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PowertrainProductSpecsBlocksQuery,
    PowertrainProductSpecsBlocksQueryVariables
  >(PowertrainProductSpecsBlocksDocument, options);
}
export function usePowertrainProductSpecsBlocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PowertrainProductSpecsBlocksQuery,
    PowertrainProductSpecsBlocksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PowertrainProductSpecsBlocksQuery,
    PowertrainProductSpecsBlocksQueryVariables
  >(PowertrainProductSpecsBlocksDocument, options);
}
export function usePowertrainProductSpecsBlocksSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PowertrainProductSpecsBlocksQuery,
        PowertrainProductSpecsBlocksQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PowertrainProductSpecsBlocksQuery,
    PowertrainProductSpecsBlocksQueryVariables
  >(PowertrainProductSpecsBlocksDocument, options);
}
export type PowertrainProductSpecsBlocksQueryHookResult = ReturnType<
  typeof usePowertrainProductSpecsBlocksQuery
>;
export type PowertrainProductSpecsBlocksLazyQueryHookResult = ReturnType<
  typeof usePowertrainProductSpecsBlocksLazyQuery
>;
export type PowertrainProductSpecsBlocksSuspenseQueryHookResult = ReturnType<
  typeof usePowertrainProductSpecsBlocksSuspenseQuery
>;
export type PowertrainProductSpecsBlocksQueryResult = Apollo.QueryResult<
  PowertrainProductSpecsBlocksQuery,
  PowertrainProductSpecsBlocksQueryVariables
>;
export const PowertrainNewsroomDocument = gql`
  query PowertrainNewsroom {
    powertrain {
      data {
        attributes {
          newsroom {
            ...newsroom
          }
        }
      }
    }
  }
  ${NewsroomFragmentDoc}
`;

/**
 * __usePowertrainNewsroomQuery__
 *
 * To run a query within a React component, call `usePowertrainNewsroomQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowertrainNewsroomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowertrainNewsroomQuery({
 *   variables: {
 *   },
 * });
 */
export function usePowertrainNewsroomQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PowertrainNewsroomQuery,
    PowertrainNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PowertrainNewsroomQuery,
    PowertrainNewsroomQueryVariables
  >(PowertrainNewsroomDocument, options);
}
export function usePowertrainNewsroomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PowertrainNewsroomQuery,
    PowertrainNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PowertrainNewsroomQuery,
    PowertrainNewsroomQueryVariables
  >(PowertrainNewsroomDocument, options);
}
export function usePowertrainNewsroomSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PowertrainNewsroomQuery,
        PowertrainNewsroomQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PowertrainNewsroomQuery,
    PowertrainNewsroomQueryVariables
  >(PowertrainNewsroomDocument, options);
}
export type PowertrainNewsroomQueryHookResult = ReturnType<
  typeof usePowertrainNewsroomQuery
>;
export type PowertrainNewsroomLazyQueryHookResult = ReturnType<
  typeof usePowertrainNewsroomLazyQuery
>;
export type PowertrainNewsroomSuspenseQueryHookResult = ReturnType<
  typeof usePowertrainNewsroomSuspenseQuery
>;
export type PowertrainNewsroomQueryResult = Apollo.QueryResult<
  PowertrainNewsroomQuery,
  PowertrainNewsroomQueryVariables
>;
export const PowertrainSeoDocument = gql`
  query PowertrainSeo {
    powertrain {
      data {
        id
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __usePowertrainSeoQuery__
 *
 * To run a query within a React component, call `usePowertrainSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowertrainSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowertrainSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function usePowertrainSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PowertrainSeoQuery,
    PowertrainSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PowertrainSeoQuery, PowertrainSeoQueryVariables>(
    PowertrainSeoDocument,
    options,
  );
}
export function usePowertrainSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PowertrainSeoQuery,
    PowertrainSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PowertrainSeoQuery, PowertrainSeoQueryVariables>(
    PowertrainSeoDocument,
    options,
  );
}
export function usePowertrainSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PowertrainSeoQuery,
        PowertrainSeoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PowertrainSeoQuery,
    PowertrainSeoQueryVariables
  >(PowertrainSeoDocument, options);
}
export type PowertrainSeoQueryHookResult = ReturnType<
  typeof usePowertrainSeoQuery
>;
export type PowertrainSeoLazyQueryHookResult = ReturnType<
  typeof usePowertrainSeoLazyQuery
>;
export type PowertrainSeoSuspenseQueryHookResult = ReturnType<
  typeof usePowertrainSeoSuspenseQuery
>;
export type PowertrainSeoQueryResult = Apollo.QueryResult<
  PowertrainSeoQuery,
  PowertrainSeoQueryVariables
>;
export const SoftwareHeroDocument = gql`
  query SoftwareHero {
    software {
      data {
        id
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __useSoftwareHeroQuery__
 *
 * To run a query within a React component, call `useSoftwareHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftwareHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftwareHeroQuery({
 *   variables: {
 *   },
 * });
 */
export function useSoftwareHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SoftwareHeroQuery,
    SoftwareHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SoftwareHeroQuery, SoftwareHeroQueryVariables>(
    SoftwareHeroDocument,
    options,
  );
}
export function useSoftwareHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SoftwareHeroQuery,
    SoftwareHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SoftwareHeroQuery, SoftwareHeroQueryVariables>(
    SoftwareHeroDocument,
    options,
  );
}
export function useSoftwareHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SoftwareHeroQuery,
        SoftwareHeroQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SoftwareHeroQuery, SoftwareHeroQueryVariables>(
    SoftwareHeroDocument,
    options,
  );
}
export type SoftwareHeroQueryHookResult = ReturnType<
  typeof useSoftwareHeroQuery
>;
export type SoftwareHeroLazyQueryHookResult = ReturnType<
  typeof useSoftwareHeroLazyQuery
>;
export type SoftwareHeroSuspenseQueryHookResult = ReturnType<
  typeof useSoftwareHeroSuspenseQuery
>;
export type SoftwareHeroQueryResult = Apollo.QueryResult<
  SoftwareHeroQuery,
  SoftwareHeroQueryVariables
>;
export const SoftwareProductImageBlockDocument = gql`
  query SoftwareProductImageBlock {
    software {
      data {
        id
        attributes {
          productImageBlock {
            ...productImageBlock
          }
        }
      }
    }
  }
  ${ProductImageBlockFragmentDoc}
`;

/**
 * __useSoftwareProductImageBlockQuery__
 *
 * To run a query within a React component, call `useSoftwareProductImageBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftwareProductImageBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftwareProductImageBlockQuery({
 *   variables: {
 *   },
 * });
 */
export function useSoftwareProductImageBlockQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SoftwareProductImageBlockQuery,
    SoftwareProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SoftwareProductImageBlockQuery,
    SoftwareProductImageBlockQueryVariables
  >(SoftwareProductImageBlockDocument, options);
}
export function useSoftwareProductImageBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SoftwareProductImageBlockQuery,
    SoftwareProductImageBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SoftwareProductImageBlockQuery,
    SoftwareProductImageBlockQueryVariables
  >(SoftwareProductImageBlockDocument, options);
}
export function useSoftwareProductImageBlockSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SoftwareProductImageBlockQuery,
        SoftwareProductImageBlockQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SoftwareProductImageBlockQuery,
    SoftwareProductImageBlockQueryVariables
  >(SoftwareProductImageBlockDocument, options);
}
export type SoftwareProductImageBlockQueryHookResult = ReturnType<
  typeof useSoftwareProductImageBlockQuery
>;
export type SoftwareProductImageBlockLazyQueryHookResult = ReturnType<
  typeof useSoftwareProductImageBlockLazyQuery
>;
export type SoftwareProductImageBlockSuspenseQueryHookResult = ReturnType<
  typeof useSoftwareProductImageBlockSuspenseQuery
>;
export type SoftwareProductImageBlockQueryResult = Apollo.QueryResult<
  SoftwareProductImageBlockQuery,
  SoftwareProductImageBlockQueryVariables
>;
export const SoftwareNewsroomDocument = gql`
  query SoftwareNewsroom {
    software {
      data {
        attributes {
          newsroom {
            ...newsroom
          }
        }
      }
    }
  }
  ${NewsroomFragmentDoc}
`;

/**
 * __useSoftwareNewsroomQuery__
 *
 * To run a query within a React component, call `useSoftwareNewsroomQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftwareNewsroomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftwareNewsroomQuery({
 *   variables: {
 *   },
 * });
 */
export function useSoftwareNewsroomQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SoftwareNewsroomQuery,
    SoftwareNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SoftwareNewsroomQuery, SoftwareNewsroomQueryVariables>(
    SoftwareNewsroomDocument,
    options,
  );
}
export function useSoftwareNewsroomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SoftwareNewsroomQuery,
    SoftwareNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SoftwareNewsroomQuery,
    SoftwareNewsroomQueryVariables
  >(SoftwareNewsroomDocument, options);
}
export function useSoftwareNewsroomSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SoftwareNewsroomQuery,
        SoftwareNewsroomQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SoftwareNewsroomQuery,
    SoftwareNewsroomQueryVariables
  >(SoftwareNewsroomDocument, options);
}
export type SoftwareNewsroomQueryHookResult = ReturnType<
  typeof useSoftwareNewsroomQuery
>;
export type SoftwareNewsroomLazyQueryHookResult = ReturnType<
  typeof useSoftwareNewsroomLazyQuery
>;
export type SoftwareNewsroomSuspenseQueryHookResult = ReturnType<
  typeof useSoftwareNewsroomSuspenseQuery
>;
export type SoftwareNewsroomQueryResult = Apollo.QueryResult<
  SoftwareNewsroomQuery,
  SoftwareNewsroomQueryVariables
>;
export const SoftwareSeoDocument = gql`
  query SoftwareSeo {
    software {
      data {
        id
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useSoftwareSeoQuery__
 *
 * To run a query within a React component, call `useSoftwareSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftwareSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftwareSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useSoftwareSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SoftwareSeoQuery,
    SoftwareSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SoftwareSeoQuery, SoftwareSeoQueryVariables>(
    SoftwareSeoDocument,
    options,
  );
}
export function useSoftwareSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SoftwareSeoQuery,
    SoftwareSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SoftwareSeoQuery, SoftwareSeoQueryVariables>(
    SoftwareSeoDocument,
    options,
  );
}
export function useSoftwareSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SoftwareSeoQuery,
        SoftwareSeoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SoftwareSeoQuery, SoftwareSeoQueryVariables>(
    SoftwareSeoDocument,
    options,
  );
}
export type SoftwareSeoQueryHookResult = ReturnType<typeof useSoftwareSeoQuery>;
export type SoftwareSeoLazyQueryHookResult = ReturnType<
  typeof useSoftwareSeoLazyQuery
>;
export type SoftwareSeoSuspenseQueryHookResult = ReturnType<
  typeof useSoftwareSeoSuspenseQuery
>;
export type SoftwareSeoQueryResult = Apollo.QueryResult<
  SoftwareSeoQuery,
  SoftwareSeoQueryVariables
>;
