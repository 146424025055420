import { CookiesBar, useCaseType } from '@softopus-io/rimac-ui-lib';
import { getCookie, hasCookie, setCookie } from 'cookies-next';
import { useEffect, useState } from 'react';

import { useCookiesBarQuery } from '@/api';

export const CookieBar = () => {
  const [visible, setVisible] = useState(false);
  const { data } = useCookiesBarQuery();
  const cookiesBarData = data?.cookiesBanner?.data?.attributes;

  setCookie('rtech_cookie_consent_necessary', true);

  useEffect(() => {
    hasCookie('rtech_cookie_consent_optional') &&
    getCookie('rtech_cookie_consent_optional') === 'true'
      ? setVisible(false)
      : setVisible(true);
  }, []);

  const setAll = () => {
    setCookie('rtech_cookie_consent_necessary', true);
    setCookie('rtech_cookie_consent_optional', true);
    setVisible(false);
  };

  if (visible && data)
    return (
      <div className="fixed bottom-none left-1/2 z-10 w-full -translate-x-1/2">
        <CookiesBar
          heading={cookiesBarData?.header || ''}
          text={cookiesBarData?.text || ''}
          moreInfoLink={{
            label: cookiesBarData?.policyButton?.label || '',
            path: cookiesBarData?.policyButton?.path || '',
            useCase: 'technology' as useCaseType,
          }}
          actionButton={{
            label: cookiesBarData?.actionButtonLabel || '',
            onClick: () => setAll(),
          }}
        />
      </div>
    );
};
